<script setup lang="ts">
import { computed } from 'vue';
import TodaysLogHeader from './TodaysLogHeader.vue';
import TodaysLogCards from './TodaysLogCards.vue';
import { useBeatsStore } from '@/store/beats';

const emit = defineEmits<{
	(e: 'beatEdited'): void;
}>();

const beatsStore = useBeatsStore();
const beats = computed(() => beatsStore.beatsFromToday);

</script>

<template>
	<div
		class="p-6 desktop:px-12 desktop:pt-0 w-full"
	>
		<TodaysLogHeader class="mb-8 phablet:mb-0" />
		<TodaysLogCards
			:beats="beats"
			@beat-edited="emit('beatEdited')"
		/>
	</div>
</template>

<script setup lang="ts">
/**
 * This is a quick & dirty solution for a "Manage Quota Lists View".
 * The supabase queries are beyond good & evil, so feel free to optimize this. :D
 * To explain what is happening here: With this view we want to get all Quota Lists
 * with resolved User, Client and Project data. We also want to have the possibility
 * to add and remove Users, Clients und Projects from that lists.
 */

import { useQuery } from '@tanstack/vue-query';
import Multiselect from '@vueform/multiselect';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { Database } from '@/models/Supabase';
import { supabase } from '@/lib/supabase';
import PageTitle from '@/components/PageTitle.vue';

const { data: quotaLists, refetch } = useQuery({
	queryKey: ['manage-quota-lists', 'quota-lists'],
	queryFn: async () => {
		const { data } = await supabase
			.from('quota_list')
			.select(`
                *,
                users_access_in_quota_list(
                    users(user_id, name)
                ),
                quota_list_entries(
                    quota_info_id,
                    quota_infos(
                        projects(*),
                        clients(*)
                    )
                )
            `);

		return data;
	},
});

const { data: users } = useQuery({
	queryKey: ['manage-quota-lists', 'users'],
	queryFn: async () => {
		const { data } = await supabase
			.from('users')
			.select('user_id, name');

		return data ?? [];
	},
	initialData: [],
});

const { data: clients } = useQuery({
	queryKey: ['manage-quota-lists', 'clients'],
	queryFn: async () => {
		const { data } = await supabase
			.from('clients')
			.select('client_id, name, quota_info_id');

		return data ?? [];
	},
	initialData: [],
});

const { data: projects } = useQuery({
	queryKey: ['manage-quota-lists', 'projects'],
	queryFn: async () => {
		const { data } = await supabase
			.from('projects')
			.select('project_id, name, quota_info_id');

		return data ?? [];
	},
	initialData: [],
});

const onSelectUserForList = async (listId: string, user: Pick<Database['public']['Tables']['users']['Row'], 'user_id' | 'name'>) => {
	await supabase.from('users_access_in_quota_list').insert({ quota_list_id: listId, user_id: user.user_id });
	await refetch();
};

const onDeselectUserForList = async (listId: string, user: Pick<Database['public']['Tables']['users']['Row'], 'user_id' | 'name'>) => {
	await supabase
		.from('users_access_in_quota_list')
		.delete()
		.eq('quota_list_id', listId)
		.eq('user_id', user.user_id);
	await refetch();
};

const onSelectQuotaInfoIdForList = async (listId: string, quotaInfoId: string) => {
	await supabase.from('quota_list_entries').insert({ quota_list_id: listId, quota_info_id: quotaInfoId });
	await refetch();
};

const onDeselectQuotaInfoIdForList = async (listId: string, quotaInfoId: string) => {
	await supabase
		.from('quota_list_entries')
		.delete()
		.eq('quota_list_id', listId)
		.eq('quota_info_id', quotaInfoId);
	await refetch();
};

const getMultipleLabel = (text: string) => (array: unknown[]) => `${text} (${array.length})`;

const multiselectTailwindDefaults = {
	container: 'relative flex items-center justify-end box-border cursor-pointer border border-primary-800 rounded-lg bg-white text-base leading-snug outline-none',
	containerDisabled: 'cursor-default bg-primary-100',
	containerOpen: 'rounded-b-none',
	containerOpenTop: 'rounded-t-none',
	containerActive: 'ring ring-primary-100 ring-opacity-80',
	wrapper: 'relative mx-auto w-full flex items-center justify-end box-border cursor-pointer outline-none',
	singleLabel: 'flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
	singleLabelText: 'overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full',
	multipleLabel: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
	search: 'w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5 rtl:pl-0 rtl:pr-3.5',
	tags: 'flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2 min-w-0 rtl:pl-0 rtl:pr-2',
	tag: 'bg-primary-500 text-white text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap min-w-0 rtl:pl-0 rtl:pr-2 rtl:mr-0 rtl:ml-1',
	tagDisabled: 'pr-2 opacity-50 rtl:pl-2',
	tagWrapper: 'whitespace-nowrap overflow-hidden overflow-ellipsis',
	tagWrapperBreak: 'whitespace-normal break-all',
	tagRemove: 'flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group',
	tagRemoveIcon: 'bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60',
	tagsSearchWrapper: 'inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full',
	tagsSearch: 'absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full',
	tagsSearchCopy: 'invisible whitespace-pre-wrap inline-block h-px',
	placeholder: 'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-primary-400 rtl:left-auto rtl:right-0 rtl:pl-0 rtl:pr-3.5',
	caret: 'bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none rtl:mr-0 rtl:ml-3.5',
	caretOpen: 'rotate-180 pointer-events-auto',
	clear: 'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80 rtl:pr-0 rtl:pl-3.5',
	clearIcon: 'bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block',
	spinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0 rtl:mr-0 rtl:ml-3.5',
	infinite: 'flex items-center justify-center w-full',
	infiniteSpinner: 'bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 animate-spin flex-shrink-0 flex-grow-0 m-3.5',
	dropdown: 'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-primary-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
	dropdownTop: '-translate-y-full top-px bottom-auto rounded-b-none rounded-t',
	dropdownHidden: 'hidden',
	options: 'flex flex-col p-0 m-0 list-none',
	optionsTop: '',
	group: 'p-0 m-0',
	groupLabel: 'flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-primary-200 cursor-default leading-normal',
	groupLabelPointable: 'cursor-pointer',
	groupLabelPointed: 'bg-primary-100 text-primary-700',
	groupLabelSelected: 'bg-primary-600 text-white',
	groupLabelDisabled: 'bg-primary-100 text-primary-300 cursor-not-allowed',
	groupLabelSelectedPointed: 'bg-primary-600 text-white opacity-90',
	groupLabelSelectedDisabled: 'text-primary-100 bg-primary-600 bg-opacity-50 cursor-not-allowed',
	groupOptions: 'p-0 m-0',
	option: 'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
	optionPointed: 'text-primary-800 bg-primary-100 bg-opacity-20',
	optionSelected: 'text-white bg-primary-500',
	optionDisabled: 'text-primary-300 cursor-not-allowed',
	optionSelectedPointed: 'text-white bg-primary-500 opacity-90',
	optionSelectedDisabled: 'text-primary-100 bg-primary-500 bg-opacity-50 cursor-not-allowed',
	noOptions: 'py-2 px-3 text-primary-600 bg-white text-left rtl:text-right',
	noResults: 'py-2 px-3 text-primary-600 bg-white text-left rtl:text-right',
	fakeInput: 'bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent',
	assist: 'absolute -m-px w-px h-px overflow-hidden',
	spacer: 'h-9 py-px box-content',
};
</script>

<template>
	<div class="pt-6 space-y-10">
		<PageTitle>
			{{ $t('manageQuotaLists.title') }}
		</PageTitle>
		<div class="bg-secondary rounded-2xl p-6 shadow-widget font-bold">
			{{ $t('manageQuotaLists.experimentalWarning') }}
		</div>
		<div class="space-y-4">
			<div
				v-for="item in quotaLists"
				:key="item.quota_list_id"
				class="bg-primary-400 rounded-2xl p-6 shadow-widget"
			>
				<p class="text-2xl mb-4">
					{{ item.name }}
				</p>
				<div class="grid gap-4">
					<div>
						<p class="font-bold mb-4">
							{{ $t('manageQuotaLists.usersWithAccessToList') }}
						</p>
						<Multiselect
							:classes="multiselectTailwindDefaults"
							class="text-black w-56 m-0 mb-4"
							searchable
							object
							:can-clear="false"
							mode="multiple"
							:model-value="item.users_access_in_quota_list.map(u => u.users)"
							:options="users"
							value-prop="user_id"
							label="name"
							:placeholder="$t('manageQuotaLists.multipleLabelUserSelect')"
							:multiple-label="getMultipleLabel($t('manageQuotaLists.multipleLabelUserSelect'))"
							@select="onSelectUserForList(item.quota_list_id, $event)"
							@deselect="onDeselectUserForList(item.quota_list_id, $event)"
						/>
						<div class="flex flex-wrap gap-2">
							<div
								v-for="user in item.users_access_in_quota_list.map(u => u.users)"
								:key="user?.user_id"
								class="bg-primary-500 px-4 py-2 rounded-lg whitespace-nowrap flex items-center space-x-2"
							>
								<p>{{ user?.name }}</p>
								<button
									v-if="user"
									type="button"
									class="hover:bg-primary-300 p-0.5"
									@click="onDeselectUserForList(item.quota_list_id, user)"
								>
									<XMarkIcon class="text-white w-4 h-4" />
								</button>
							</div>
						</div>
					</div>
					<div>
						<p class="font-bold mb-4">
							{{ $t('common.clients') }} / {{ $t('common.projects') }}
						</p>
						<div class="flex space-x-4">
							<Multiselect
								:classes="multiselectTailwindDefaults"
								class="text-black w-72 m-0 mb-4"
								searchable
								object
								:can-clear="false"
								mode="multiple"
								:model-value="item.quota_list_entries.map(entry => entry.quota_infos?.clients[0]).filter(entry => !!entry)"
								:options="clients"
								value-prop="client_id"
								label="name"
								:placeholder="$t('manageQuotaLists.selectClient')"
								:multiple-label="getMultipleLabel('Kunden auswählen')"
								@select="onSelectQuotaInfoIdForList(item.quota_list_id, $event.quota_info_id)"
								@deselect="onDeselectQuotaInfoIdForList(item.quota_list_id, $event.quota_info_id)"
							/>
							<Multiselect
								:classes="multiselectTailwindDefaults"
								class="text-black w-72 m-0 mb-4"
								searchable
								object
								:can-clear="false"
								mode="multiple"
								:model-value="item.quota_list_entries.map(entry => entry.quota_infos?.projects[0]).filter(entry => !!entry)"
								:options="projects"
								value-prop="project_id"
								:placeholder="$t('manageQuotaLists.selectProject')"
								:multiple-label="getMultipleLabel('Projekte auswählen')"
								label="name"
								@select="onSelectQuotaInfoIdForList(item.quota_list_id, $event.quota_info_id)"
								@deselect="onDeselectQuotaInfoIdForList(item.quota_list_id, $event.quota_info_id)"
							/>
						</div>
						<div class="flex flex-wrap gap-2">
							<div
								v-for="entry in item.quota_list_entries"
								:key="entry.quota_info_id"
								class="bg-primary-500 px-4 py-2 rounded-lg whitespace-nowrap flex items-center space-x-2"
							>
								<p>
									<span v-if="entry.quota_infos?.clients[0]">
										{{ entry.quota_infos?.clients[0]?.name }}
									</span>
									<span v-if="entry.quota_infos?.projects[0]">
										{{ entry.quota_infos?.projects[0]?.name }}
									</span>
								</p>
								<button
									type="button"
									class="hover:bg-primary-300 p-0.5"
									@click="onDeselectQuotaInfoIdForList(item.quota_list_id, entry.quota_info_id)"
								>
									<XMarkIcon class="text-white w-4 h-4" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

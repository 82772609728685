<script setup lang="ts">
import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useVueTable } from '@tanstack/vue-table';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import AppTable from './AppTable.vue';
import { msToDisplayTime } from '@/shared/times';
import { BeatTableEntry } from '@/models/BeatTableEntry';

const props = withDefaults(defineProps<{
	data?: BeatTableEntry[];
}>(), {
	data: () => [],
});

const { t } = useI18n();
const columnHelper = createColumnHelper<BeatTableEntry>();

const computedMessages = computed(() => ({
	topic: t('common.topic'),
	logs: t('common.logs'),
	duration: t('common.duration'),
}));

const columns = [
	columnHelper.accessor('name', {
		header: computedMessages.value.topic,
		size: 300,
	}),
	columnHelper.accessor('logCount', {
		header: computedMessages.value.logs,
		size: 0,
		cell: (context) => context.getValue(),
	}),
	columnHelper.accessor('totalDurationInMs', {
		header: computedMessages.value.duration,
		size: 0,
		cell: (context) => msToDisplayTime(context.getValue()),
	}),
];

const table = useVueTable({
	columns,
	get data() {
		return props.data;
	},
	getCoreRowModel: getCoreRowModel(),
	getExpandedRowModel: getExpandedRowModel(),
	getSortedRowModel: getSortedRowModel(),
});

defineExpose({ table });
</script>

<template>
	<AppTable
		:table="table"
		class="w-full"
	/>
</template>

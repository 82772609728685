<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { useClipboard } from '@vueuse/core';
import AppInput from './AppInput.vue';
import AppTextarea from './AppTextarea.vue';
import IconCopy from '@/assets/copy-icon.svg?component';
import { useUnsavedChanges } from '@/composables/useUnsavedChanges';
import { Client } from '@/models/Client';
import { Project, ProjectMeta } from '@/models/Project';
import AppButton from '@/components/AppButton.vue';

const props = withDefaults(defineProps<{
	client: Client;
	project?: Project | null;
	editable?: boolean;
}>(), {
	project: null,
	editable: false,
});

const emit = defineEmits<{
	(e: 'cancel'): void;
	(e: 'save', client: ProjectMeta): void;
}>();

const isNewProject = computed(() => !props.project);

const { meta, values, setValues } = useForm<ProjectMeta>({
	validationSchema: {
		name: yup.string().required('Project-Name required'),
		description: yup.string().nullable().optional(),
	},
	initialValues: {
		name: props.project?.name ?? '',
		description: props.project?.description ?? '',
	},
});

watch(() => props.editable, () => {
	setValues({
		name: props.project?.name ?? '',
		description: props.project?.description ?? '',
	});
});

const { toggle, remove } = useUnsavedChanges();

watch(() => meta.value.dirty, toggle);

const cancel = () => {
	remove();
	emit('cancel');
};

const onConfirm = () => {
	remove();
	emit('save', { name: values.name, description: values.description, clientId: props.client.id });
};

const source = ref(props.project?.id ?? '');

const {
	copy, copied, isSupported,
} = useClipboard({ source });
</script>

<template>
	<form>
		<div class="text-xl mb-3">
			{{ $t('admin.projectName') }}
		</div>
		<AppInput
			v-if="editable"
			:placeholder="$t('admin.projectName')"
			name="name"
			class="mb-12"
		/>
		<div
			v-else
			class="text-2xl font-semibold mb-2"
		>
			{{ project?.name }}
		</div>
		<p class="text-gray mb-14 inline-block mr-2">
			{{ project?.id }}
		</p>
		<div
			v-if="isSupported && project?.id"
			class="inline-block"
		>
			<IconCopy
				class="inline-block w-4 mr-2 fill-primary-100 hover:cursor-pointer hover:fill-primary-200"
				@click="copy(source)"
			/>
			<div
				v-if="copied"
				class="text-gray text-xs inline-block"
			>
				{{ $t('admin.copied') }}
			</div>
		</div>

		<div class="text-xl mb-3">
			{{ $t('common.description') }}
		</div>
		<AppTextarea
			v-if="editable"
			name="description"
			:placeholder="$t('common.optional')"
			:label="$t('common.description')"
			:rows="5"
		/>
		<p
			v-else-if="project?.description"
			class="text-gray"
		>
			{{ project.description }}
		</p>
		<p
			v-else
			class="text-gray italic"
		>
			{{ $t('admin.noProjectDescription') }}
		</p>

		<div
			v-if="editable"
			class="flex justify-between mt-12"
		>
			<AppButton
				size="sm"
				:disabled="!meta.valid"
				@click="onConfirm"
			>
				<span v-if="isNewProject">
					{{ $t('admin.createProject') }}
				</span>
				<span v-else>
					{{ $t('common.save') }}
				</span>
			</AppButton>
			<AppButton
				size="sm"
				color="outline"
				@click="cancel"
			>
				{{ $t('common.cancel') }}
			</AppButton>
		</div>
	</form>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { ResultAsync } from 'neverthrow';
import { PostgrestError } from '@supabase/supabase-js';
import { hoursToDisplayTime } from '../shared/times';
import QuotaForm from './QuotaForm.vue';
import { Quota, QuotaSubmitData } from '@/models/Quota';
import IconEdit from '@/assets/edit-icon.svg?component';
import DeleteIcon from '@/assets/trash-icon.svg?component';
import { useToday } from '@/composables/useToday';

const props = withDefaults(defineProps<{
	quota: Quota;
	editable?: boolean;
	onUpdate?: ((quota: Quota) => ResultAsync<Quota, string>);
	onDelete?: ((quota: Quota) => ResultAsync<Quota, PostgrestError>);
}>(), {
	editable: false,
	onUpdate: undefined,
	onDelete: undefined,
});

const emit = defineEmits<{
	(e: 'startEditMode'): void;
	(e: 'endEditMode'): void;
}>();

const isEditable = computed(() => props.editable && !!props.onUpdate);

const today = useToday();

const isEditModeActive = ref(false);

const isFutureQuota = computed(() => today.value < props.quota.from);

const isPastQuota = computed(() => props.quota.till && today.value > props.quota.till);

const isDeletable = computed(() => isFutureQuota.value && !!props.onDelete);

const updateQuotaErrorMessage = ref('');

const startEditMode = () => {
	updateQuotaErrorMessage.value = '';
	isEditModeActive.value = true;
	emit('startEditMode');
};

const onCancelEdit = () => {
	updateQuotaErrorMessage.value = '';
	isEditModeActive.value = false;
	emit('endEditMode');
};

const onUpdateQuota = async (newQuotaData: QuotaSubmitData) => {
	if (!props.onUpdate) {
		return;
	}

	const result = await props.onUpdate({ ...props.quota, ...newQuotaData });

	if (result.isErr()) {
		updateQuotaErrorMessage.value = result.error;
		return;
	}

	isEditModeActive.value = false;
	emit('endEditMode');
};

const onDeleteQuota = async () => {
	if (!props.onDelete) {
		return;
	}

	props.onDelete(props.quota);
};
</script>

<template>
	<div>
		<QuotaForm
			v-if="isEditModeActive"
			:quota="quota"
			:error-message="updateQuotaErrorMessage"
			@cancel="onCancelEdit"
			@update="onUpdateQuota"
		/>
		<div
			v-else
			:class="{ 'text-gray': isFutureQuota || isPastQuota }"
		>
			<div class="grid grid-cols-11 gap-x-4 relative">
				<div class="col-span-3">
					<div class="text-lg">
						{{ quota?.from.toFormat('dd.MM.yyyy') }}
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-lg">
						{{ quota.till?.toFormat('dd.MM.yyyy') ?? '-' }}
					</div>
				</div>
				<div class="col-span-3">
					<div class="text-lg">
						{{ hoursToDisplayTime(quota.monthlyHours) }}{{ $t('common.h') }}
					</div>
				</div>
				<div class="col-span-2">
					<div class="flex justify-end space-x-4">
						<button
							v-if="isDeletable"
							v-tippy="{ content: 'Delete', theme: 'sunhealth-mt' }"
							type="button"
							class="fill-gray hover:fill-white"
							@click="onDeleteQuota"
						>
							<DeleteIcon
								class="fill-red-500 hover:cursor-pointer hover:fill-red-600 transform translate-y-1"
							/>
						</button>
						<button
							v-if="isEditable"
							v-tippy="{ content: $t('common.edit'), theme: 'sunhealth-mt' }"
							type="button"
							class="fill-gray hover:fill-white"
							@click="startEditMode"
						>
							<IconEdit
								class="fill-primary-100 hover:cursor-pointer hover:fill-primary-200 transform translate-y-1"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { DateTime } from 'luxon';

const toDate = defineModel<DateTime>('toDate');
const fromDate = defineModel<DateTime>('fromDate');

const activeMonth = computed(() => {
	const now = DateTime.now().startOf('month');
	const monthDiff = fromDate.value?.startOf('month').diff(now, 'month').months;

	if (monthDiff === 0) {
		return 'current';
	}

	return 'last';
});

const setLastMonth = () => {
	fromDate.value = DateTime.now().minus({ months: 1 }).startOf('month');
	toDate.value = DateTime.now().minus({ months: 1 }).endOf('month');
};

const setCurrentMonth = () => {
	fromDate.value = DateTime.now().startOf('month');
	toDate.value = DateTime.now().endOf('month');
};

</script>

<template>
	<div
		class="bg-primary-400 rounded-2xl p-4 shadow-widget text-center min-w-72 flex items-center space-x-1"
	>
		<button
			type="button"
			class="px-4 py-2 rounded-lg transition"
			:class="{
				'bg-primary-200': activeMonth === 'current',
			}"
			@click="setCurrentMonth"
		>
			{{ $t('quotaMonitoring.currentMonth') }}
		</button>
		<button
			type="button"
			class="px-4 py-2 rounded-lg transition"
			:class="{
				'bg-primary-200': activeMonth === 'last',
			}"
			@click="setLastMonth"
		>
			{{ $t('quotaMonitoring.lastMonth') }}
		</button>
	</div>
</template>

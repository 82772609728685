<script setup lang="ts">
import type { Row } from '@tanstack/vue-table';
import { createColumnHelper, getCoreRowModel, getExpandedRowModel, getSortedRowModel, useVueTable, FlexRender } from '@tanstack/vue-table';
import { h, computed } from 'vue';
import { ArrowDownIcon, ChevronDownIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';
import MeterLinear from './MeterLinear.vue';
import QuotaAverage from './QuotaAverage.vue';
import { QuotaOverviewEntry } from '@/models/QuotaEntry';
import { hoursToDisplayTime } from '@/shared/times';
import { plausible } from '@/plugins/plausible/setup';

const props = withDefaults(defineProps<{
	entries?: QuotaOverviewEntry[];
}>(), {
	entries: () => [],
});

const { t } = useI18n();
const sendExpandPlausibleEvent = (row: Row<QuotaOverviewEntry>) => {
	if (row.getIsExpanded()) {
		plausible.trackEvent('quota-expand', { props: { quotaname: row.original.name } });
	}
};

const columnHelper = createColumnHelper<QuotaOverviewEntry>();

const computedMessages = computed(() => ({
	client: t('common.client'),
	project: t('common.project'),
	quotaInHours: t('quotaMonitoring.overview.quotaInHours'),
}));

const columns = [
	columnHelper.display({
		id: 'expand',
		size: 10,
		cell: (context) => (context.row.getCanExpand()
			? h(
				'button',
				{
					class: 'flex items-center justify-center',
				},
				h(
					ChevronDownIcon,
					{
						class: ['w-5 h-5 transform', { 'rotate-180': context.row.getIsExpanded() }],
					},
				),
			)
			: ''),
	}),
	columnHelper.accessor('name', {
		header: `${computedMessages.value.client} / ${computedMessages.value.project}`,
		cell: (context) => h(
			'strong',
			{
				class: 'text-white opacity-100',
			},
			context.getValue(),
		),
	}),
	columnHelper.accessor('monthlyHours', {
		header: '',
		size: 300,
		enableSorting: false,
		cell: (context) => h(MeterLinear, {
			theme: context.row.getValue<number>('loggedHours') > context.getValue()
				? 'secondary'
				: 'success',
			class: ['h-5 w-full my-1'],
			progress: [
				context.row.getValue('loggedHours'),
				context.getValue(),
			],
		}),
	}),
	columnHelper.accessor('loggedHours', {
		header: computedMessages.value.quotaInHours,
		meta: {
			align: 'right',
		},
		cell: (context) => h(
			'div',
			{
				class: '',
			},
			[
				// With 49px we imply that the hours should have 3 digits
				h('div', { class: 'inline-block w-[49px]' }, hoursToDisplayTime(context.getValue())),
				h('div', { class: 'inline-block px-2' }, '/'),
				h('div', { class: 'inline-block w-[49px]' }, hoursToDisplayTime(context.row.getValue('monthlyHours'))),
			],
		),
	}),
];

const table = useVueTable({
	columns,
	enableSorting: true,
	initialState: {
		sorting: [{ id: 'name', desc: false }],
	},
	get data() {
		return props.entries;
	},
	enableRowSelection: false,
	enableMultiRowSelection: false,
	enableSubRowSelection: false,
	enableSortingRemoval: false,
	getRowCanExpand: () => true,
	getCoreRowModel: getCoreRowModel(),
	getExpandedRowModel: getExpandedRowModel(),
	getSortedRowModel: getSortedRowModel(),
});

defineExpose({ table });
</script>

<template>
	<table class="min-w-full relative w-full">
		<thead>
			<tr
				v-for="headerGroup in table.getHeaderGroups()"
				:key="headerGroup.id"
			>
				<th
					v-for="(
						header, index
					) in headerGroup.headers"
					:key="header.id"
					:colSpan="header.colSpan"
					class="p-4 font-sans font-bold text-sm text-gray-400 border-b-2 border-primary-300 sticky top-0 bg-primary-400 z-40"
					:class="[{
						'sm:pl-6': index === 0,
						'cursor-pointer select-none': header.column.getCanSort(),
					}, header.column.columnDef.meta?.align === 'right' ? 'text-right' : 'text-left']"
					:style="{ width: `${header.getSize()}px` }"
					@click="event => header.column.getToggleSortingHandler()?.(event)"
				>
					<div
						class="flex items-center"
						:class="{
							'justify-end': header.column.columnDef.meta?.align === 'right',
						}"
					>
						<FlexRender
							v-if="!header.isPlaceholder"
							:render="header.column.columnDef.header"
							:props="header.getContext()"
						/>
						<ArrowDownIcon
							v-if="header.column.getCanSort()"
							class="w-5 ml-3 transform"
							:class="{
								'opacity-50': !header.column.getIsSorted(),
								'rotate-180': header.column.getIsSorted() === 'desc',
							}"
						/>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<template
				v-for="row in table.getRowModel().rows"
				:key="row.id"
			>
				<tr
					class="cursor-pointer hover:bg-primary-300"
					@click="() => {
						row.toggleExpanded(); sendExpandPlausibleEvent(row);
					}"
				>
					<td
						v-for="(
							cell, columnIndex
						) in row.getVisibleCells()"
						:key="cell.id"
						class="py-4 pl-4 pr-4 text-left border-t border-primary-300"
						:class="{
							'sm:pl-6': columnIndex === 0,
							'bg-primary-300': row.getIsSelected(),
							'text-right': cell.column.columnDef.meta?.align === 'right',
						}"
						:style="{
							width: `${cell.column.getSize()}px`,
						}"
					>
						<FlexRender
							:render="cell.column.columnDef.cell"
							:props="cell.getContext()"
						/>
					</td>
				</tr>
				<tr v-if="row.getIsExpanded()">
					<td :colspan="row.getVisibleCells().length">
						<QuotaAverage
							class="w-4/5 widescreen:w-3/5 mx-auto mb-4"
							:quota-entries="row.original.monthlyEntries"
							show-period-selection
						/>
					</td>
				</tr>
			</template>
		</tbody>
	</table>
</template>

import { Activity, createActivityFromSupabase } from '../models/Activity';
import { supabase } from '@/lib/supabase';
import { ActivityOrdering } from '@/models/UserSettings';

export const getActivities = async (): Promise<Activity[]> => {
	const { data } = await supabase
		.from('activities')
		.select('*');

	return data?.map(createActivityFromSupabase) ?? [];
};

export const getOrderedUserActivities = async (sortMode?: ActivityOrdering): Promise<Activity[]> => {
	const { data } = await supabase
		.rpc('user_activities', { sort_mode: sortMode });

	return data?.map(createActivityFromSupabase) ?? [];
};

export const createNewActivity = async (name: string) => {
	const { data } = await supabase
		.from('activities')
		.insert({
			name,
		});

	return data;
};

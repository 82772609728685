<script setup lang="ts">
import hotkeys from 'hotkeys-js';
import {
	computed, h,
	onMounted, onUnmounted, watch,
} from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useAppProgressLoader, LoadingBar } from '@sunlabde/vuelab';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/vue';
import { ModalsContainer } from 'vue-final-modal';
import { useWindowFocus } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import ToastRefreshVersion from './components/ToastRefreshVersion.vue';
import IconSnow from './components/eastereggs/IconSnow.vue';
import { createUserData } from './api/user';
import { versioningTimer, appVersionChecker } from './plugins/versioningTimer';
import { useMainStore } from './store/main';
import { useAuthentication } from './store/auth';
import { releaseNotes } from './services/ReleaseNotes';
import { useUserSettings } from './services/UserSettings';
import { useLogMissingNotification } from './composables/useLogMissingNotification';
import { LogMissingNotification } from './models/LogMissingNotification';
import { logMissingMessages } from './data/logMissingMessages';

const { t } = useI18n();
const mainStore = useMainStore();
const router = useRouter();
const toast = useToast();
const appProgressLoader = useAppProgressLoader();
const isDevelopment = import.meta.env.MODE === 'development';

const updateCurrentDate = () => {
	mainStore.today = DateTime.now();
};

const computedMessages = computed(() => ({
	infoRefreshVersion: t('notifications.infoRefreshVersion'),
	reload: t('notifications.reload'),
}));

watch(() => mainStore.isNewVersionAvailable, (isNewVersionAvailable) => {
	if (isNewVersionAvailable) {
		toast.info(h(ToastRefreshVersion, { infoRefreshVersion: computedMessages.value.infoRefreshVersion, reload: computedMessages.value.reload }), { timeout: false });
	}
});

const windowFocused = useWindowFocus();

watch(windowFocused, () => {
	versioningTimer.runTimersCheck();
});

const checkForNewVersion = async () => {
	const newVersionAvailable = await appVersionChecker.isNewerVersionAvailable(true);

	if (!newVersionAvailable) {
		return;
	}

	mainStore.isNewVersionAvailable = true;
};

versioningTimer.setInterval({
	callback: checkForNewVersion,
	// Normal interval is once every 10 minutes
	interval: 1000 * 60 * 10,
	// Forced interval is once per day
	forcedInterval: 1000 * 60 * 60 * 24,
});

const { onUserLogin, onUserLogout } = useAuthentication();

onUserLogin((_authUser) => {
	createUserData(_authUser)
		.then(mainStore.fetchUserData);

	mainStore.user = { id: _authUser.id, name: _authUser.displayName };
});

onUserLogout(() => {
	mainStore.resetStore();

	Sentry.setUser(null);

	router.push({ name: 'Signup' });
});

onMounted(() => {
	checkForNewVersion();
	releaseNotes();

	hotkeys.filter = (event: Event) => {
		const target = (event.target || event.srcElement) as HTMLElement;

		const { tagName } = target;

		if (target.getAttribute('data-allow-hotkeys')) {
			return true;
		}

		if (target.isContentEditable) {
			return false;
		}

		if ((tagName === 'INPUT' || tagName === 'TEXTAREA' || tagName === 'SELECT') && !(target as HTMLInputElement).readOnly) {
			return false;
		}

		return true;
	};

	window.addEventListener('focus', updateCurrentDate);
});

onUnmounted(() => {
	window.removeEventListener('focus', updateCurrentDate);
});

const { settings: userSettings } = useUserSettings();

const logMissingNotifications = computed<LogMissingNotification[]>(() => {
	if (!userSettings.value?.logNotificationsEnabled) {
		return [];
	}

	const messages = logMissingMessages[userSettings.value.logNotificationVoice];

	return userSettings.value.logNotifications.map((notification) => ({
		time: notification.time,
		messages,
	}));
});

useLogMissingNotification(logMissingNotifications);

</script>

<template>
	<div
		id="app-portal"
		class="relative w-full bg-primary-500 text-white"
	>
		<div
			v-if="isDevelopment"
			class="h-1 w-full fixed z-[9998]"
			style="background: repeating-linear-gradient(-55deg, #ad9c1d, #ad9c1d 10px, #ebd534 10px, #ebd534 20px)"
		/>
		<LoadingBar
			:progress="appProgressLoader"
			class="absolute left-0 top-0 w-full z-[9999]"
			bar-classes="w-full transition-all ease-linear pointer-events-none transform-gpu scale-x-0 origin-left bg-primary-200 shadow-glow"
		/>
		<ModalsContainer />
		<transition
			name="fade"
			mode="out-in"
		>
			<component :is="mainStore.viewLayoutComponent">
				<router-view />
			</component>
		</transition>
		<IconSnow />
	</div>
</template>

<style>
@import 'vue-toastification/dist/index.css';
@import './styles/tippy.css';

#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
    font-weight: 600;
    opacity: 0.5;
}

* {
	margin: 0;
	padding: 0;
}

html {
    background-color: #16123b;
}

.click-wrapper {
	position: absolute;
	overflow: hidden;
    border-radius: 9999px;
	z-index: 9999;
	pointer-events: none;
}

@keyframes click-pulse {
	0% {
		transform: scale3d(1, 1, 1);
	}

	to {
		opacity: 0;
		transform: scale3d(9, 9, 1);
	}
}

.click-point {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	position: absolute;
	animation: click-pulse 500ms ease both;
	background-color: white;
	opacity: 0.3;
    z-index: 99999;

	.is-light {
		opacity: 1;
	}

	.is-dark {
		background-color: white;
		opacity: 0.3;
	}
}

::selection {
    background-color: #622CEB;
    color: inherit;
}
</style>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import { computed } from 'vue';
import { InputSize, getSizeClass } from '@/models/InputSize';

const props = withDefaults(defineProps<{
	modelValue?: string;
	placeholder?: string;
	name?: string;
	size?: InputSize;
}>(), {
	modelValue: '',
	placeholder: '',
	name: 'name',
	size: 'lg',
});

const value = useVModel(props, 'modelValue');

const sizeClass = computed(() => getSizeClass(props.size));

</script>

<template>
	<input
		v-model="value"
		class="block relative focus:ring-0 focus:outline-none appearance-none text-white bg-primary-300 rounded-lg w-full border border-primary-900 focus:border-white disabled:opacity-50 disabled:bg-black/40 outline-offset-0"
		:class="sizeClass"
		type="text"
		autocomplete="off"
		data-1p-ignore
		:name="name"
		:aria-label="name"
		:placeholder="placeholder"
	>
</template>

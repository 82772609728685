import { DateTime } from 'luxon';
import { supabase } from '@/lib/supabase';
import { QuotaBeat, createQuotaBeatFromSupabase } from '@/models/QuotaBeats';

export const fetchQuotaBeats = async (fromDate: DateTime, excludedTeams: string[] = []): Promise<QuotaBeat[]> => {
	const { data } = await supabase
		.from('quota_beats')
		.select('*')
		.not('team_id', 'in', `(${excludedTeams.join(',')})`)
		.gte('month', fromDate.startOf('month'));

	// TODO: Fix "SupabaseQuotaBeat" type
	// @ts-expect-error See todo above
	return data?.map(createQuotaBeatFromSupabase) ?? [];
};

import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useMainStore } from './main';
import { useQuotaListsQuery } from '@/composables/queries/useQuotaListsQuery';

export const useQuotaListsStore = defineStore('quotaLists', () => {
	const mainStore = useMainStore();

	const { data } = useQuotaListsQuery(computed(() => ({
		user: mainStore.user,
	})));

	const quotaLists = computed(() => data.value ?? []);

	return {
		quotaLists,
	};
});

<script setup lang="ts">
import { computed, ref } from 'vue';
import QuotaGraphChart from './QuotaGraphChart.vue';
import QuotaAveragePeriodSelection from './QuotaAveragePeriodSelection.vue';
import { QuotaMonthlyEntry } from '@/models/QuotaEntry';

const props = withDefaults(defineProps<{
	quotaEntries?: QuotaMonthlyEntry[];
	showPeriodSelection?: boolean;
}>(), {
	quotaEntries: () => [],
	showPeriodSelection: false,
});

const defaultPeriod = computed<number>(() => {
	if (props.quotaEntries.length <= 3) {
		return 3;
	}
	return 6;
});

const selectedPeriod = ref<number>(defaultPeriod.value);

const updateSelectedPeriod = (months: number) => {
	selectedPeriod.value = months;
};

const quotaEntriesFilteredByPeriod = computed(() => props.quotaEntries.filter((entry) => {
	const lastEntry = props.quotaEntries.at(-1);

	if (!lastEntry) {
		return null;
	}

	return entry.month > lastEntry.month.minus({ months: selectedPeriod.value });
}));

const availablePeriodSpans = computed(() => {
	const periods: Array<number> = [defaultPeriod.value];

	if (defaultPeriod.value === 6) {
		periods.push(3);
	}

	if (defaultPeriod.value !== 6 && props.quotaEntries.length >= 3) {
		periods.push(6);
	}

	if (props.quotaEntries.length > 6) {
		periods.push(12);
	}

	return periods.sort((a, b) => a - b);
});

</script>

<template>
	<div class="bg-primary-800 block rounded-lg">
		<QuotaGraphChart
			:data="quotaEntriesFilteredByPeriod"
		/>
		<QuotaAveragePeriodSelection
			v-if="showPeriodSelection"
			:period-spans="availablePeriodSpans"
			:selected-period="selectedPeriod"
			@select-period="updateSelectedPeriod"
		/>
	</div>
</template>

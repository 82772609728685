import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import {
	MaybeRef, computed, unref,
} from 'vue';
import { selectProjectsQuotasByClientId } from '@/api/quota';
import { createQuotaFromSupabase } from '@/models/Quota';
import { useAuthentication } from '@/store/auth';

export interface ProjectsQuotasSearchParams {
	clientId: string;
}

export const useProjectsQuotasQuery = (
	searchParams: MaybeRef<ProjectsQuotasSearchParams>,
) => {
	const auth = useAuthentication();

	const enabled = computed(() => auth.isAuthenticated);

	const query = useQuery({
		queryKey: ['quotas', searchParams],
		queryFn: async () => {
			const { clientId } = unref(searchParams);

			if (!clientId) {
				return Promise.reject();
			}

			const quotas = await selectProjectsQuotasByClientId(clientId);

			if (!quotas.data) {
				return [];
			}

			return quotas.data
				.map((data) => {
					if (!data.quota_infos) {
						return [];
					}

					const quotaInfo = data.quota_infos;

					return data.quota_infos.quotas.map((quota) => createQuotaFromSupabase(quota, {
						is_extra: quotaInfo.is_extra,
						quota_info_id: quotaInfo.quota_info_id,
					}));
				})
				.flat()
				.filter((quota) => quota.isExtra !== true)
				.sort((a, b) => b.from.toMillis() - a.from.toMillis());
		},
		enabled,
		placeholderData: (previousData) => keepPreviousData(previousData ?? []),
		refetchOnWindowFocus: false,
	});

	return query;
};

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import hotkeys from 'hotkeys-js';
import vClickOutside from '../plugins/ClickOutsideDirective';
import CheckIcon from '../assets/check-icon.svg?component';
import SpinnerIcon from '../assets/spinner-icon.svg?component';
import TimesIcon from '../assets/times-icon.svg?component';
import { LogCardMenuItem } from '@/models/LogCard';

const props = withDefaults(defineProps<{
	items?: LogCardMenuItem[];
	hasFocusBeatInlineNote?: boolean;
	isSaving?: boolean;
}>(), {
	items: () => [],
	hasFocusBeatInlineNote: false,
	isSaving: false,
});

const isClicked = ref(false);
const pendingConfirmationItem = ref<null | LogCardMenuItem>(null);
const isTouchDevice = computed(() => 'ontouchstart' in document.documentElement);

const abortConfirmation = async () => {
	if (!pendingConfirmationItem.value) {
		return;
	}

	if (pendingConfirmationItem.value.onAbortConfirmation) {
		const abortResult = await pendingConfirmationItem.value.onAbortConfirmation();

		if (abortResult.isErr()) {
			return;
		}
	}

	isClicked.value = false;
	pendingConfirmationItem.value = null;
};

const clickOutside = () => {
	if (pendingConfirmationItem.value && pendingConfirmationItem.value.onAbortConfirmation) {
		return;
	}

	isClicked.value = false;
	pendingConfirmationItem.value = null;
};

const submit = async () => {
	if (!pendingConfirmationItem.value) {
		return;
	}

	const submitResult = await pendingConfirmationItem.value.onSubmit();

	if (submitResult.isErr()) {
		return;
	}

	isClicked.value = false;
	pendingConfirmationItem.value = null;
};

const requestConfirmationForItem = async (item: LogCardMenuItem) => {
	pendingConfirmationItem.value = item;

	if (item.needsConfirmation) {
		if (pendingConfirmationItem.value.onRequestConfirmation) {
			const abortResult = await pendingConfirmationItem.value.onRequestConfirmation();

			if (abortResult.isErr()) {
				return;
			}
		}

		isClicked.value = true;
		return;
	}

	submit();
};

onMounted(() => {
	hotkeys('ESC', () => {
		if (isClicked.value) {
			abortConfirmation();
		}
	});
	hotkeys('enter', () => {
		if (props.hasFocusBeatInlineNote) {
			return;
		}

		if (isClicked.value) {
			submit();
		}
	});
});
</script>

<template>
	<div
		v-click-outside="clickOutside"
		class="bg-primary-300 h-8 rounded-full flex items-center justify-center px-3 transition transform-gpu transition-no-flicker"
		:class="{
			'translate-y-1 opacity-0 group-hover:-translate-y-0 group-hover:opacity-100': !isClicked && !isTouchDevice,
			'opacity-100': !isClicked && isTouchDevice,
		}"
	>
		<div class="relative">
			<transition
				name="fade"
				mode="out-in"
			>
				<div
					v-if="!isClicked"
					class="flex items-center space-x-3"
				>
					<button
						v-for="item in items"
						:key="item.id"
						class="cursor-pointer text-gray hover:text-white"
						type="button"
						:disabled="isSaving"
						@click="requestConfirmationForItem(item)"
					>
						<component
							:is="item.id === 'Confirm' && isSaving ? SpinnerIcon : item.icon"
							v-tippy="{ content: item.id === 'Confirm' && isSaving ? $t('common.saving') : item.tippyContent, theme: 'sunhealth-mt' }"
							tabindex="-1"
							class="fill-current subpixel-antialiased focus:outline-none"
							:class="item.id === 'Confirm' && isSaving ? 'animate-spin' : ''"
						/>
					</button>
				</div>
				<div
					v-else
					class="flex justify-around items-center ease-in-out duration-600 z-10 space-x-3"
				>
					<button
						class="text-gray hover:text-white"
						tabindex="-1"
						type="submit"
						@click="submit"
					>
						<CheckIcon
							v-tippy="{ content: $t('common.confirm'), theme: 'sunhealth-mt' }"
							class="fill-current w-5 h-5 object-contain focus:outline-none"
						/>
					</button>
					<button
						class="text-gray hover:text-white"
						tabindex="-1"
						type="button"
						@click="abortConfirmation"
					>
						<TimesIcon
							v-tippy="{ content: $t('common.cancel'), theme: 'sunhealth-mt' }"
							class="fill-current focus:outline-none"
						/>
					</button>
				</div>
			</transition>
		</div>
	</div>
</template>

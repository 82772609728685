import { MaybeRef, computed, unref } from 'vue';
import { Beat } from '@/models/Beats';
import { Client } from '@/models/Client';

export interface UseInternalBeatsOptions {
	beats: MaybeRef<Beat[]>;
	internalClients: MaybeRef<Client[]>;
}

export const useInternalBeats = (options: UseInternalBeatsOptions) => {
	const internalClientIds = computed(() => unref(options.internalClients).map((client) => client.id));

	const isUnknownBeat = (beat: Beat) => internalClientIds.value.includes(beat.clientId);

	const internalBeats = computed(() => unref(options.beats).filter(isUnknownBeat));

	return {
		internalBeats,
	};
};

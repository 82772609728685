import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import {
	archiveProjectById, createNewProject, updateProjectById,
} from '@/api/project';
import { ProjectMeta, toSupabaseData } from '@/models/Project';

export const useProjectsMutation = () => {
	const queryClient = useQueryClient();

	const createProject = useMutation({
		async mutationFn(
			variables: {
				project: ProjectMeta;
			},
		) {
			try {
				const response = await createNewProject(variables.project.clientId, variables.project.name, variables.project.description ?? '');

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result, data) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['quotas', { clientId: data.project.clientId }] }),
				queryClient.invalidateQueries({ queryKey: ['projects'] }),
			]);
		},
	});

	const archiveProject = useMutation({
		async mutationFn(
			variables: {
				projectId: string;
			},
		) {
			try {
				const response = await archiveProjectById(variables.projectId);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['quotas'] }),
				queryClient.invalidateQueries({ queryKey: ['projects'] }),
			]);
		},
	});

	const updateProject = useMutation({
		async mutationFn(
			variables: {
				projectId: string;
				project: ProjectMeta;
			},
		) {
			try {
				const response = await updateProjectById(variables.projectId, toSupabaseData(variables.project));
				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['projects'] }),
			]);
		},
	});

	const addQuotaInfoToProject = useMutation({
		async mutationFn(
			variables: {
				projectId: string;
				quotaInfoId: string;
			},
		) {
			try {
				const response = await updateProjectById(variables.projectId, { quota_info_id: variables.quotaInfoId });
				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return result;
			}
			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['projects'] }),
			]);
		},
	});

	return {
		createProject,
		archiveProject,
		updateProject,
		addQuotaInfoToProject,
	};
};

<script setup lang="ts">
import { use } from 'echarts/core';
import { BarChart, LineChart } from 'echarts/charts';
import {
	TitleComponent,
	TooltipComponent,
	GridComponent,
	MarkLineComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import VChart from 'vue-echarts';
import { BarSeriesOption, EChartsOption } from 'echarts';
import { computed } from 'vue';
import { z } from 'zod';
import { QuotaMonthlyEntry } from '@/models/QuotaEntry';
import { hoursToDisplayTime } from '@/shared/times';

const props = withDefaults(defineProps<{
	data?: QuotaMonthlyEntry[];
}>(), {
	data: () => [],
});

use([
	TitleComponent,
	TooltipComponent,
	GridComponent,
	MarkLineComponent,
	BarChart,
	LineChart,
	CanvasRenderer,
]);

const monthsArray = computed(() => props.data.map(({ month }) => month.monthLong) ?? []);
const monthlyHoursArray = computed(() => props.data.map(({ monthlyHours }) => monthlyHours) ?? []);

const loggedHoursSeriesOptionData = computed<BarSeriesOption['data']>(() => props.data
	.map(({ loggedHours, monthlyHours }) => {
		const value = loggedHours > monthlyHours ? monthlyHours : loggedHours;

		return {
			value,
			itemStyle: {
				borderRadius: loggedHours > monthlyHours ? 0 : [15, 15, 0, 0],
			},
			monthlyHours,
			loggedHours,
		};
	}));

const overHoursSeriesOptionData = computed<BarSeriesOption['data']>(() => props.data
	.map(({ loggedHours, monthlyHours }) => {
		const value = loggedHours > monthlyHours ? loggedHours - monthlyHours : 0;

		return {
			value,
			monthlyHours,
			loggedHours,
		};
	}));

const monthlyHoursSeriesOptionData = computed<BarSeriesOption['data']>(() => props.data
	.map(({ loggedHours, monthlyHours }) => ({
		value: monthlyHours,
		monthlyHours,
		loggedHours,
	})));

const stackedHoursDataSchema = z.object({
	loggedHours: z.number(),
	monthlyHours: z.number(),
});

const option = computed<EChartsOption>(() => ({
	title: {
		text: 'Kontingente der letzten Monate',
		show: false,
	},
	tooltip: {
		trigger: 'item',
		backgroundColor: '#201C4B',
		textStyle: {
			color: 'white',
		},
		padding: 25,
		borderWidth: 0,
		borderRadius: 10,
	},
	xAxis: [
		{
			type: 'category',
			data: monthsArray.value,
			axisLabel: {
				show: true,
				color: '#fff',
			},
			axisLine: {
				show: false,
			},
			axisTick: {
				show: false,
			},
			splitLine: {
				show: false,
			},
		},
	],
	yAxis: [
		{
			show: true,
			type: 'value',
			max: 'dataMax',
			axisLabel: {
				show: false,
			},
			axisTick: {
				show: false,
			},
			splitLine: {
				show: false,
			},
		},
	],
	series: [
		{
			name: 'Quota',
			type: 'line',
			data: monthlyHoursArray.value,
			itemStyle: {
				color: '#ffffff',
			},
			z: 10,
			symbolSize: 8,
			emphasis: {
				scale: true,
			},
			tooltip: {
				formatter(params) {
					return `
                        <strong>Kontingent</strong><br />
                        <strong>${params.name}</strong><br />
                        ${hoursToDisplayTime(params.value as number)}h
                    `;
				},
			},
		},
		{
			name: 'Background',
			type: 'bar',
			data: monthlyHoursSeriesOptionData.value,
			roundCap: true,
			barWidth: 23,
			itemStyle: {
				color: '#42D392',
				opacity: 0.2,
				borderRadius: [15, 15, 0, 0],
			},
			barGap: '-100%',
			z: 1,
			tooltip: {
				formatter(params) {
					const result = stackedHoursDataSchema.safeParse(params.data);

					if (!result.success) {
						return '';
					}

					if (result.data.loggedHours > result.data.monthlyHours) {
						return `
                            <strong>${params.name}</strong><br />
                            ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                            +${(hoursToDisplayTime(result.data.loggedHours - result.data.monthlyHours))}h überzogen
                        `;
					}

					return `
                        <strong>${params.name}</strong><br />
                        ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                    `;
				},
			},
		},
		{
			name: 'Erfasste Stunden',
			type: 'bar',
			data: loggedHoursSeriesOptionData.value,
			stack: 'hours',
			barWidth: 23,
			itemStyle: {
				color(params) {
					const result = stackedHoursDataSchema.safeParse(params.data);

					if (result.success && result.data.loggedHours >= result.data.monthlyHours) {
						return '#FF874B';
					}

					return '#42D392';
				},
			},
			z: 3,
			tooltip: {
				formatter(params) {
					const result = stackedHoursDataSchema.safeParse(params.data);

					if (!result.success) {
						return '';
					}

					if (result.data.loggedHours > result.data.monthlyHours) {
						return `
                            <strong>${params.name}</strong><br />
                            ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                            <span style="color:#FF874B">+${(hoursToDisplayTime(result.data.loggedHours - result.data.monthlyHours))}h überzogen</span>
                        `;
					}

					return `
                        <strong>${params.name}</strong><br />
                        ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                    `;
				},
			},
		},
		{
			name: 'Überstunden',
			type: 'bar',
			data: overHoursSeriesOptionData.value,
			stack: 'hours',
			barWidth: 23,
			itemStyle: {
				color: '#FF874B',
				borderRadius: [15, 15, 0, 0],
				decal: {
					color: 'rgba(0,0,0,0.2)',
					dashArrayX: [1, 0],
					dashArrayY: [2, 5],
					symbolSize: 2,
					rotation: Math.PI / 6,
				},
			},
			markLine: {
				symbol: 'none',
				label: {
					show: false,
				},
				lineStyle: {
					color: 'white',
					width: 2,
				},
				z: 10,
				data: [
					{
						type: 'average',
						name: 'Durchschnitt',
					},
				],
				tooltip: {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					formatter(params: any) {
						return `<strong>${params.name}</strong><br />${hoursToDisplayTime(params.value as number)}h`;
					},
				},
			},
			z: 3,
			tooltip: {
				formatter(params) {
					const result = stackedHoursDataSchema.safeParse(params.data);

					if (!result.success) {
						return '';
					}

					if (result.data.loggedHours > result.data.monthlyHours) {
						return `
                            <strong>${params.name}</strong><br />
                            ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                            <span style="color:#FF874B">+${(hoursToDisplayTime(result.data.loggedHours - result.data.monthlyHours))}h überzogen</span>
                        `;
					}

					return `
                        <strong>${params.name}</strong><br />
                        ${hoursToDisplayTime(result.data.loggedHours)} / ${hoursToDisplayTime(result.data.monthlyHours)}h <br />
                    `;
				},
			},
		},
	],
}));
</script>

<template>
	<div class="w-full h-[400px]">
		<VChart
			class="relative z-10"
			:option="option"
		/>
	</div>
</template>

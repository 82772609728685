<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { isEqual } from 'lodash';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { useSortable } from '@vueuse/integrations/useSortable';
import { useI18n } from 'vue-i18n';
import SuggestionChipList from '../SuggestionChips/SuggestionChipList.vue';
import AppButton from '../AppButton.vue';
import DraggableChip from '../DraggableChip.vue';
import { useRouteLeaveConfirmation } from '@/composables/useRouteLeaveConfirmation';
import { Activity } from '@/models/Activity';
import { getOrderedUserActivities } from '@/api/activity';
import { ActivityOrdering, UserSettings } from '@/models/UserSettings';
import { useUserSettings } from '@/services/UserSettings';
import { SelectOption, getOptionValue } from '@/models/Select';

const { t } = useI18n();
const isLoading = ref(false);

const { settings, updateUserSettings, updateUserActivityRanking } = useUserSettings();

const toast = useToast();

const computedMessages = computed(() => ({
	alphabetical: t('settings.sortingActivities.alphabetical'),
	degreeOfUtilization: t('settings.sortingActivities.degreeOfUtilization'),
	individual: t('settings.sortingActivities.individual'),
	successChangesWereSaved: t('notifications.successChangesWereSaved'),
}));

const sortOptions: SelectOption<ActivityOrdering>[] = [
	{ value: 'alpha', label: computedMessages.value.alphabetical },
	{ value: 'usage', label: computedMessages.value.degreeOfUtilization },
	{ value: 'custom', label: computedMessages.value.individual },
];

const selectedSortOption = ref<SelectOption<ActivityOrdering>>(settings.value?.activityOrdering ?? 'alpha');

const selectedSortOptionValue = computed(() => getOptionValue(selectedSortOption.value));

const { data: activitiesOrderPreview } = useQuery({
	queryKey: ['userActivitiesPreview', selectedSortOptionValue],
	queryFn: () => getOrderedUserActivities(getOptionValue(selectedSortOptionValue.value)),
	placeholderData: keepPreviousData,
	refetchOnWindowFocus: false,
});

const customActivitiesOrderPreview = ref<Activity[]>([]);

watch(activitiesOrderPreview, (activities) => {
	customActivitiesOrderPreview.value = activities ?? [];
}, { immediate: true });

const sortListWrapper = ref<HTMLElement | null>(null);

const { option: sortableOption } = useSortable(sortListWrapper, customActivitiesOrderPreview, {
	disabled: selectedSortOptionValue.value !== 'custom',
	scrollSensitivity: 35,
	forceAutoScrollFallback: true,
});

watch(selectedSortOptionValue, (option) => {
	sortableOption('disabled', option !== 'custom');
}, { immediate: true });

watch(settings, (newSettings) => {
	if (!newSettings) return;

	selectedSortOption.value = newSettings.activityOrdering;
}, { immediate: true });

const localSettings = computed<Partial<UserSettings>>(() => ({
	...settings.value,
	activityOrdering: getOptionValue(selectedSortOption.value),
}));

const hasChanges = computed(() => {
	const hasChangedSettings = !isEqual(settings.value, localSettings.value);
	const hasChangedCustomOrdering = !isEqual(activitiesOrderPreview.value, customActivitiesOrderPreview.value);
	return hasChangedSettings || hasChangedCustomOrdering;
});

const updateSettings = async () => {
	isLoading.value = true;

	await updateUserSettings({
		activityOrdering: selectedSortOptionValue.value,
	});

	if (selectedSortOptionValue.value === 'custom') {
		await updateUserActivityRanking(customActivitiesOrderPreview.value);
	}

	toast.success(computedMessages.value.successChangesWereSaved);

	isLoading.value = false;
};

const computedMessagesModal = computed(() => ({
	title: t('modal.unsavedChanges.title'),
	description: t('modal.unsavedChanges.description'),
	confirmLabel: t('modal.unsavedChanges.confirmLabel'),
	cancelLabel: t('modal.unsavedChanges.cancelLabel'),
}));

useRouteLeaveConfirmation({
	modal: computedMessagesModal.value,
	showModal: hasChanges,
});
</script>

<template>
	<div
		class="bg-primary-400 rounded-[18px] px-[53px] py-10"
	>
		<div class="flex gap-12 justify-between">
			<div class="space-y-[14px]">
				<p class="text-[28px] font-bold">
					{{ $t('settings.sortingActivities.title') }}
				</p>
				<p class="text-xl opacity-50">
					{{ $t('settings.sortingActivities.description') }}
				</p>
			</div>
		</div>
		<div
			class="flex flex-col"
		>
			<div
				class="grid grid-cols-2 gap-8 my-11"
			>
				<div class="bg-primary-500 rounded-[18px] p-[42px] space-y-14">
					<p class="text-xl font-bold">
						{{ $t('settings.sortingActivities.typeOfSorting') }}
					</p>
					<SuggestionChipList
						v-model="selectedSortOption"
						:options="sortOptions"
						required
					/>
				</div>
				<div class="bg-primary-500 rounded-[18px] p-[42px] space-y-14">
					<p class="text-xl font-bold">
						<template v-if="getOptionValue(selectedSortOption) === 'custom'">
							{{ $t('settings.sortingActivities.moveToYourPreference') }}
						</template>
						<template v-else>
							{{ $t('settings.sortingActivities.preview') }}
						</template>
					</p>
					<div
						ref="sortListWrapper"
						class="flex flex-col gap-[10px] max-h-[400px] overflow-auto has-custom-scrollbar pr-2"
					>
						<DraggableChip
							v-for="activity in customActivitiesOrderPreview"
							:key="activity.id"
							:draggable="getOptionValue(selectedSortOption) === 'custom'"
						>
							{{ activity.name }}
						</DraggableChip>
					</div>
				</div>
			</div>
			<AppButton
				size="md"
				class="mx-auto"
				:disabled="!hasChanges || isLoading"
				@click="updateSettings"
			>
				{{ $t('settings.saveSettings') }}
			</AppButton>
		</div>
	</div>
</template>

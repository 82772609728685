import { DateTime } from 'luxon';
import {
	Beat, BeatMeta, SupabaseBeat, createBeatFromSupabase, toSupabaseData,
} from '../models/Beats';
import { supabase } from '@/lib/supabase';

export const fetchBeats = async (options: {
	fromDate: DateTime;
	toDate?: DateTime;
	userId: string;
}): Promise<Beat[]> => {
	const fromDate = options.fromDate.startOf('day');
	const toDate = options.toDate ? options.toDate.endOf('day') : DateTime.now().endOf('day');

	const { data } = await supabase
		.from('beats')
		.select('*')
		.eq('archived', false)
		.eq('user_id', options.userId)
		.order('timestamp', { ascending: false })
		.gt('timestamp', fromDate)
		.lt('timestamp', toDate);

	return data?.map(createBeatFromSupabase) ?? [];
};

export const getAllBeatsForToday = async (): Promise<Beat[]> => {
	const { data } = await supabase
		.from('beats')
		.select('*')
		.eq('archived', false)
		.order('timestamp')
		.gt('timestamp', DateTime.now().startOf('day').toISO());

	return data?.map(createBeatFromSupabase) ?? [];
};

export const updateBeatById = async (beatId: string, properties: Partial<SupabaseBeat>) => {
	const { data } = await supabase
		.from('beats')
		.update(properties)
		.eq('beat_id', beatId);

	return data;
};

export const createNewBeat = async (meta: BeatMeta) => {
	const supabaseBeat = toSupabaseData(meta);

	const { data } = await supabase
		.from('beats')
		.insert(supabaseBeat)
		.select()
		.single();

	return data ? createBeatFromSupabase(data) : null;
};

export const archiveBeat = (beatId: string) => updateBeatById(beatId, { archived: true });

<script setup lang="ts">
import AppModal from '../AppModal.vue';

defineProps<{
	cancelLabel: string;
	confirmLabel: string;
	title: string;
	description: string;
}>();

const emit = defineEmits<{
	(e: 'cancel'): void;
	(e: 'confirm'): void;
}>();

const onCancel = () => {
	emit('cancel');
};

const onConfirm = () => {
	emit('confirm');
};
</script>

<template>
	<AppModal
		modal-id="confirmation"
		:confirm-label="confirmLabel"
		:cancel-label="cancelLabel"
		:title="title"
		:description="description"
		@confirm="onConfirm"
		@cancel="onCancel"
	/>
</template>

<script setup lang="ts">
import {
	computed, onMounted, ref, watch, watchEffect,
} from 'vue';
import 'v-calendar/dist/style.css';
import { Calendar } from 'v-calendar';
import { DateTime } from 'luxon';
import {
	onBeforeRouteLeave, RouteLocationNormalizedLoaded, useRoute, useRouter,
} from 'vue-router';
import { AttributeConfig } from 'v-calendar/dist/types/src/utils/attribute';
import { useI18n } from 'vue-i18n';
import { useMainStore } from '../store/main';
import { msToDisplayTime } from '../shared/times';
import {
	CalendarAttribute, getBeatsFromAttributes, getTotalTimeInMs,
} from '../models/Beats';
import ArrowIcon from '../assets/long-arrow-left-regular.svg?component';
import { LANGUAGE } from '../utilities/Constants';
import AppChip from '../components/AppChip.vue';
import AppIcon from '../components/AppIcon.vue';
import { CalendarDay } from '../models/CalendarDay';
import { maxLetterLength } from '../utilities/Helpers';
import { useFeiertage } from '../composables/useFeiertage';
import { getTopClients } from '../models/Client';
import { useClientsStore } from '../store/clients';
import { useUserWorkScheduleQuery } from '@/composables/queries/useUserWorkSchedule';
import StatItem from '@/components/StatItem.vue';
import { useAbsencesStore } from '@/store/absences';
import { useBeatsStore } from '@/store/beats';
import { DisplayDate } from '@/models/DisplayDate';
import { isIsoDateInAbsences } from '@/models/Absence';

const { t } = useI18n();
const mainStore = useMainStore();
const beatsStore = useBeatsStore();
const clientsStore = useClientsStore();
const absencesStore = useAbsencesStore();
const router = useRouter();
const route = useRoute();
const beats = computed(() => beatsStore.beats);
const calendarRef = ref<typeof Calendar>();
const masks = { weekdays: 'WWW' };

const formatDisplayDate = (date: DateTime): DisplayDate => ({
	year: date.year,
	month: date.month,
	day: date.day,
});

const getDefaultDisplayDate = (): DisplayDate => {
	const currentDateObject = DateTime.now();
	return formatDisplayDate(currentDateObject);
};

const getDisplayDateFromRoute = (inputRoute: RouteLocationNormalizedLoaded): DisplayDate => {
	const dayQuery = Array.isArray(inputRoute.query.day) ? inputRoute.query.day[0] : inputRoute.query.day;

	if (dayQuery) {
		const parsedDateTime = DateTime.fromISO(dayQuery);
		if (parsedDateTime.isValid) {
			return formatDisplayDate(parsedDateTime);
		}
	}
	return getDefaultDisplayDate();
};

const displayDate = ref<DisplayDate>(getDisplayDateFromRoute(route));
const displayDateInDateTime = computed(() => DateTime.fromObject(displayDate.value));
const dayRoute = computed(() => displayDateInDateTime.value.toISODate());
const calendarTitle = computed(() => displayDateInDateTime.value.toFormat('LLLL yyyy', { locale: LANGUAGE }));

const { data: userWorkSchedule } = useUserWorkScheduleQuery(computed(() => ({ date: displayDateInDateTime.value })));

watch(displayDate, (newDisplayDate, oldDisplayDate) => {
	if (oldDisplayDate && newDisplayDate.year === oldDisplayDate.year) {
		return;
	}
	beatsStore.requestBeatsForYear(displayDate.value.year);
	absencesStore.requestAbsencesForYear(displayDate.value.year);
}, { immediate: true });

const stopRouteQueryUpdate = watch(displayDate, (newDisplayDate) => {
	const currentRouteDisplayDate = getDisplayDateFromRoute(route);

	if (currentRouteDisplayDate.year === newDisplayDate.year
		&& currentRouteDisplayDate.month === newDisplayDate.month) {
		return;
	}

	router.replace({ name: 'History', query: { day: displayDateInDateTime.value.toISODate() } });
});

const attributesWithBeats = computed<AttributeConfig[]>(() => [...beats.value].map((beat) => ({
	key: beat.beatId,
	dates: [beat.timestamp.toJSDate()],
	customData: {
		beat,
	},
})));

const isCurrentMonth = (date: DateTime) => {
	const now = DateTime.now();
	return date.hasSame(now, 'month') && date.hasSame(now, 'year');
};

const prevMonth = () => {
	let prevMonthDateTime = displayDateInDateTime.value.minus({ month: 1 }).set({ day: 1 });

	if (isCurrentMonth(prevMonthDateTime)) {
		prevMonthDateTime = prevMonthDateTime.set({ day: DateTime.now().day });
	}

	displayDate.value = formatDisplayDate(prevMonthDateTime);
};

const nextMonth = () => {
	let nextMonthDateTime = displayDateInDateTime.value.plus({ month: 1 }).set({ day: 1 });

	if (isCurrentMonth(nextMonthDateTime)) {
		nextMonthDateTime = nextMonthDateTime.set({ day: DateTime.now().day });
	}
	displayDate.value = formatDisplayDate(nextMonthDateTime);
};

const thisMonth = () => {
	displayDate.value = getDefaultDisplayDate();
};

const hasBeatsForDay = (attributes?: CalendarAttribute[]) => {
	if (!attributes) {
		return false;
	}

	return attributes.length > 0;
};

watchEffect(async () => {
	await calendarRef.value?.move(displayDate.value);
});

const beatsOfMonth = computed(() => beatsStore.getBeatsFromMonth(displayDateInDateTime.value));

onMounted(() => {
	displayDate.value = getDisplayDateFromRoute(route);
});

onBeforeRouteLeave(() => {
	stopRouteQueryUpdate();
});

const { isCompanyHoliday, renderHolidayContent } = useFeiertage(displayDateInDateTime);

const isAbsence = (day: CalendarDay) => {
	if (!mainStore.user) return false;
	if (!isIsoDateInAbsences({
		isoDate: day.id, absences: absencesStore.absences, userId: mainStore.user.id, userWorkSchedule: userWorkSchedule.value,
	})) return false;
	return true;
};

const onDayClick = (day: CalendarDay) => {
	if (!mainStore.user) {
		return;
	}
	router.push({ name: 'DaySingle', params: { day: DateTime.fromJSDate(day.date).toISODate() } });
};
</script>

<template>
	<div class="text-center section">
		<div class="px-6 desktop:px-0 mb-6 desktop:mb-9 flex flex-col desktop:flex-row mt-6 desktop:justify-between space-y-[31px] desktop:space-y-0 desktop:space-x-8">
			<div class="flex space-x-9">
				<div class="flex items-center">
					<div class="w-3 phablet:w-12 h-1 bg-secondary rounded-2xl mr-4" />
					<p class="text-xl mobile:text-2xl desktop:text-4xl font-bold">
						{{ calendarTitle }}
					</p>
					<StatItem
						:label="t('common.total')"
						:value="getTotalTimeInMs(beatsOfMonth)"
						:formatter="msToDisplayTime"
						suffix="h"
						class="ml-6 pl-6 border-l border-primary-300 text-left"
					/>
				</div>
			</div>
			<div class="flex items-center justify-between desktop:justify-start desktop:space-x-10">
				<div class="flex items-center space-x-4">
					<button
						class="flex items-center justify-center rounded-full py-[9px] phablet:py-4 px-[6px] phablet:px-3 border-2 border-primary-300 w-[35px] phablet:w-12 h-[35px] phablet:h-12 hover:bg-primary-400 text-gray hover:text-white"
						type="button"
						@click="prevMonth()"
					>
						<ArrowIcon class="fill-current w-full h-full" />
					</button>
					<button
						class="flex items-center justify-center rounded-full py-[9px] phablet:py-4 px-[6px] phablet:px-3 border-2 border-primary-300 w-[35px] phablet:w-12 h-[35px] phablet:h-12 hover:bg-primary-400 text-gray hover:text-white"
						type="button"
						@click="nextMonth()"
					>
						<ArrowIcon class="fill-current transform rotate-180 w-full h-full" />
					</button>
				</div>
				<div class="flex space-x-2">
					<router-link :to="{ name: 'DaySingle', params: { day: dayRoute } }">
						<AppChip>
							{{ $t('common.day') }}
						</AppChip>
					</router-link>
					<AppChip
						:selected="true"
						@click="thisMonth"
					>
						{{ $t('common.month') }}
					</AppChip>
				</div>
			</div>
		</div>
		<Calendar
			ref="calendarRef"
			:initial-page="displayDate"
			class="custom-calendar max-w-full desktop:mt-6 px-6 desktop:px-0"
			:masks="masks"
			:attributes="attributesWithBeats"
			:locale="LANGUAGE"
			:first-day-of-week="2"
			expanded
		>
			<template #day-content="{ day, attributes }">
				<div
					class="flex flex-col h-full z-10 overflow-hidden group p-2 select-none"
					:class="{
						'cursor-pointer phablet:hover:bg-primary-400 transition': true,
						'outline-[1px] outline-success outline': mainStore.howConfidentBeatTimestamp?.toISODate() === day.id,
					}"
					@click.left.exact="onDayClick(day)"
					@keydown.enter="onDayClick(day)"
				>
					<div class="flex gap-2 absolute">
						<AppIcon
							v-if="isAbsence(day)"
							class="hidden desktop:block"
							icon="absence"
						/>
						<AppIcon
							v-if="isCompanyHoliday(day.id)"
							v-tippy="{
								theme: 'sunhealth-mt',
								content: renderHolidayContent(day.id),
								allowHTML: true,
							}"
							class="hidden desktop:block"
							icon="holiday"
						/>
					</div>
					<span class="day-label text-sm">
						{{ day.day }}
						<span class="middle:hidden">({{ DateTime.fromJSDate(day.date).toFormat('ccc') }})</span>
					</span>
					<div class="flex flex-col justify-center items-center h-full -mt-4">
						<template v-if="hasBeatsForDay(attributes)">
							<div class="text-center text-white text-opacity-75 font-bold text-2xl middle:text-[20px]">
								{{ msToDisplayTime(getTotalTimeInMs(getBeatsFromAttributes(attributes))) }}
							</div>
							<div
								v-if="getTopClients(getBeatsFromAttributes(attributes), clientsStore.clients, 3).length"
								class="flex desktop:grid desktop:grid-cols-4 gap-1 text-center text-base middle:text-[14px] text-white text-opacity-25 justify-center mt-1"
							>
								<div
									v-for="(client, index) in getTopClients(getBeatsFromAttributes(attributes), clientsStore.clients, 3)"
									:key="client.id"
									:class="(getTopClients(getBeatsFromAttributes(attributes), clientsStore.clients, 3).length === 2) ? 'desktop:col-span-2' : 'desktop:col-span-2 desktop:last:col-span-4'"
								>
									{{ maxLetterLength(client.name, 5) }}{{ index !== Object.keys(getTopClients(getBeatsFromAttributes(attributes), clientsStore.clients, 3)).length - 1 ? ',' : '' }}
								</div>
							</div>
						</template>
						<div
							v-else
							class="text-center text-base middle:text-[14px] text-white text-opacity-25"
						>
							{{ $t('calendar.noLogs') }}
						</div>
					</div>
				</div>
			</template>
		</Calendar>
	</div>
</template>

<style scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

:deep(.custom-calendar.vc-container) {
    --day-border: 1px solid #2a2656;
    --day-border: 1px solid #2a2656;
    --today-highlight: 1px solid #ffffff;
    --day-width: 100%;
    --day-height: 120px;
    --weekday-bg: #16123b;
    --border-color: #2a2656;
    --weekday-border: 1px solid #2a2656;
    @apply border-none bg-primary-500 rounded-none w-full;

    & .vc-header {
        @apply hidden bg-primary-500 py-[10px];
    }
    & .vc-title {
        @apply relative text-white text-opacity-75 pl-16 text-4xl;
    }
    & .vc-title {
        @apply before:absolute before:top-[18px] before:left-0 before:w-12 before:h-1 before:bg-secondary before:rounded-2xl;
    }
    & .vc-weeks {
        @apply p-0 grid-cols-1 phablet:grid-cols-2 middle:grid-cols-7;
    }
    & .vc-weekday {
        @apply hidden middle:block bg-primary-600 border-t border-b border-primary-300 py-[18px] mb-8 text-white text-opacity-50;
    }
    & .vc-weekday:nth-child(6),
    & .vc-weekday:nth-child(7) {
        @apply text-white text-opacity-25;
    }
    & .vc-day {
        @apply text-right h-28 phablet:h-32 phablet:min-w-fit bg-primary-500 text-white text-opacity-50 text-xs desktop:text-base font-sans font-medium;

        &.weekday-1,
        &.weekday-7 {
            background-color: var(--weekday-bg);

            &.day-label {
                opacity: 0.5;
            }
        }
    }

    @media screen and (min-width: 1400px) {
        & .vc-day {
            &:not(.on-bottom) {
                border-bottom: var(--day-border);

                &.weekday-1 {
                    border-bottom: var(--day-border-highlight);
                }
            }
            &:not(.on-right, .on-top) {
                border-right: var(--day-border);
            }
            &:not(.on-right, .on-top) {
                border-right: var(--day-border);
            }
            &:not(.on-right) {
                &.on-top {
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-image:
                    linear-gradient(
                        to top,
                    var(--border-color),
                    var(--weekday-bg)
                    ) 1 10%;
                }
                &.on-bottom {
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-image:
                    linear-gradient(
                        to bottom,
                    var(--border-color),
                    var(--weekday-bg)
                    ) 1 10%;
                }
            }
            &:not(.on-bottom) {
                &.on-left {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-image:
                    linear-gradient(
                        to left,
                    var(--border-color),
                    var(--weekday-bg)
                    ) 1 10%;
                }
                &.on-right {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-image:
                    linear-gradient(
                        to right,
                    var(--border-color),
                    var(--weekday-bg)
                    ) 1 10%;
                }
            }
        }
        & .vc-day.in-month.is-today {
            @apply border border-white border-opacity-90;

            &:not(.on-bottom) {
                &.on-left {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-image:
                    linear-gradient(
                        to left,
                    var(--today-highlight),
                    var(--weekday-bg)
                    ) 1 10%;
                }
                &.on-right {
                    border-bottom-width: 1px;
                    border-bottom-style: solid;
                    border-image:
                    linear-gradient(
                        to right,
                    var(--today-highlight),
                    var(--weekday-bg)
                    ) 1 10%;
                }
            }
            &:not(.on-right) {
                &.on-top {
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-image:
                    linear-gradient(
                        to top,
                    var(--today-highlight),
                    var(--weekday-bg)
                    ) 1 10%;
                }
                &.on-bottom {
                    border-right-width: 1px;
                    border-right-style: solid;
                    border-image:
                    linear-gradient(
                        to bottom,
                    var(--today-highlight),
                    var(--weekday-bg)
                    ) 1 10%;
                }
            }
        }
    }

    @media screen and (max-width: 1400px) {
        & .vc-day:not(.is-today) {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-image: linear-gradient(
                to right,
                var(--weekday-bg) 0%,
                var(--weekday-bg) 20%,
                var(--border-color) 33%,
                var(--border-color) 67%,
                var(--weekday-bg) 80%,
                var(--weekday-bg) 100%
                ) 1 10%;
        }
        & .vc-day.is-today:not(.on-bottom) {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            border-image: linear-gradient(
                to right,
                var(--weekday-bg) 0%,
                var(--weekday-bg) 20%,
                #ffffffB2 33%,
                #ffffffB2 67%,
                var(--weekday-bg) 80%,
                var(--weekday-bg) 100%
                ) 1 10%;
        }
        & .vc-day.is-today:not(.on-bottom) {
            border-top-width: 1px;
            border-top-style: solid;
            border-image: linear-gradient(
                to right,
                var(--weekday-bg) 0%,
                var(--weekday-bg) 20%,
                #ffffffB2 33%,
                #ffffffB2 67%,
                var(--weekday-bg) 80%,
                var(--weekday-bg) 100%
                ) 1 10%;
        }
    }
    & .vc-day.is-today .day-label {
        @apply text-white text-opacity-100;
    }
    & .vc-day.is-not-in-month {
        @apply hidden middle:block;
    }
    & .vc-day.in-next-month {
        @apply hidden;
    }
    & .vc-day-dots {
        @apply mb-1;
    }
    & .vc-svg-icon {
        @apply rounded-full py-2 px-1 border-2 border-primary-300 w-12 h-12 mb-8;
    }
    & .vc-arrow {
        @apply text-white text-opacity-50 bg-transparent hover:bg-transparent active:bg-transparent;
    }
    & .vc-arrows-container {
        @apply gap-6 -top-3 hidden;
    }
    & .vc-header {
        @apply mb-5;
    }
}
</style>

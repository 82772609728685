<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import ProjectForm from '@/components/ProjectForm.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { ProjectMeta } from '@/models/Project';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';

const props = defineProps<{
	clientId: string;
}>();

const { t } = useI18n();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();

const client = computed(() => clientsStore.allClients.find((_client) => _client.id === props.clientId));

const router = useRouter();

const onCancelEdit = () => {
	router.replace({ name: 'Admin' });
};

const toast = useToast();

const computedMessages = computed(() => ({
	success: t('notifications.successCreatedProject'),
}));

const onSave = async ({ name, description }: ProjectMeta) => {
	if (!client.value) {
		return;
	}

	const clientId = client.value.id;

	await projectsStore.createProject({ name, description, clientId });
	toast.success(`${computedMessages.value.success} "${name}"`);
	const newProject = projectsStore.allProjects.find((_project) => _project.name === name && _project.clientId === clientId);

	if (newProject) {
		router.replace({ name: 'Project', params: { projectId: newProject.id } });
	} else {
		router.replace({ name: 'Admin' });
	}
};
</script>

<template>
	<SidepanelWrapper>
		<div class="mb-10">
			<p class="text-2xl font-semibold">
				{{ $t('admin.addProject') }}
			</p>
			<p class="text-secondary">
				{{ client?.name }}
			</p>
		</div>
		<ProjectForm
			v-if="client"
			:editable="true"
			:client="client"
			@cancel="onCancelEdit"
			@save="onSave"
		/>
	</SidepanelWrapper>
</template>

import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { DateTime } from 'luxon';
import { MaybeRef, computed, unref } from 'vue';
import { useAuthentication } from '@/store/auth';
import { fetchBeats } from '@/api/beats';

export interface BeatsSearchParams {
	fromDate: MaybeRef<DateTime>;
	toDate?: MaybeRef<DateTime>;
	userId: MaybeRef<string | null>;
}

export const useBeatsQuery = (
	searchParams: BeatsSearchParams,
) => {
	const auth = useAuthentication();

	const enabled = computed(() => auth.isAuthenticated && unref(searchParams.userId) !== null);

	const query = useQuery({
		queryKey: ['beats', searchParams],
		queryFn: () => {
			const userId = unref(searchParams.userId);
			const fromDate = unref(searchParams.fromDate);
			const toDate = unref(searchParams.toDate);

			if (!userId) {
				return Promise.reject();
			}

			return fetchBeats({ userId, fromDate, toDate });
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};

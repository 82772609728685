import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { err } from 'neverthrow';
import { useMainStore } from './main';
import { useBeatsMutation } from '@/composables/queries/useBeatsMutation';
import { Beat, BeatMeta } from '@/models/Beats';
import { useToday } from '@/composables/useToday';
import { useBeatsQuery } from '@/composables/queries/useBeatsQuery';

export const useBeatsStore = defineStore('beats', () => {
	const today = useToday();
	const minYear = ref(today.value.year);

	const firstDayOfMinYear = computed(() => DateTime.now().set({ year: minYear.value }).startOf('year'));

	const mainStore = useMainStore();

	const userId = computed(() => mainStore.user?.id ?? null);

	const { data } = useBeatsQuery({
		userId,
		fromDate: firstDayOfMinYear,
	});

	const {
		createBeat: createBeatMutation,
		deleteBeat: deleteBeatMutation,
		updateBeat: updateBeatMutation,
	} = useBeatsMutation();

	const createBeat = (meta: BeatMeta) => {
		if (!mainStore.user) {
			return err(new Error('Unauthorized'));
		}

		return createBeatMutation.mutateAsync({ beat: meta, user: mainStore.user });
	};

	const deleteBeat = (beatId: string) => deleteBeatMutation.mutateAsync({ beatId });

	const updateBeat = (beatId: string, beat: BeatMeta) => updateBeatMutation.mutateAsync({ beatId, beat });

	const beats = computed(() => data.value ?? []);

	const beatsFromToday = computed<Beat[]>(() => {
		const todayDay = today.value.startOf('day').toISODate();

		return beats.value.filter((beat) => {
			const beatDate = beat.timestamp.toISODate();
			return beatDate === todayDay;
		}) ?? [];
	});

	const beatsFromWeek = computed<Beat[]>(() => beats.value.filter((beat) => beat.timestamp > today.value.startOf('week')) ?? []);

	const beatsFromMonth = computed<Beat[]>(() => beats.value.filter((beat) => beat.timestamp > today.value.startOf('month')) ?? []);

	const beatsFromYear = computed<Beat[]>(() => beats.value.filter((beat) => beat.timestamp > today.value.startOf('year')) ?? []);

	const getBeatsFromDay = (day: DateTime): Beat[] => beats.value.filter((beat) => day.hasSame(beat.timestamp, 'day'));

	const getBeatsFromMonth = (day: DateTime): Beat[] => {
		const startOfMonth = day.startOf('month').toISODate();
		const endOfMonth = day.endOf('month').toISODate();

		if (!startOfMonth || !endOfMonth) {
			return [];
		}

		return beats.value.filter((beat) => {
			const beatDate = beat.timestamp.toISODate();

			if (!beatDate) {
				return false;
			}

			return beatDate >= startOfMonth && beatDate <= endOfMonth;
		});
	};

	const requestBeatsForYear = (year: number) => {
		if (year < minYear.value) {
			minYear.value = year;
		}
	};

	return {
		beats,
		beatsFromToday,
		beatsFromWeek,
		beatsFromMonth,
		beatsFromYear,
		getBeatsFromDay,
		getBeatsFromMonth,
		createBeat,
		deleteBeat,
		updateBeat,
		requestBeatsForYear,
	};
});

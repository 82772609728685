<script setup lang="ts">
import { computed } from 'vue';
import SuggestionChip from './SuggestionChip.vue';
import { SelectOption, getOptionLabel, getOptionValue } from '@/models/Select';

type SelectionType = 'multi' | 'single';

const props = withDefaults(defineProps<{
	modelValue?: SelectOption | SelectOption[] | null;
	options?: SelectOption[];
	type?: SelectionType;
	required?: boolean;
}>(), {
	modelValue: null,
	options: () => [],
	type: 'single',
	required: false,
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: SelectOption | SelectOption[] | null): void;
}>();

const currentValue = computed(() => props.modelValue);

const isSelected = (option: SelectOption): boolean => {
	if (!currentValue.value) {
		return false;
	}

	if (Array.isArray(currentValue.value)) {
		return !!currentValue.value.find((value) => getOptionValue(value) === getOptionValue(option));
	}

	return !!(getOptionValue(currentValue.value) === getOptionValue(option));
};

const selectOption = (option: SelectOption) => {
	if (props.type === 'multi' && Array.isArray(currentValue.value)) {
		emit('update:modelValue', [...currentValue.value, option]);
	}

	emit('update:modelValue', option);
};

const unselectOption = (option: SelectOption) => {
	if (props.type === 'multi' && Array.isArray(currentValue.value)) {
		const optionIndex = currentValue.value.indexOf(option);
		const selectionCopy = [...currentValue.value];

		selectionCopy.splice(optionIndex, 1);

		if (props.required && selectionCopy.length === 0) {
			return;
		}

		emit('update:modelValue', selectionCopy);
		return;
	}

	if (props.required) {
		return;
	}

	emit('update:modelValue', null);
};

const onOptionClick = (option: SelectOption) => {
	const selected = isSelected(option);

	if (!selected) {
		selectOption(option);
		return;
	}

	unselectOption(option);
};
</script>

<template>
	<div class="flex flex-col gap-[10px]">
		<SuggestionChip
			v-for="option in options"
			:key="getOptionValue(option)"
			:is-selected="isSelected(option)"
			@click="onOptionClick(option)"
		>
			<slot :option="option">
				{{ getOptionLabel(option) }}
			</slot>
		</SuggestionChip>
	</div>
</template>

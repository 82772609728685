<script setup lang="ts">
import { computed } from 'vue';
import { DateTime } from 'luxon';
import { useMainStore } from '../store/main';

const mainStore = computed(() => useMainStore());

const displayDate = computed(() => mainStore.value.today.toLocaleString(DateTime.DATE_SHORT));
</script>

<template>
	<div class="flex justify-between items-center">
		<div class="flex items-center font-bold text-xl text-white text-opacity-75">
			<div class="bg-secondary w-6 h-1 rounded-full mr-4" />
			<div class="mr-2">
				{{ $t('dashboard.todayLogs') }}
			</div>
		</div>
		<div class="text-base">
			{{ displayDate }}
		</div>
	</div>
</template>

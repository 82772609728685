<script setup lang="ts">
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { Ref, computed, ref } from 'vue';
import { DateTime } from 'luxon';
import { TableCellsIcon, ListBulletIcon } from '@heroicons/vue/24/outline';
import PageTitle from '@/components/PageTitle.vue';
import { supabase } from '@/lib/supabase';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import TableQuotasOverview from '@/components/TableQuotasOverview.vue';
import TableQuotasDetails from '@/components/TableQuotasDetails.vue';
import TogglePeriod from '@/components/TogglePeriod.vue';
import { getActivityFromBeat, groupQuotaBeatsByActivity } from '@/models/Beats';
import { PieChartDataItem } from '@/models/PieChartDataItem';
import { useActivitiesStore } from '@/store/activities';
import { groupBy } from '@/utilities/Helpers';
import ChartPieSingle from '@/components/ChartPieSingle.vue';
import { QuotaOverviewEntry, QuotaMonthlyEntry, QuotaEntry } from '@/models/QuotaEntry';
import { PublicQuota, PublicQuotaRaw } from '@/models/PublicQuota';
import { getBeatsForQuota, getTotalTimeInHours } from '@/models/QuotaBeats';
import QuotaAverage from '@/components/QuotaAverage.vue';
import { hoursToDisplayTime } from '@/shared/times';
import { useQuotaBeatsQuery } from '@/composables/queries/useQuotaBeatsQuery';
import { useToday } from '@/composables/useToday';

const props = defineProps<{
	quotaListId: string;
}>();

const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const today = useToday();
const month = ref(today.value.month);
const startOfPreviousMonth = computed(() => DateTime.now().set({ month: month.value }).minus({ month: 1 }).startOf('month'));

// We want to exclude the Wordpress Team from the quota view
const EXCLUDE_TEAMS = ['1eec15e0-b898-6ed0-93e0-e0a78448ae44'];

const { data: quotaBeatsData } = useQuotaBeatsQuery({
	fromDate: startOfPreviousMonth,
	excludedTeams: EXCLUDE_TEAMS,
});

const quotaDisplayMode = ref<'list' | 'grid'>('list');

const fromDate: Ref<DateTime> = ref(DateTime.now().startOf('month'));
const toDate: Ref<DateTime> = ref(DateTime.now().endOf('month'));

const hasOverusage = (entry: QuotaOverviewEntry) => entry.loggedHours > entry.monthlyHours;

const { data: quotasAndBeatsData } = useQuery({
	queryKey: ['quotas'],
	queryFn: async () => {
		const { data } = await supabase
			.rpc('get_quotas_and_beat_infos', {
				quota_list_id: props.quotaListId,
				exclude_team_ids: EXCLUDE_TEAMS,
			});

		return data;
	},
	placeholderData: keepPreviousData,
});

const allQuotas = computed<PublicQuota[]>(() => quotasAndBeatsData.value?.map((quota: PublicQuotaRaw) => ({
	month: DateTime.fromISO(quota.date).startOf('month'),
	clientId: quota.client_id,
	projectId: quota.project_id,
	isExtra: quota.is_extra,
	monthlyHours: quota.monthly_hours,
	loggedHours: quota.total_logged_hours,
})) ?? []);

const quotaEntriesGroupedById = computed(() => allQuotas.value.reduce((result, quota) => {
	const client = clientsStore.clients.find((c) => c.id === quota.clientId);
	const project = projectsStore.projects.find((p) => p.id === quota.projectId);

	const name = (project ? `${project.name} - ${client?.name}` : client?.name) ?? 'Unknown';

	const id = (project ? `${quota.clientId}-${quota.projectId}` : quota.clientId);

	const quotaMonthlyEntryFormat = {
		name,
		month: quota.month,
		monthlyHours: quota.monthlyHours,
		loggedHours: quota.loggedHours,
	};

	return {
		...result,
		[id]: [...(result[id] || []),
			quotaMonthlyEntryFormat],
	};
}, {} as { [key: string]: QuotaMonthlyEntry[] }));

const quotaOverviewEntries = computed(() => Object.keys(quotaEntriesGroupedById.value).reduce((acc, key) => {
	const entry = ref(quotaEntriesGroupedById.value[key]);

	return [...acc, {
		id: key,
		name: entry.value[0].name,
		month: entry.value.find((item) => item.month.equals(fromDate.value))?.month ?? DateTime.fromISO('1999-01-01'),
		monthlyHours: entry.value.find((item) => item.month.equals(fromDate.value))?.monthlyHours ?? 0,
		loggedHours: entry.value.find((item) => item.month.equals(fromDate.value))?.loggedHours ?? 0,
		monthlyEntries: entry.value.filter((item) => item.month <= fromDate.value),
	}];
}, [] as QuotaOverviewEntry[]));

const currentPeriodQuotaEntries = computed(() => quotaOverviewEntries.value.filter((entry) => entry.month.equals(fromDate.value)));

const quotaBeats = computed(() => quotaBeatsData.value);

const currentStatsPeriodQuotaBeats = computed(() => quotaBeats.value
	.filter((beat) => beat.month.equals(fromDate.value)));

const currentStatsPeriodQuotasWithBeats = computed<QuotaEntry[]>(() => allQuotas.value
	.filter((quota) => quota.month.equals(fromDate.value))
	.map((quota) => {
		const client = clientsStore.clients.find((c) => c.id === quota.clientId);
		const project = projectsStore.projects.find((p) => p.id === quota.projectId);
		const beatsOfQuota = getBeatsForQuota(quota, currentStatsPeriodQuotaBeats.value);
		const name = (project ? `${project.name} - ${client?.name}` : client?.name) ?? 'Unknown';

		return {
			clientId: quota.clientId,
			name,
			logCount: beatsOfQuota.length,
			monthlyHours: quota.monthlyHours,
			loggedHours: quota.loggedHours,
			alert: false,
			isExtra: quota.isExtra,
			isQuota: beatsOfQuota[0]?.isQuota,
			projectId: quota.projectId,
			beats: beatsOfQuota,
		};
	}) ?? []);

const quotasDetailsTable = ref<InstanceType<typeof TableQuotasDetails>>();

const selectedRows = computed(() => quotasDetailsTable.value?.table.getSelectedRowModel());

const selectedRow = computed(() => selectedRows.value?.flatRows[0]);

const selectedParentRow = computed(() => selectedRow.value?.getParentRow() ?? null);

const selectedRowUserChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}
	const groupedByUsers = groupBy(selectedRow.value.original.beats, 'userName');
	return Object.entries(groupedByUsers)
		.map(([userName, beats]) => ({
			label: userName,
			value: getTotalTimeInHours(beats),
		}))
		.sort((a, b) => b.value - a.value);
});

const selectedRowActivityChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}
	const groupedByActivites = groupQuotaBeatsByActivity(selectedRow.value.original.beats);
	return Object.entries(groupedByActivites)
		.map(([activityId, beats]) => {
			const activity = getActivityFromBeat(activitiesStore.activities, beats[0]);

			return ({
				label: activity?.name ?? activityId,
				value: getTotalTimeInHours(beats),
			});
		})
		.sort((a, b) => b.value - a.value);
});
</script>

<template>
	<div class="pt-6 space-y-10">
		<div class="flex justify-between">
			<PageTitle>
				{{ $t('quotaMonitoring.title') }}
			</PageTitle>
			<div class="flex space-x-4">
				<div
					class="bg-primary-400 rounded-2xl p-4 shadow-widget text-center flex items-center space-x-1"
				>
					<button
						type="button"
						class="px-4 py-2 rounded-lg transition"
						:class="{
							'bg-primary-200': quotaDisplayMode === 'list',
						}"
						@click="quotaDisplayMode = 'list'"
					>
						<ListBulletIcon class="w-6 h-6" />
					</button>
					<button
						type="button"
						class="px-4 py-2 rounded-lg transition"
						:class="{
							'bg-primary-200': quotaDisplayMode === 'grid',
						}"
						@click="quotaDisplayMode = 'grid'"
					>
						<TableCellsIcon class="w-6 h-6" />
					</button>
				</div>
				<TogglePeriod
					v-model:to-date="toDate"
					v-model:from-date="fromDate"
				/>
			</div>
		</div>
		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				{{ $t('quotaMonitoring.overview.title') }}
			</p>
			<div
				v-if="quotaDisplayMode === 'grid'"
				class="grid grid-cols-3 gap-6"
			>
				<div
					v-for="entry in currentPeriodQuotaEntries"
					:key="entry.id"
					class="bg-primary-400 rounded-2xl p-6 shadow-widget"
					:class="{
						'border-2 border-orange-500': hasOverusage(entry),
					}"
				>
					<div class="flex justify-between items-center mb-4">
						<p class="text-lg font-bold">
							{{ entry.name }}
						</p>
						<div>
							<p>
								{{ hoursToDisplayTime(entry.loggedHours) }} / {{ hoursToDisplayTime(entry.monthlyHours) }} {{ $t('common.h') }}
							</p>
						</div>
					</div>
					<QuotaAverage
						class="w-full"
						:quota-entries="entry.monthlyEntries"
					/>
				</div>
			</div>
			<div
				v-if="quotaDisplayMode === 'list'"
				class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center"
			>
				<TableQuotasOverview
					ref="quotasOverviewTable"
					:entries="currentPeriodQuotaEntries"
				/>
			</div>
			<div class="space-y-6">
				<p class="text-2xl font-semibold">
					{{ $t('quotaMonitoring.detailedView.title') }}
				</p>
				<div class="grid grid-cols-12 gap-4">
					<div
						class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-8"
					>
						<TableQuotasDetails
							ref="quotasDetailsTable"
							:quota-entries="currentStatsPeriodQuotasWithBeats"
							class="h-[700px] overflow-auto has-custom-scrollbar"
						/>
					</div>
					<div
						class="flex flex-col space-y-8 bg-primary-400 rounded-2xl p-8 shadow-widget justify-center items-center col-span-4"
					>
						<div class="text-left w-full">
							<p
								v-if="selectedRow"
								class="text-xl"
							>
								{{ selectedParentRow?.getValue('name')
									? selectedParentRow?.getValue('name') + ' - '
										+ selectedRow?.getValue('name')
									: selectedRow?.getValue('name') }}
							</p>
						</div>
						<div
							class="flex-1 flex flex-col justify-center space-y-8 text-center"
						>
							<div>
								<p>{{ $t('diagrams.userComparison') }}</p>
								<ChartPieSingle
									:data="selectedRowUserChartData"
									:show-legend="false"
									class="px-6 desktop:px-12"
								/>
							</div>
							<div>
								<p>{{ $t('diagrams.activityComparison') }}</p>
								<ChartPieSingle
									:data="selectedRowActivityChartData"
									:show-legend="false"
									class="px-6 desktop:px-12"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

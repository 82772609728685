<script setup lang="ts">
import { useToast } from 'vue-toastification';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import AppInput from './AppInput.vue';
import { useActivitiesStore } from '@/store/activities';
import AppButton from '@/components/AppButton.vue';

const emit = defineEmits<{
	(e: 'cancel', value: boolean): void;
}>();

const { t } = useI18n();
const activitiesStore = useActivitiesStore();
const toast = useToast();
const { values, meta } = useForm({
	validationSchema: {
		activityName: yup.string().required('Activity-Name required'),
	},
});

const close = () => {
	emit('cancel', false);
};

const computedMessages = computed(() => ({
	successCreatedActivity: t('notifications.successCreatedActivity'),
}));

const onConfirm = async (activityName: string) => {
	await activitiesStore.createActivity(activityName);
	toast.success(`${computedMessages.value.successCreatedActivity} "${activityName}"`);
	close();
};
</script>

<template>
	<form>
		<div class="flex">
			<div class="inline-block mr-6 text-secondary">
				{{ $t('common.general') }}
			</div>
		</div>
		<div class="text-xl mt-10 mb-3">
			{{ $t('admin.activityName') }}
		</div>
		<AppInput
			v-model="values.activityName"
			class="text-white bg-primary-300 rounded-md w-full px-2 text-2xl border border-primary-900 py-2 mb-12"
			:placeholder="$t('admin.activityName')"
			name="activityName"
		/>
		<div class="flex justify-between mt-12">
			<AppButton
				size="sm"
				:disabled="!meta.valid"
				@click="onConfirm(values.activityName)"
			>
				{{ $t('admin.createActivity') }}
			</AppButton>
			<AppButton
				size="sm"
				color="outline"
				@click="close"
			>
				{{ $t('common.cancel') }}
			</AppButton>
		</div>
	</form>
</template>

<script setup lang="ts">
/**
 * Das ist ein experimentalles Feature für Andy.
 * Das ist noch nicht optimiert.
 */
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@tanstack/vue-query';
import { DateTime } from 'luxon';
import StreamCard from '../components/StreamCard.vue';
import { useAuthentication } from '@/store/auth';
import { createBeatFromSupabase } from '@/models/Beats';
import { supabase } from '@/lib/supabase';

const mainStore = useAuthentication();

const router = useRouter();

const validUserIds = [
	// Thomas
	'6aa970df-cebe-4683-8712-5f601a636fb5',
	// Andy
	'883c3a50-04c0-4a2e-b19e-b39e1a661f1e',
];

const { data: beatStreamData, refetch } = useQuery({
	queryKey: ['beats-stream'],
	queryFn: async () => {
		const last24Hours = DateTime.now().minus({ hours: 24 });

		const { data } = await supabase
			.from('beats')
			.select('*, users(*)')
			.eq('archived', false)
			.order('timestamp', { ascending: false })
			.gt('timestamp', last24Hours);

		return data?.map((d) => ({ beat: createBeatFromSupabase(d), user: d.users })) ?? [];
	},
	refetchOnWindowFocus: true,
});

supabase
	.channel('beats')
// @ts-expect-error: For some reason ts is throwing an error here and want to use "broadcast"
	.on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'beats' }, refetch)
	.subscribe();

onMounted(() => {
	if (!mainStore.user) {
		router.push({ name: 'Dashboard' });
		return;
	}

	if (!(mainStore.user?.id && validUserIds.includes(mainStore.user.id))) {
		router.push({ name: 'Dashboard' });
	}
});

</script>

<template>
	<div>
		<div class="flex space-x-9 mb-6">
			<div class="flex items-center">
				<div class="w-3 phablet:w-12 h-1 bg-secondary rounded-2xl mr-4" />
				<p class="text-xl mobile:text-2xl desktop:text-4xl font-bold">
					{{ $t('streamView.title') }}
				</p>
			</div>
		</div>
		<div class="space-y-4">
			<TransitionGroup
				name="card"
				class="transition-all duration-300 ease-in-out"
			>
				<StreamCard
					v-for="beat in beatStreamData"
					:key="beat.beat.beatId"
					:beat="beat.beat"
					:user="beat.user"
				/>
			</TransitionGroup>
		</div>
	</div>
</template>

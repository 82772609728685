<script setup lang="ts">
import { onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthentication } from '@/store/auth';

const router = useRouter();
const { logout } = useAuthentication();

onBeforeMount(async () => {
	await logout();
	return router.push({ name: 'Signup' });
});
</script>

<template>
	<div />
</template>

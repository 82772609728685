import { Directive, DirectiveBinding } from 'vue';

export type AnimatedNumberDirectiveValue = number;

export type AnimatedNumberDirectiveBindingOptions = { single: boolean };

export type AnimatedNumberDirectiveBinding = AnimatedNumberDirectiveValue
	| ({ value: AnimatedNumberDirectiveValue } & Partial<AnimatedNumberDirectiveBindingOptions>);

const isPrimitiveBinding = (binding: AnimatedNumberDirectiveBinding): binding is AnimatedNumberDirectiveValue => typeof binding === 'string' || typeof binding === 'number';

const getValueFromBidnung = (binding: AnimatedNumberDirectiveBinding) => (isPrimitiveBinding(binding)
	? binding
	: binding.value);

const formatNumber = (value: AnimatedNumberDirectiveValue, binding: DirectiveBinding) => {
	if (Number.isNaN(value) || !Number.isFinite(value)) {
		return 'n/a';
	}

	if (binding.modifiers.toPercentageFixed) {
		return `${value.toFixed(1).replace('.', ',')}%`;
	}

	if (binding.modifiers.toPercentage) {
		return `${value}%`;
	}

	if (typeof binding.arg === 'function') {
		/* eslint-disable @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		return binding.arg(value);
	}

	return value;
};

const animateNumber = (el: HTMLElement, binding: DirectiveBinding<AnimatedNumberDirectiveBinding>, fromValue?: number) => {
	let interval = 0;
	let counter = 0;
	const { oldValue, value: valueBinding } = binding;

	const value = getValueFromBidnung(valueBinding);
	const computedOldValue = fromValue !== undefined ? fromValue : getValueFromBidnung(oldValue ?? 0);
	const transformedOldValue = Number.isFinite(computedOldValue) ? computedOldValue : 0;

	let updatedValue = transformedOldValue;

	if (interval) {
		clearInterval(interval);
	}

	if (transformedOldValue === value || computedOldValue === value) {
		return;
	}

	interval = window.setInterval(() => {
		if (updatedValue !== value && counter < 30) {
			let change = (value - updatedValue) / 10;

			if (binding.modifiers.toPercentage) {
				change = change >= 0 ? Math.ceil(change) : Math.floor(change);
			}

			updatedValue += change;
			el.innerHTML = formatNumber(updatedValue, binding);
			counter++;
		} else {
			el.innerHTML = formatNumber(value, binding);
			clearInterval(interval);
		}
	}, 30);
};

const animatedNumberDirective: Directive<HTMLElement, AnimatedNumberDirectiveBinding> = {
	beforeMount(el, binding) {
		el.innerHTML = formatNumber(getValueFromBidnung(binding.value), binding);

		if (binding.modifiers.skip) {
			return;
		}

		animateNumber(el, binding, 0);
	},
	updated(el, binding) {
		animateNumber(el, binding);
	},
};

export default animatedNumberDirective;

import { MaybeRef, computed, unref } from 'vue';
import { Beat, getTotalTimeInMs, groupBeatsBySatisfaction } from '@/models/Beats';
import { percentageRatio } from '@/utilities/Helpers';

export const useSatisfactionStats = (beats: MaybeRef<Beat[]>) => {
	const _beats = computed(() => unref(beats));

	const beatsGroupedBySatisfaction = computed(() => groupBeatsBySatisfaction(_beats.value));

	const beatsWithHighSatisfaction = computed(() => beatsGroupedBySatisfaction.value['1'] ?? []);

	const beatsWithLowSatisfaction = computed(() => beatsGroupedBySatisfaction.value['0'] ?? []);

	const totalTimeLoggedInMs = computed(() => getTotalTimeInMs(_beats.value));

	const highSatisfactionTotalLoggedTimeInMs = computed(() => getTotalTimeInMs(beatsWithHighSatisfaction.value));

	const lowSatisfactionTotalLoggedTimeInMs = computed(() => getTotalTimeInMs(beatsWithLowSatisfaction.value));

	const highSatisfactionLoggedTimeInPercent = computed(() => percentageRatio(totalTimeLoggedInMs.value, highSatisfactionTotalLoggedTimeInMs.value) || 0);

	const lowSatisfactionLoggedTimeInPercent = computed(() => percentageRatio(totalTimeLoggedInMs.value, lowSatisfactionTotalLoggedTimeInMs.value) || 0);

	return {
		beatsWithHighSatisfaction,
		beatsWithLowSatisfaction,
		highSatisfactionTotalLoggedTimeInMs,
		lowSatisfactionTotalLoggedTimeInMs,
		highSatisfactionLoggedTimeInPercent,
		lowSatisfactionLoggedTimeInPercent,
	};
};

import { createPlausible } from '.';

export const plausible = createPlausible(
	import.meta.env.PROD
		? {
				apiHost: 'https://plausible.sunlab.de',
				domain: 'sunhealth.netlify.app',
			}
		: {},
);

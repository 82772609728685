import { MaybeRef, computed, unref } from 'vue';
import { useInternalBeats } from './useInternalBeats';
import { Beat } from '@/models/Beats';
import { Client } from '@/models/Client';
import { percentageRatio } from '@/utilities/Helpers';

export interface UseUnknownBeatsOptions {
	beats: MaybeRef<Beat[]>;
	internalClients: MaybeRef<Client[]>;
}

export const useUnknownBeats = (options: UseUnknownBeatsOptions) => {
	const _beats = computed(() => unref(options.beats));

	const { internalBeats } = useInternalBeats(options);

	const totalTimeLoggedInMs = computed(() => _beats.value
		.map((beat) => beat.timeIntervalInMs)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0));

	const unknownBeats = computed(() => internalBeats.value.filter((beat) => !beat.projectId && !beat.note) ?? []);

	const unknownBeatsInMs = computed(() => unknownBeats.value
		.map((beat) => beat.timeIntervalInMs)
		.reduce((accumulator, currentValue) => accumulator + currentValue, 0));

	const unknownLoggedDataInPercent = computed(() => {
		const p = percentageRatio(totalTimeLoggedInMs.value, unknownBeatsInMs.value);
		return p || 0;
	});

	return {
		unknownBeats,
		unknownLoggedDataInPercent,
	};
};

<script setup lang="ts">
import { useQuery } from '@tanstack/vue-query';
import { createColumnHelper, getCoreRowModel, useVueTable } from '@tanstack/vue-table';
import { groupBy } from 'lodash';
import { DateTime } from 'luxon';
import { computed, toRef } from 'vue';
import { supabase } from '@/lib/supabase';
import AppTable from '@/components/AppTable.vue';

/**
 * This is an experimental table and has not been
 * fully tested. When we plan to roll this out to
 * more people we should check the functionallity again.
 */

const props = withDefaults(defineProps<{
	fromDate?: DateTime;
	toDate?: DateTime;
}>(), {
	fromDate: () => DateTime.now().minus({ days: 30 }).startOf('day'),
	toDate: () => DateTime.now().startOf('day'),
});

const { data: teamsData } = useQuery({
	queryKey: ['teams'],
	queryFn: async () => {
		const { data } = await supabase
			.from('teams')
			.select('*');

		return data ?? [];
	},
});

const { data: logData } = useQuery({
	queryKey: ['teamLogData', { fromDate: toRef(props, 'fromDate'), toDate: toRef(props, 'toDate') }],
	queryFn: async () => {
		const fromDate = props.fromDate.startOf('day').toISODate();

		if (!fromDate) {
			throw new Error('No valid from Date provided');
		}

		const { data } = await supabase
			.rpc('get_team_logquotes', {
				from_date: fromDate,
				to_date: props.toDate.endOf('day').toISODate() ?? undefined,
			});

		return data;
	},
});

const groupedByTeam = computed(() => groupBy(logData.value, 'team_id'));

const calculated = computed(() => Object.entries(groupedByTeam.value)
	.map(([teamId, users]) => {
		const usersLoggedDataInPercent = users
			.map((user) => user.percent_logged ?? 0);

		const totalLoggedDataInPercent = usersLoggedDataInPercent.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

		const averageLoggedDataInPercent = totalLoggedDataInPercent / users.length;

		const team = teamsData.value?.find((t) => t.team_id === teamId)?.name ?? teamId;

		return {
			team,
			averageLoggedDataInPercent,
		};
	})
	.toSorted((a, b) => b.averageLoggedDataInPercent - a.averageLoggedDataInPercent));

const columnHelper = createColumnHelper<typeof calculated['value'][0]>();

const columns = [
	columnHelper.accessor('team', { header: 'Team' }),
	columnHelper.accessor('averageLoggedDataInPercent', {
		header: 'Quote in %',
		cell(context) {
			return Intl.NumberFormat('de-DE', { style: 'percent' }).format(context.getValue() / 100);
		},
	}),
];

const table = useVueTable({
	columns,
	get data() {
		return calculated.value;
	},
	getCoreRowModel: getCoreRowModel(),
	enableRowSelection: false,
});
</script>

<template>
	<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-8">
		<AppTable
			:table="table"
			class="w-full"
		/>
	</div>
</template>

import { computed, MaybeRef, unref } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { UserModel } from '@/models/User';
import { getAbsencesForYear } from '@/api/absence';
import { useAuthentication } from '@/store/auth';

export interface AbsencesSearchParams {
	year: number;
	user: UserModel | null;
}

export const useAbsencesQuery = (
	searchParams: MaybeRef<AbsencesSearchParams>,
) => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated && unref(searchParams).user !== null);

	const query = useQuery({
		queryKey: ['absences', searchParams],
		queryFn: () => {
			const { user } = unref(searchParams);
			if (!user) {
				return Promise.reject();
			}
			return getAbsencesForYear(user, unref(searchParams).year);
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};

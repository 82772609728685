<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SidepanelNavigation from '@/components/SidepanelNavigation.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { SidepanelNavigationItem } from '@/models/SidepanelNavigationItem';
import { useClientsStore } from '@/store/clients';

const props = defineProps<{
	clientId: string;
}>();

const clientsStore = useClientsStore();
const { t } = useI18n();

const client = computed(() => clientsStore.allClients.find((_client) => _client.id === props.clientId));

const computedMessages = computed(() => ({
	clientGeneral: t('common.general'),
	clientQuotas: t('common.quotas'),
}));

const navigation: SidepanelNavigationItem[] = [
	{
		name: computedMessages.value.clientGeneral,
		to: { name: 'ClientGeneral' },
	},
	{
		name: computedMessages.value.clientQuotas,
		to: { name: 'ClientQuotas' },
	},
];
</script>

<template>
	<SidepanelWrapper>
		<SidepanelNavigation
			class="mb-10"
			:navigation="navigation"
		/>
		<RouterView
			v-if="client"
			:client="client"
		/>
	</SidepanelWrapper>
</template>

<script setup lang="ts">
import { toRef } from 'vue';
import { useFieldLazy } from '@/composables/useFieldLazy';

const props = withDefaults(defineProps<{
	name: string;
	modelValue?: string;
	label: string;
	placeholder: string;
	rows?: number;
}>(), {
	rows: 2,
	modelValue: '',
});

const { value } = useFieldLazy<string>(
	toRef(props, 'name'),
	{ initialValue: props.modelValue },
);
</script>

<template>
	<textarea
		v-model="value"
		class="text-white bg-primary-300 rounded-md w-full px-2 text-base border border-primary-900 py-2 focus:outline-none focus-visible:outline-none focus:border-white"
		:placeholder="placeholder"
		:aria-label="label"
		:name="name"
		:rows="rows"
	/>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { useField, useForm } from 'vee-validate';
import { z } from 'zod';
import { isEqual } from 'lodash';
import { toTypedSchema } from '@vee-validate/zod';
import { usePermission, useWebNotification } from '@vueuse/core';
import { TransitionExpand } from '@morev/vue-transitions';
import { useI18n } from 'vue-i18n';
import AppSwitch from '../AppSwitch.vue';
import SuggestionChipList from '../SuggestionChips/SuggestionChipList.vue';
import AppButton from '../AppButton.vue';
import InputTimePicker from '../TimePicker/InputTimePicker.vue';
import { getSortedTimeStrings } from '@/utilities/Helpers';
import { LogNotificationVoice, LogNotifiction, UserSettings } from '@/models/UserSettings';
import { useUserSettings } from '@/services/UserSettings';
import { TIMESLOT_OPTIONS } from '@/utilities/Constants';
import { SelectOption, getOptionValue } from '@/models/Select';

const { t } = useI18n();
const isLoading = ref(false);

const { settings, updateUserSettings } = useUserSettings();

const isActive = computed(() => settings.value?.logNotificationsEnabled ?? false);

const toast = useToast();

const computedMessages = computed(() => ({
	successActivateLogReminder: t('notifications.successActivateLogReminder'),
	successActivateFirstLogReminder: t('notifications.successActivateFirstLogReminder'),
	successDisabledLogReminder: t('notifications.successDisabledLogReminder'),
	neutral: t('settings.logReminder.neutral'),
	sassy: t('settings.logReminder.sassy'),
	successChangesWereSaved: t('notifications.successChangesWereSaved'),
}));

const enableLogNotifications = async () => {
	if (settings.value?.logNotifications && settings.value.logNotifications.length) {
		await updateUserSettings({ logNotificationsEnabled: true, logNotifications: settings.value.logNotifications });
		toast.success(computedMessages.value.successActivateLogReminder);
	} else {
		await updateUserSettings({ logNotificationsEnabled: true, logNotifications: [{ time: '16:00' }] });
		toast.success(computedMessages.value.successActivateFirstLogReminder);
	}
};

const disableNotifications = async () => {
	await updateUserSettings({ logNotificationsEnabled: false });
	toast.success(computedMessages.value.successDisabledLogReminder);
};

const toggleLogNotifications = async () => {
	if (isLoading.value) return;

	isLoading.value = true;

	if (!isActive.value) {
		await enableLogNotifications();
	} else {
		await disableNotifications();
	}

	isLoading.value = false;
};

const intonationOptions: SelectOption<LogNotificationVoice>[] = [
	{ value: 'neutral', label: computedMessages.value.neutral },
	{ value: 'sassy', label: computedMessages.value.sassy },
];

const selectedIntonation = ref<SelectOption<LogNotificationVoice>>(settings.value?.logNotificationVoice ?? 'neutral');

const timeSlotOptions = TIMESLOT_OPTIONS;

const timeRegex = /(?:[01]\d|2[0-3]):[0-5]\d/;

const validationSchema = toTypedSchema(z.object({
	timeSlot1: z.string()
		.refine((schema) => (schema ? timeRegex.test(schema) : true), 'Keine gültige Uhrzeit ⏰')
		.optional(),
	timeSlot2: z.string()
		.refine((schema) => (schema ? timeRegex.test(schema) : true), 'Keine gültige Uhrzeit ⏰')
		.optional(),
	timeSlot3: z.string()
		.refine((schema) => (schema ? timeRegex.test(schema) : true), 'Keine gültige Uhrzeit ⏰')
		.optional(),
}).refine((schema) => Object.values(schema).some((value) => !!value), {
	message: 'Es muss min. eine Zeit gewählt sein 👀',
	path: ['timeSlot1'],
}));

const {
	meta,
} = useForm<{ timeSlot1: string; timeSlot2?: string; timeSlot3?: string }>({
	validationSchema,
	initialValues: {
		timeSlot1: settings.value?.logNotifications[0]?.time,
		timeSlot2: settings.value?.logNotifications[1]?.time,
		timeSlot3: settings.value?.logNotifications[2]?.time,
	},
});

const timeSlot1 = useField<SelectOption>('timeSlot1');
const timeSlot2 = useField<SelectOption>('timeSlot2');
const timeSlot3 = useField<SelectOption>('timeSlot3');

watch(settings, (newSettings) => {
	if (!newSettings) return;

	selectedIntonation.value = newSettings.logNotificationVoice;
	timeSlot1.setValue(newSettings.logNotifications[0]?.time);
	timeSlot2.setValue(newSettings.logNotifications[1]?.time);
	timeSlot3.setValue(newSettings.logNotifications[2]?.time);
});

const logNotifications = computed<LogNotifiction[]>(() => [...new Set(getSortedTimeStrings([timeSlot1.value.value, timeSlot2.value.value, timeSlot3.value.value]
	.filter((value) => !!value)
	.map(getOptionValue)))]
	.map((value) => ({ time: value })));

const localSettings = computed<Partial<UserSettings>>(() => ({
	...settings.value,
	logNotifications: logNotifications.value,
	logNotificationsEnabled: isActive.value,
	logNotificationVoice: getOptionValue(selectedIntonation.value),
}));

const hasChanges = computed(() => !isEqual(settings.value, localSettings.value));

const updateSettings = async () => {
	await updateUserSettings({
		logNotificationsEnabled: isActive.value,
		logNotificationVoice: getOptionValue(selectedIntonation.value),
		logNotifications: logNotifications.value,
	});
	toast.success(computedMessages.value.successChangesWereSaved);
};

const { permissionGranted, ensurePermissions } = useWebNotification();

const notificationPermissionsState = usePermission('notifications');

watch(isActive, (value) => {
	if (value) ensurePermissions();
}, { immediate: true });
</script>

<template>
	<div
		class="bg-primary-400 rounded-[18px] px-[53px] py-10"
	>
		<div class="flex gap-12 justify-between">
			<div class="space-y-[14px] max-w-[732px]">
				<p class="text-[28px] font-bold">
					{{ $t('settings.logReminder.title') }}
				</p>
				<p class="text-xl opacity-50">
					{{ $t('settings.logReminder.description') }}
				</p>
				<div
					v-if="isActive && !permissionGranted"
					class="font-medium tracking-wider bg-secondary p-4 rounded-2xl text-white"
				>
					<p>
						{{ $t('settings.logReminder.allowNotificationsInBrowser') }}
						<strong
							v-if="notificationPermissionsState === 'denied'"
							class="text-white text-opacity-100 opacity-100"
						>
							{{ $t('settings.logReminder.browserHasBlockedNotifications') }}
						</strong>
						<strong
							v-else
							class="text-white text-opacity-100 opacity-100"
						>
							{{ $t('settings.logReminder.reloadPage') }}
						</strong>
					</p>
					<button
						v-if="notificationPermissionsState !== 'denied'"
						type="button"
						class="inline-block underline"
						@click="ensurePermissions"
					>
						{{ $t('settings.logReminder.requestAccess') }}
					</button>
				</div>
			</div>
			<div class="flex justify-end">
				<AppSwitch
					:class="{
						'cursor-wait': isLoading,
					}"
					:model-value="isActive"
					@update:model-value="toggleLogNotifications"
				>
					<div
						v-if="!isActive"
						class="w-10"
					>
						{{ $t('common.off') }}
					</div>
					<div
						v-else
						class="w-10"
					>
						{{ $t('common.on') }}
					</div>
				</AppSwitch>
			</div>
		</div>
		<TransitionExpand>
			<div
				v-show="isActive"
				class="flex flex-col"
			>
				<div
					class="grid grid-cols-2 gap-8 my-11"
				>
					<div class="bg-primary-500 rounded-[18px] p-[42px] space-y-14">
						<p class="text-xl font-bold">
							{{ $t('settings.logReminder.selectTime') }}
						</p>
						<div class="flex flex-col gap-[10px]">
							<InputTimePicker
								v-model="timeSlot1.value.value"
								:error-message="timeSlot1.errors.value[0]"
								:is-valid="timeSlot1.meta.valid"
								:options="timeSlotOptions"
								required
							/>
							<InputTimePicker
								v-model="timeSlot2.value.value"
								:placeholder="$t('settings.logReminder.selectTimeOptional')"
								:error-message="timeSlot2.errors.value[0]"
								:is-valid="timeSlot2.meta.valid"
								:options="timeSlotOptions"
							/>
							<InputTimePicker
								v-model="timeSlot3.value.value"
								:placeholder="$t('settings.logReminder.selectTimeOptional')"
								:error-message="timeSlot3.errors.value[0]"
								:is-valid="timeSlot3.meta.valid"
								:options="timeSlotOptions"
							/>
						</div>
					</div>
					<div class="bg-primary-500 rounded-[18px] p-[42px] space-y-14">
						<p class="text-xl font-bold">
							{{ $t('settings.logReminder.toneOfVoice') }}
						</p>
						<SuggestionChipList
							v-model="selectedIntonation"
							:options="intonationOptions"
							required
						/>
					</div>
				</div>
				<AppButton
					size="md"
					class="mx-auto"
					:disabled="!meta.valid || !hasChanges"
					@click="updateSettings"
				>
					{{ $t('settings.saveSettings') }}
				</AppButton>
			</div>
		</TransitionExpand>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { onBeforeRouteUpdate } from 'vue-router';
import { useI18n } from 'vue-i18n';
import ClientForm from '@/components/ClientForm.vue';
import { Client, ClientMeta } from '@/models/Client';
import { useClientsStore } from '@/store/clients';
import IconEdit from '@/assets/edit-icon.svg?component';

const props = defineProps<{
	client: Client;
}>();

const { t } = useI18n();
const isEditModeActive = ref(false);

const onCancelEdit = () => {
	isEditModeActive.value = false;
};

const startEditMode = () => {
	isEditModeActive.value = true;
};

const toast = useToast();
const clientsStore = useClientsStore();

onBeforeRouteUpdate(() => {
	isEditModeActive.value = false;
});

const computedMessages = computed(() => ({
	success: t('notifications.successUpdatedClient'),
}));

const onSave = async ({ name, description, isInternal }: ClientMeta) => {
	await clientsStore.updateClient(props.client.id, { name, description, isInternal });
	toast.success(`${computedMessages.value.success} "${name}"`);
	isEditModeActive.value = false;
};
</script>

<template>
	<div>
		<IconEdit
			v-if="!isEditModeActive"
			class="inline-block fill-primary-100 scale-125 hover:cursor-pointer hover:fill-primary-200 absolute top-8 right-8"
			@click="startEditMode"
		/>
		<ClientForm
			:key="client.id"
			:editable="isEditModeActive"
			:client="client"
			@cancel="onCancelEdit"
			@save="onSave"
		/>
	</div>
</template>

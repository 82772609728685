<script setup lang="ts">
import hotkeys from 'hotkeys-js';
import { computed, onMounted, ref, watch } from 'vue';
import { useMainStore } from '../store/main';
import { msToDisplayTime, msToMinutes, msToHourOnly, displayTimeToMs, hourToMs, minutesToMs } from '../shared/times';
import { MAX_BEAT_TIME_IN_MS, MINUTES_STEPS_IN_MS } from '../utilities/Constants';

type Layout = 'base' | 'sm' | 'xs';

const props = withDefaults(defineProps<{
	modelValue?: number;
	name?: string;
	appearance?: Layout;
	unlimited?: boolean;
	disabled?: boolean;
	autofocus?: boolean;
}>(), {
	modelValue: 0,
	name: '',
	appearance: 'base',
	unlimited: false,
	disabled: false,
	autofocus: false,
});
const emit = defineEmits<{
	(e: 'update:modelValue', value: number): void;
	(e: 'focus'): void;
}>();

const mainStore = useMainStore();

const wrapperLayoutMap: Record<Layout, string> = {
	base: 'w-[233px]',
	sm: 'w-[98px] h-[44px]',
	xs: 'w-full h-[38px] border border-primary-900 rounded-md',
};

const wrapperFocusLayoutMap: Record<Layout, string> = {
	base: '',
	sm: '',
	xs: 'border-white',
};

const textWrapperLayoutMap: Record<Layout, string> = {
	base: 'text-[80px] text-right',
	sm: 'text-[36px] -mt-1 text-right',
	xs: 'text-base text-left p-2 -mt-[1px] font-normal',
};

const hourSpanLayoutMap: Record<Layout, string> = {
	base: 'w-[104px], firefoxFocus',
	sm: 'w-[40px], firefoxFocusSmall',
	xs: 'w-[40px], firefoxFocusSmall',
};

const minuteSpanLayoutMap: Record<Layout, string> = {
	base: 'w-[104px], firefoxFocus',
	sm: 'w-[40px], firefoxFocusSmall',
	xs: 'w-[40px], firefoxFocusSmall',
};

const hourInputLayoutMap: Record<Layout, string> = {
	base: 'w-[104px] text-[80px]',
	sm: 'w-[30px] text-[36px]',
	xs: 'w-[30px] text-base',
};

const minuteInputLayoutMap: Record<Layout, string> = {
	base: 'w-[104px] text-[80px]',
	sm: 'w-[30px] text-[36px]',
	xs: 'w-[30px] text-base',
};

const hourInputRef = ref<HTMLInputElement>();
const hourFocus = ref(false);

const minutesInputRef = ref<HTMLInputElement>();
const minutesFocus = ref(false);

const isInputFocused = computed(() => hourFocus.value || minutesFocus.value);

const wrapperClass = computed(() => {
	const wrapperLayoutClass = wrapperLayoutMap[props.appearance];

	if (isInputFocused.value) {
		const wrapperFocusClass = wrapperFocusLayoutMap[props.appearance];

		return [wrapperLayoutClass, wrapperFocusClass];
	}

	return wrapperLayoutClass;
});
const textWrapperClass = computed(() => textWrapperLayoutMap[props.appearance]);
const hourSpanClass = computed(() => hourSpanLayoutMap[props.appearance]);
const minuteSpanClass = computed(() => minuteSpanLayoutMap[props.appearance]);
const hourInputClass = computed(() => hourInputLayoutMap[props.appearance]);
const minuteInputClass = computed(() => minuteInputLayoutMap[props.appearance]);

const modelValueHours = ref(msToHourOnly(props.modelValue));
const maxHourValue = computed(() => {
	if (props.unlimited) {
		return Infinity;
	}
	return msToHourOnly(MAX_BEAT_TIME_IN_MS);
});

const modelValueMinutes = ref(msToMinutes(props.modelValue));
const maxMinuteValue = computed(() => msToMinutes(MINUTES_STEPS_IN_MS * (3600000 / MINUTES_STEPS_IN_MS - 1)));
const minuteSteps = computed(() => msToMinutes(MINUTES_STEPS_IN_MS));

const hourInputFocus = () => {
	if (!hourInputRef.value) {
		return;
	}
	hourInputRef.value.focus();
	hourInputRef.value.select();

	minutesFocus.value = false;
	hourFocus.value = true;
	emit('focus');
};

const hourInputBlur = () => {
	if (!hourInputRef.value) {
		return;
	}
	hourInputRef.value.blur();
	hourFocus.value = false;
};

const minutesInputFocus = () => {
	if (!minutesInputRef.value) {
		return;
	}
	minutesInputRef.value.focus();
	minutesInputRef.value.select();

	hourFocus.value = false;
	minutesFocus.value = true;
	emit('focus');
};

const minutesInputBlur = () => {
	if (!minutesInputRef.value) {
		return;
	}
	minutesInputRef.value.blur();
	minutesFocus.value = false;
};

const isNumber = (event: KeyboardEvent) => {
	const char = String.fromCharCode(event.keyCode);
	if (/^[0-9]$/.test(char)) {
		return true;
	}
	event.preventDefault();
	return false;
};

const newValue = computed(() => {
	if (props.unlimited) {
		return msToDisplayTime(hourToMs(modelValueHours.value) + minutesToMs(modelValueMinutes.value));
	}
	if (modelValueHours.value > 8) {
		minutesInputFocus();
		return msToDisplayTime(hourToMs(maxHourValue.value) + minutesToMs(modelValueMinutes.value));
	}
	if (minutesFocus.value && modelValueMinutes.value.toString().length === 2) {
		minutesInputFocus();
		return msToDisplayTime(hourToMs(modelValueHours.value) + minutesToMs(parseInt(modelValueMinutes.value.toString().slice(0, 2), 10)));
	}
	if (hourFocus.value && modelValueHours.value.toString().length === 1) {
		hourInputFocus();
		return msToDisplayTime(hourToMs(parseInt(modelValueHours.value.toString().slice(0, 1), 10)) + minutesToMs(parseInt(modelValueMinutes.value.toString().slice(0, 2), 10)));
	}
	if (modelValueMinutes.value.toString().length > 2) {
		return msToDisplayTime(hourToMs(modelValueHours.value) + minutesToMs(parseInt(modelValueMinutes.value.toString().slice(0, 2), 10)));
	}
	return msToDisplayTime(hourToMs(modelValueHours.value) + minutesToMs(modelValueMinutes.value));
});

const onModelValueChange = () => {
	modelValueHours.value = msToHourOnly(props.modelValue);
	modelValueMinutes.value = msToMinutes(props.modelValue);

	if (msToDisplayTime(props.modelValue) === '04:15') {
		mainStore.startIconSnow('🥦');
	}
};

watch(() => props.modelValue, onModelValueChange);

const onUpdateValue = (event: KeyboardEvent) => {
	if (minutesFocus.value && (event.key === 'Backspace' || event.key === 'Delete')) {
		modelValueMinutes.value = 0;
	}

	if (hourFocus.value && (event.key === 'Backspace' || event.key === 'Delete')) {
		modelValueHours.value = 0;
	}
	if (!props.unlimited) {
		if (parseInt(event.key, 10) && hourFocus.value) {
			hourInputBlur();
			minutesInputFocus();
		}
	}

	if (displayTimeToMs(newValue.value) % MINUTES_STEPS_IN_MS !== 0 || displayTimeToMs(newValue.value) > MAX_BEAT_TIME_IN_MS) {
		return;
	}

	emit('update:modelValue', displayTimeToMs(newValue.value));
};

const onBlur = () => {
	hourInputBlur();
	minutesInputBlur();

	if (displayTimeToMs(newValue.value) % MINUTES_STEPS_IN_MS !== 0) {
		const modifiedValue = Math.round(displayTimeToMs(newValue.value) / MINUTES_STEPS_IN_MS) * MINUTES_STEPS_IN_MS;
		modelValueHours.value = msToHourOnly(modifiedValue);
		modelValueMinutes.value = msToMinutes(modifiedValue);
	}

	if (modelValueHours.value > maxHourValue.value) {
		modelValueHours.value = maxHourValue.value;
	}
	if (modelValueMinutes.value > maxMinuteValue.value) {
		modelValueMinutes.value = maxMinuteValue.value;
	}

	emit('update:modelValue', displayTimeToMs(newValue.value));
};

const autoFocusInput = () => {
	if (!props.autofocus) {
		return;
	}

	const isTouchDevice = 'ontouchstart' in document.documentElement;

	if (window.innerWidth > 942 || !isTouchDevice) {
		hourInputFocus();
	}
};

onMounted(() => {
	autoFocusInput();

	hotkeys('up, down', (event, handler) => {
		if (hourFocus.value) {
			if (modelValueHours.value === maxHourValue.value && handler.key === 'up') {
				modelValueHours.value = -1;
			}

			if (modelValueHours.value === 0 && handler.key === 'down') {
				modelValueHours.value = maxHourValue.value + 1;
			}
		}

		if (minutesFocus.value) {
			if (modelValueMinutes.value === maxMinuteValue.value && handler.key === 'up') {
				modelValueMinutes.value = -minuteSteps.value;
				if (modelValueHours.value < maxHourValue.value) {
					modelValueHours.value += 1;
				} else {
					modelValueHours.value = 0;
				}
			}
			if (modelValueMinutes.value === 0 && handler.key === 'down') {
				modelValueMinutes.value += maxMinuteValue.value + minuteSteps.value;
				if (modelValueHours.value > 0) {
					modelValueHours.value -= 1;
				} else {
					modelValueHours.value = maxHourValue.value;
				}
			}
		}
	});

	hotkeys('esc', () => {
		hourInputBlur();
		minutesInputBlur();
	});
});
</script>

<template>
	<div
		class="relative flex justify-between items-center mx-auto"
		:class="[wrapperClass, {
			'!opacity-50 !bg-black/40': disabled,
		}]"
		@click="hourInputFocus"
		@keydown.enter="hourInputFocus"
	>
		<div
			class="absolute inset-0 bg-transparent focus:outline-none font-bold tracking-wider cursor-text z-20"
			:class="[textWrapperClass, modelValue === 0 ? 'text-white text-opacity-50' : 'text-white text-opacity-100', disabled ? '!cursor-not-allowed' : '']"
		>
			<span
				:class="[hourSpanClass, hourFocus ? 'bg-primary-200' : 'bg-transparent', disabled ? 'bg-transparent' : '']"
				@click.stop="hourInputFocus"
				@keydown.enter="hourInputFocus"
			>
				{{ newValue.split(':')[0] }}
			</span>
			<span>:</span>
			<span
				:class="[minuteSpanClass, minutesFocus ? 'bg-primary-200' : 'bg-transparent', disabled ? 'bg-transparent' : '']"
				@click.stop="minutesInputFocus"
				@keydown.enter="minutesInputFocus"
			>
				{{ newValue.split(':')[1] }}
			</span>
		</div>
		<input
			ref="hourInputRef"
			v-model="modelValueHours"
			type="number"
			name="hours"
			class="bg-transparent focus:outline-none font-bold tracking-wide text-right cursor-text z-0 pointer-events-none opacity-0"
			:class="hourInputClass"
			:step="1"
			:max="maxHourValue"
			:min="0"
			:disabled="disabled"
			data-allow-hotkeys="true"
			inputmode="decimal"
			aria-label="Hour count"
			@keydown.space.prevent
			@keyup="onUpdateValue($event)"
			@keypress="isNumber($event)"
			@keydown.enter="minutesInputFocus"
			@keyup.right="minutesInputFocus"
			@keyup.left="hourInputFocus"
			@focus="hourInputFocus"
			@blur="onBlur"
		>
		<input
			ref="minutesInputRef"
			v-model="modelValueMinutes"
			type="number"
			name="minutes"
			class="bg-transparent focus:outline-none font-bold tracking-wide cursor-text z-0 pointer-events-none opacity-0"
			:class="minuteInputClass"
			:step="msToMinutes(MINUTES_STEPS_IN_MS)"
			:max="msToMinutes(MINUTES_STEPS_IN_MS) * 3"
			:min="0"
			:disabled="disabled"
			inputmode="decimal"
			data-allow-hotkeys="true"
			aria-label="Minutes count"
			@keydown.space.prevent
			@keypress="isNumber($event)"
			@keyup.left="hourInputFocus"
			@keyup.right="minutesInputFocus"
			@keyup="onUpdateValue($event)"
			@focus="minutesInputFocus"
			@blur="onBlur"
		>
		<div
			v-if="appearance === 'sm'"
			class="absolute bottom-0 w-full h-[2px] bg-white bg-opacity-75 -mt-3"
		/>
	</div>
</template>

<style scoped>
input[type=number]::-webkit-calendar-picker-indicator { background: none; display:none; }

.without_ampm::-webkit-datetime-edit-ampm-field {
    display: none;
}

input[type=number]::-webkit-clear-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance:none;
    appearance: none;
}

input[type=number]:focus {
    background-color: #733BFF;
    color: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

@-moz-document url-prefix() {
  .firefoxFocus {
    padding-top: 10px;
  }
  .firefoxFocusSmall {
    padding-top: 5px;
  }
}
</style>

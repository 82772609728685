<script setup lang="ts">
import { computed } from 'vue';
import SunHealthLogo from '../assets/sunhealth-logo.svg?component';

const buildDate = (new Date(__BUILD_TIMESTAMP__)).toLocaleString();
const environmentName = import.meta.env.MODE;
const gitCommitHash = __COMMIT_REF__;
const githubRepositoryUrl = __REPOSITORY_URL__;

const shortenedGitCommitHash = computed(() => gitCommitHash.substring(0, 6));

</script>

<template>
	<div class="grid justify-center content-center h-screen">
		<SunHealthLogo class="mx-auto" />
		<div class="bg-white p-8 w-140 shadow-md rounded-md mt-8 space-y-2 text-black">
			<p><strong>{{ $t('version.environment') }}</strong> {{ environmentName || '-' }}</p>
			<p><strong>{{ $t('version.buildDate') }}</strong> {{ buildDate || '-' }}</p>
			<p>
				<strong>{{ $t('version.commit') }}</strong>
				<a
					v-if="gitCommitHash && shortenedGitCommitHash"
					class="rounded-full bg-gray-100 border border-solid border-gray-300 px-2 text-xs ml-2"
					:href="`${githubRepositoryUrl}/commit/${gitCommitHash}`"
					target="_blank"
					rel="noopener noreferrer"
				>
					{{ shortenedGitCommitHash }}
				</a>
			</p>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { Client } from '@/models/Client';
import IconEdit from '@/assets/edit-icon.svg?component';
import ProjectForm from '@/components/ProjectForm.vue';
import { Project, ProjectMeta } from '@/models/Project';
import { useProjectsStore } from '@/store/projects';

const props = defineProps<{
	client: Client;
	project: Project;
}>();

const { t } = useI18n();
const isEditModeActive = ref(false);

const onCancelEdit = () => {
	isEditModeActive.value = false;
};

const startEditMode = () => {
	isEditModeActive.value = true;
};

const toast = useToast();
const projectsStore = useProjectsStore();

const computedMessages = computed(() => ({
	success: t('notifications.successUpdatedProject'),
}));

const onSave = async ({ name, description }: ProjectMeta) => {
	await projectsStore.updateProject(props.project.id, { name, description, clientId: props.client.id });
	toast.success(`${computedMessages.value.success} "${name}"`);
	isEditModeActive.value = false;
};
</script>

<template>
	<div>
		<IconEdit
			v-if="!isEditModeActive"
			class="inline-block fill-primary-100 scale-125 hover:cursor-pointer hover:fill-primary-200 absolute top-8 right-8"
			@click="startEditMode"
		/>
		<ProjectForm
			:editable="isEditModeActive"
			:client="client"
			:project="project"
			@cancel="onCancelEdit"
			@save="onSave"
		/>
	</div>
</template>

import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useMainStore } from './main';
import { useTopReasonsQuery } from '@/composables/queries/useTopReasonsQuery';

export const useTopReasonsStore = defineStore('topReasons', () => {
	const mainStore = useMainStore();

	const { data } = useTopReasonsQuery(computed(() => ({
		user: mainStore.user,
	})));

	const reasons = computed(() => data.value ?? []);

	return {
		reasons,
	};
});

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { UserRight } from '@/api/rights';
import { useUserRightsQuery } from '@/composables/queries/useUserRightsQueries';

/**
 * This is just a quick solution. We only use this for checking during Navigation
 * resolution if an item can be displayed based on the current users rights. If
 * we would scale this store to use more user rights we would need to make a query
 * for every single right. That might not be efficient. Therefore we could think
 * about either a supabase function providing us all rights. Or maybe a frontend
 * function for "requesting" rights and storing them in this store. In any case,
 * feel free to refactor this part.
 */

export const useUserRightsStore = defineStore('userRights', () => {
	const requestedUserRights = ref<UserRight[]>([]);

	const query = useUserRightsQuery(requestedUserRights);

	const userRights = computed<Partial<Record<UserRight, boolean>>>(() => query.value.data);

	const hasUserRight = (userRight: UserRight) => {
		if (!requestedUserRights.value.includes(userRight)) {
			requestedUserRights.value.push(userRight);
		}

		return userRights.value[userRight] ?? false;
	};

	const isPending = computed(() => query.value.pending);

	return {
		userRights,
		hasUserRight,
		isPending,
	};
});

import {
	Project, SupabaseProject, createProjectFromSupabase,
} from '../models/Project';
import { supabase } from '@/lib/supabase';

export const getProjects = async (): Promise<Project[]> => {
	const { data } = await supabase
		.from('projects')
		.select('*')
		.order('name');

	return data?.map(createProjectFromSupabase) ?? [];
};

export const updateProjectById = async (projectId: string, properties: Partial<SupabaseProject>) => supabase
	.from('projects')
	.update(properties)
	.eq('project_id', projectId)
	.select()
	.single();

export const archiveProjectById = async (projectId: string) => updateProjectById(projectId, { archived: true });

export const createNewProject = async (clientId: string, name: string, description?: string) => {
	const { data } = await supabase
		.from('projects')
		.insert({
			name,
			client_id: clientId,
			description,
		});

	return data;
};

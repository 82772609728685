import { MaybeRef, unref } from 'vue';
import { NavigationGuard, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import { useEventListener } from '@vueuse/core';
import { ConfirmationModalOptions, useConfirmationModal } from '@/composables/useConfirmationModal';

export interface UseRouteLeaveConfirmationOptions {
	modal: ConfirmationModalOptions;
	showModal: MaybeRef<boolean>;
	showModalOnRouteUpdate?: boolean;
}

export const useRouteLeaveConfirmation = (options: UseRouteLeaveConfirmationOptions) => {
	const confirmationModal = useConfirmationModal(options.modal);

	const navigationGuard: NavigationGuard = async (to, from, next) => {
		if (unref(options.showModal)) {
			const result = await confirmationModal.show();

			if (result.isErr()) {
				return next(false);
			}
		}

		return next();
	};

	if (options.showModalOnRouteUpdate) {
		onBeforeRouteUpdate(navigationGuard);
	}

	onBeforeRouteLeave(navigationGuard);

	useEventListener('beforeunload', (event) => {
		if (!unref(options.showModal)) {
			return;
		}
		event.preventDefault();
		event.returnValue = '';
	});
};

<script setup lang="ts">
import { computed } from 'vue';
import { useLink } from 'vue-router';
import { match } from 'ts-pattern';
import ExternalLink from '../assets/external-link.svg?component';
import AppButton from '@/components/AppButton.vue';
import type { IconType } from '@/models/Icon';
import type { LinkItem, NavigationItemBadge } from '@/models/NavigationItems';
import type { ColorTheme } from '@/models/ColorTheme';

const props = withDefaults(defineProps<{
	label: string;
	link: LinkItem;
	icon?: IconType;
	badge?: NavigationItemBadge;
}>(), {
	icon: 'plus',
	badge: undefined,
});

const { isActive } = useLink({ to: props.link });

const computedLinkItem = computed((): { component: string; bindings: { [p: string]: unknown } } => {
	if (typeof props.link === 'string') {
		return {
			component: 'a',
			bindings: {
				href: props.link,
				target: '_blank',
				ref: 'noopener',
				class: 'focus:outline-none',
				rel: 'noopener noreferrer',
				tabindex: '-1',
			},
		};
	}

	return {
		component: 'router-link',
		bindings: {
			to: props.link,
			tabindex: '-1',
			class: 'focus:outline-none',
		},
	};
});

const themeClass = (colorTheme: ColorTheme) => match(colorTheme)
	.with('success', () => 'bg-success text-green-800')
	.with('warning', () => 'bg-warning text-yellow-800')
	.with('error', () => 'bg-error')
	.with('secondary', () => 'bg-secondary')
	.otherwise(() => 'bg-success text-green-800');
</script>

<template>
	<div v-if="computedLinkItem">
		<component
			:is="computedLinkItem.component"
			v-bind="computedLinkItem.bindings"
			class="cursor-pointer text-gray-600 text-xs uppercase font-bold hover:text-gray-800 transition-colors"
		>
			<AppButton
				width="full"
				tabindex="-1"
				icon-position="left"
				color="transparent"
				size="sm"
				:active="isActive"
				:icon="icon"
				:label="label"
			>
				{{ label }}
				<ExternalLink
					v-if="typeof link === 'string'"
					class="w-4 h-4 opacity-75 ml-2 mb-1"
				/>
				<div
					v-if="badge"
					class="rounded-xl inline-block px-2 py-0.5 text-xs font-bold ml-3 uppercase"
					:class="themeClass(badge.color)"
				>
					{{ badge.text }}
				</div>
			</AppButton>
		</component>
	</div>
</template>

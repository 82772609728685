<script setup lang="ts" generic="T">
import { FlexRender, Row, Table } from '@tanstack/vue-table';
import { ArrowDownIcon } from '@heroicons/vue/20/solid';

const props = withDefaults(defineProps<{
	table: Table<T>;
	headPositionTop?: number;
	deselectable?: boolean;
	clickable?: boolean;
	hasLabel?: boolean;
	loading?: boolean;
}>(), {
	headPositionTop: 0,
	deselectable: false,
	clickable: false,
	hasLabel: true,
	loading: false,
});

const onRowClick = (row: Row<T>) => {
	if (props.clickable) {
		row.toggleSelected(props.deselectable ? undefined : true);
	}

	row.toggleExpanded();
};
</script>

<template>
	<div class="w-full align-middle">
		<div
			class="relative"
			:class="{
				'min-h-[200px]': loading,
			}"
		>
			<div
				v-show="loading"
				class="absolute -inset-0 z-10 bg-opacity-75 flex items-center justify-center"
			>
				{{ $t('appTable.loading') }}
			</div>
			<table class="min-w-full relative">
				<thead v-if="hasLabel">
					<tr
						v-for="headerGroup in table.getHeaderGroups()"
						:key="headerGroup.id"
					>
						<th
							v-for="(
								header, index
							) in headerGroup.headers"
							:key="header.id"
							:colSpan="header.colSpan"
							class="p-4 font-sans font-bold text-sm text-gray-400 border-b-2 border-primary-300 bg-primary-400 z-40"
							:class="[{
								'sm:pl-6': index === 0,
								'cursor-pointer select-none': header.column.getCanSort(),
							}, header.column.columnDef.meta?.align === 'right' ? 'text-right' : 'text-left']"
							:style="{ width: `${header.getSize()}px`, position: 'sticky', top: `${headPositionTop}px` }"
							@click="event => header.column.getToggleSortingHandler()?.(event)"
						>
							<div
								class="flex items-center"
								:class="{
									'justify-end': header.column.columnDef.meta?.align === 'right',
								}"
							>
								<FlexRender
									v-if="!header.isPlaceholder"
									:render="header.column.columnDef.header"
									:props="header.getContext()"
								/>
								<ArrowDownIcon
									v-if="header.column.getCanSort()"
									class="w-5 ml-3 transform"
									:class="{
										'opacity-50': !header.column.getIsSorted(),
										'rotate-180': header.column.getIsSorted() === 'desc',
									}"
								/>
							</div>
						</th>
					</tr>
				</thead>
				<tbody class="divide-y divide-primary-300">
					<template v-if="table.getRowModel().rows.length > 0">
						<tr
							v-for="row in table.getRowModel().rows"
							:key="row.id"
							:class="{
								'cursor-pointer hover:bg-primary-300 group':
									clickable,
							}"
							@click="onRowClick(row)"
						>
							<td
								v-for="(
									cell, columnIndex
								) in row.getVisibleCells()"
								:key="cell.id"
								class="py-4 pl-4 pr-4 text-left"
								:class="{
									'sm:pl-6': columnIndex === 0,
									'bg-primary-300': row.getIsSelected(),
									'text-right': cell.column.columnDef.meta?.align === 'right',
								}"
								:style="{
									width: `${cell.column.getSize()}px`,
								}"
							>
								<FlexRender
									:render="cell.column.columnDef.cell"
									:props="cell.getContext()"
								/>
							</td>
						</tr>
					</template>
					<tr v-else-if="!loading">
						<td
							colspan="100%"
							class="p-4"
						>
							<slot name="noEntries">
								{{ $t('appTable.noEntries') }}
							</slot>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

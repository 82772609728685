<script setup lang="ts">
import {
	createColumnHelper,
	getCoreRowModel, getExpandedRowModel, getSortedRowModel, useVueTable,
} from '@tanstack/vue-table';
import { computed, h, watch } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { groupBy } from 'lodash';
import { useI18n } from 'vue-i18n';
import MeterLinear from './MeterLinear.vue';
import AppTable from './AppTable.vue';
import { hoursToDisplayTime } from '@/shared/times';
import { useProjectsStore } from '@/store/projects';
import { percentageRatio } from '@/utilities/Helpers';
import { QuotaEntry, removeNormalProjectQuotaEntries } from '@/models/QuotaEntry';
import { getTotalTimeInHours } from '@/models/QuotaBeats';

const props = withDefaults(defineProps<{
	quotaEntries?: QuotaEntry[];
}>(), {
	quotaEntries: () => [],
});

const { t } = useI18n();
const projectsStore = useProjectsStore();

const quotaEntriesListedWithProjects = computed<QuotaEntry[]>(() => {
	const { quotaEntries } = props;

	return quotaEntries
		.filter(removeNormalProjectQuotaEntries)
		.map((quotaEntry) => {
			if (quotaEntry.isExtra) {
				return quotaEntry;
			}

			const quotaEntryBeatsGroupedByProject = groupBy(quotaEntry.beats, 'projectId');

			const quotaEntrySubrows: QuotaEntry[] = Object
				.entries(quotaEntryBeatsGroupedByProject)
				.reduce((accumulator, [projectId, projectBeats]) => {
					const project = projectsStore.projects.find((entry) => entry.id === projectId);

					if (quotaEntries.find((entry) => entry.projectId === projectId)?.isExtra) {
						return accumulator;
					}

					if (!project) {
						const noProjectEntry = {
							clientId: 'no-project',
							name: 'Ohne Projekt',
							logCount: projectBeats.length,
							monthlyHours: quotaEntry.monthlyHours,
							loggedHours: getTotalTimeInHours(projectBeats),
							alert: false,
							isExtra: quotaEntry.isExtra,
							isQuota: false,
							beats: projectBeats,
						};
						return [...accumulator, noProjectEntry];
					}

					const projectEntry = {
						clientId: project.id,
						name: project.name,
						logCount: projectBeats.length,
						monthlyHours: quotaEntries.find((entry) => entry.projectId === projectId)?.monthlyHours ?? quotaEntry.monthlyHours,
						loggedHours: getTotalTimeInHours(projectBeats),
						alert: false,
						isExtra: quotaEntry.isExtra,
						isQuota: projectBeats[0].isQuota,
						beats: projectBeats,
					};
					return [...accumulator, projectEntry];
				}, [] as QuotaEntry[])
				.sort((a, b) => b.monthlyHours - a.monthlyHours);

			const quotaWithProjects = {
				...quotaEntry,
				logCount: quotaEntrySubrows.reduce((accumulator, project) => accumulator + project.logCount, 0),
				loggedHours: quotaEntrySubrows.reduce((accumulator, project) => accumulator + project.loggedHours, 0),
				projects: quotaEntrySubrows.every((row) => row.clientId === 'no-project') ? [] : quotaEntrySubrows,
			};

			return quotaWithProjects;
		});
});

const columnHelper = createColumnHelper<QuotaEntry>();

const computedMessages = computed(() => ({
	client: t('common.client'),
	durationInPercent: t('common.durationInPercent'),
	logs: t('common.logs'),
	durationInHours: t('common.durationInHours'),
	project: t('common.project'),
}));

const columns = [
	columnHelper.display({
		id: 'expand',
		size: 40,
		cell: (context) => (context.row.getCanExpand()
			? h(
				'button',
				{
					class: 'flex items-center justify-center',
					onClick: (event: MouseEvent) => {
						event.stopPropagation();
						return context.row.toggleExpanded();
					},
				},
				h(
					ChevronDownIcon,
					{
						class: ['w-5 h-5 transform', { 'rotate-180': context.row.getIsExpanded() }],
					},
				),
			)
			: ''),
	}),
	columnHelper.accessor('name', {
		header: `${computedMessages.value.client} / ${computedMessages.value.project}`,
		size: 150,
		cell: (context) => (context.row.depth === 0
			? h(
				'strong',
				{
					class: 'text-white opacity-100',
				},
				context.getValue(),
			)
			: context.getValue()),
	}),
	columnHelper.accessor('logCount', {
		header: computedMessages.value.logs,
	}),
	columnHelper.accessor('monthlyHours', {
		header: computedMessages.value.durationInHours,
		cell: (context) => h(
			'div',
			{
				class: 'flex space-x-3 items-center',
			},
			[
				h('span', { class: 'w-12' }, hoursToDisplayTime(context.row.getValue('loggedHours'))),
				h(MeterLinear, {
					theme:
						context.row.getValue<number>('loggedHours') > context.getValue<number>()
							? 'secondary'
							: 'success',

					class: ['h-2 w-20'],
					progress: [
						context.row.getValue('loggedHours'),
						context.getValue<number>(),
					],
				}),
				h(
					'span',
					{ class: 'w-12' },
					context.row.depth !== 0 && !context.row.original.isQuota
						? ''
						: hoursToDisplayTime(context.getValue<number>()),
				),
			],
		),
	}),
	columnHelper.accessor('loggedHours', {
		header: computedMessages.value.durationInPercent,
		cell: (context) => {
			const totalDurationInPercent = percentageRatio(context.row.getValue('monthlyHours'), context.getValue());
			return `${totalDurationInPercent}%`;
		},
	}),
];

const table = useVueTable({
	columns,
	initialState: {
		sorting: [{ id: 'name', desc: false }],
	},
	get data() {
		return quotaEntriesListedWithProjects.value;
	},
	enableRowSelection: true,
	enableMultiRowSelection: false,
	enableSubRowSelection: false,
	enableSortingRemoval: false,
	getSubRows: (row) => row?.projects,
	getCoreRowModel: getCoreRowModel(),
	getExpandedRowModel: getExpandedRowModel(),
	getSortedRowModel: getSortedRowModel(),
});

watch(quotaEntriesListedWithProjects, () => {
	if (table.getIsSomeRowsSelected()) {
		return;
	}

	table.setRowSelection({ 0: true });
});

defineExpose({ table });
</script>

<template>
	<AppTable
		:table="table"
		class="w-full"
		clickable
	/>
</template>

import { createI18n } from 'vue-i18n';
import de from './lang/de.json';
import en from './lang/en.json';

const messages = {
	en,
	de,
};

const i18n = createI18n({
	locale: 'de',
	fallbackLocale: 'de',
	messages,
});

export default i18n;

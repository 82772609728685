<script setup lang="ts">
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import ClientForm from '@/components/ClientForm.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { ClientMeta } from '@/models/Client';
import { useClientsStore } from '@/store/clients';

const { t } = useI18n();
const router = useRouter();

const onCancelEdit = () => {
	router.replace({ name: 'Admin' });
};

const toast = useToast();
const clientsStore = useClientsStore();

const computedMessages = computed(() => ({
	success: t('notifications.successCreatedClient'),
}));

const onSave = async ({ name, description, isInternal }: ClientMeta) => {
	await clientsStore.createClient({ name, description, isInternal });
	toast.success(`${computedMessages.value.success} "${name}"`);
	const newClient = clientsStore.allClients.find((client) => client.name === name);

	if (newClient) {
		router.replace({ name: 'Client', params: { clientId: newClient.id } });
	} else {
		router.replace({ name: 'Admin' });
	}
};
</script>

<template>
	<SidepanelWrapper>
		<div class="mb-10">
			<p class="text-2xl font-semibold">
				{{ $t('admin.addClient') }}
			</p>
		</div>
		<ClientForm
			:editable="true"
			@cancel="onCancelEdit"
			@save="onSave"
		/>
	</SidepanelWrapper>
</template>

<script setup lang="ts">
import { useMainStore } from '@/store/main';

const mainStore = useMainStore();
</script>

<template>
	<div
		v-if="mainStore.user"
		class="text-opacity-75"
	>
		<div class="flex items-start font-semibold text-[20px] phablet:text-[24px]">
			<div
				class="w-[20px] phablet:w-12 mt-2.5 phablet:mt-3 bg-primary-100 h-1 rounded-full mr-4"
			/>
			<div>
				{{ $t('dashboard.salutation', { name: mainStore.user.name.split(' ')[0] }) }}
			</div>
		</div>
		<div class="hidden desktop:flex ml-[36px] phablet:ml-16 mt-1 text-sm phablet:text-base">
			{{ $t('dashboard.subtitle') }}
		</div>
	</div>
</template>

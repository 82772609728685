import {
	computed, ComputedRef, Ref, ref, watch,
} from 'vue';
import { errAsync, okAsync, ResultAsync } from 'neverthrow';
import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { useWindowFocus } from '@vueuse/core';
import { appProgressLoader } from '../plugins/AppProgressLoader';
import { useMainStore } from './main';
import { AuthUser, supabaseToAuthUser } from '@/models/AuthUser';
import { supabase } from '@/lib/supabase';
import { checkUserRight } from '@/api/rights';

export type UserLoginCallback = (user: AuthUser) => void;
export type UserLogoutCallback = () => void;

export interface UseAuthentication {
	onUserLogin: (callback: UserLoginCallback) => void;
	onUserLogout: (callback: UserLogoutCallback) => void;
	loginWithGoogle: () => ResultAsync<AuthUser, Error>;
	logout: () => ResultAsync<true, Error>;
	isAuthenticated: ComputedRef<boolean>;
	isAdminUser: Ref<boolean>;
	initAuthentication: () => ResultAsync<AuthUser | null, Error>;
	user: Ref<AuthUser | null | undefined>;
}

export const useAuthentication = defineStore('auth', (): UseAuthentication => {
	const user = ref<AuthUser | null>();
	const isAdminUser = ref(false);
	const hasSubscriptionForAuthStateChanges = ref(false);
	const router = useRouter();

	const isAuthenticated = computed(() => !!user.value);

	/**
         * If Supabase encounters the issue that the refresh token is invalid e.g. because
         * the same database user has been signed out from another session, supabase.js
         * silently clears the session resulting in constant 401 errors which can not be
         * globally catched. Therefore we are trying to check for the local session during
         * every relevant user interaction. (window focus and routing) This will check the
         * local storage for a session. If it does not exist, the auth user in this store
         * gets cleared which will result in an offical logout.
         */

	const setCurrentUserBySession = async () => {
		const { data } = await supabase.auth.getSession();
		user.value = data.session ? supabaseToAuthUser(data.session.user) : null;
	};

	watch(useWindowFocus(), (isFocused) => {
		if (isFocused) {
			setCurrentUserBySession();
		}
	});

	router.beforeEach(async (to, from, next) => {
		if (!user.value) {
			return next();
		}

		await setCurrentUserBySession();

		return next();
	});

	const subscribeToAuthStateChanges = () => {
		if (hasSubscriptionForAuthStateChanges.value) {
			return;
		}

		supabase.auth.onAuthStateChange((_event, session) => {
			user.value = session ? supabaseToAuthUser(session.user) : null;
		});

		hasSubscriptionForAuthStateChanges.value = true;
	};

	/**
     * Can be used at an early loading stage to ensure some
     * kind of authentication state
     */
	const initAuthentication: UseAuthentication['initAuthentication'] = () => ResultAsync.fromSafePromise(
		new Promise((resolve) => {
			supabase.auth.getSession()
				.then(({ data: { session } }) => {
					const userResult = session?.user ? supabaseToAuthUser(session.user) : null;
					user.value = userResult;

					if (userResult) {
						checkUserRight(userResult.id, 'VIEW_ADMIN_PANEL')
							.then((isAdmin) => {
								isAdminUser.value = isAdmin;
							})
							.then(() => resolve(userResult));
					} else {
						resolve(null);
					}
				})
				.catch(() => {
					user.value = null;
					resolve(user.value);
				})
				.finally(subscribeToAuthStateChanges);
		}),
	);

	const onUserLogin = (callback: UserLoginCallback) => watch(user, (newUser, oldUser) => {
		if (newUser && !oldUser) {
			callback(newUser);
		}
	}, { immediate: true });

	const onUserLogout = (callback: UserLogoutCallback) => watch(user, (newUser, oldUser) => {
		if (!newUser && oldUser) {
			callback();
		}
	});

	const logout: UseAuthentication['logout'] = () => {
		if (useMainStore().routerLoadingSegmentId) {
			appProgressLoader.finish(useMainStore().routerLoadingSegmentId);
		}

		return ResultAsync.fromSafePromise(supabase.auth.signOut()).map(() => {
			user.value = null;
			return true;
		});
	};

	const loginWithGoogle = () =>
	/**
     * TODO: Ensure a sign in with popup. This currently is not possible
     * with supabase natively and required a workaround
     * https://github.com/orgs/supabase/discussions/4487#discussioncomment-5279262
     */

		ResultAsync.fromSafePromise(supabase.auth.signInWithOAuth({
			provider: 'google',
			options: {
				redirectTo: window.location.origin,
				queryParams: {
					access_type: 'offline',
					prompt: 'consent',
				},
			},
		}))
			.andThen(() => ResultAsync.fromSafePromise(supabase.auth.getUser())).andThen((response) => {
				if (response.data.user) {
					return okAsync(supabaseToAuthUser(response.data.user));
				}

				return errAsync(new Error());
			});
	return {
		onUserLogin,
		onUserLogout,
		initAuthentication,
		isAuthenticated,
		isAdminUser,
		loginWithGoogle,
		logout,
		user,
	};
});

<script setup lang="ts">
import { ref } from 'vue';
import AppChip from './AppChip.vue';
import { plausible } from '@/plugins/plausible/setup';

const props = withDefaults(defineProps<{
	periodSpans?: number[];
	selectedPeriod?: number;
}>(), {
	periodSpans: () => [],
	selectedPeriod: 6,
});

const emit = defineEmits<{
	(e: 'selectPeriod', months: number): void;
}>();

const activeSpan = ref<number>(props.selectedPeriod);

const setPeriod = (months: number) => {
	plausible.trackEvent('quota-change-daterange', { props: { period: months } });
	activeSpan.value = months;
	emit('selectPeriod', months);
};

</script>

<template>
	<div class="flex w-full pb-10 px-10">
		<div class="p-2 font-bold">
			{{ $t('averagePeriodSelection.averages') }}
		</div>
		<AppChip
			v-for="period in periodSpans"
			:key="period"
			:selected="activeSpan === period"
			class="mx-5"
			@click="setPeriod(period)"
		>
			<span class="text-white">
				{{ $t('averagePeriodSelection.lastMonths', { period: period }) }}
			</span>
		</AppChip>
	</div>
</template>

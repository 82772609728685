<script setup lang="ts">
import { computed, ref } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/solid';
import { useFocus } from '@vueuse/core';

const props = withDefaults(defineProps<{
	placeholder?: string;
	readonly?: boolean;
	required?: boolean;
	isValid?: boolean;
	errorMessage?: string;
}>(), {
	placeholder: '',
	readonly: false,
	required: false,
	isValid: false,
	errorMessage: '',
});

const modelValue = defineModel<string>('modelValue');

const valid = computed(() => !props.errorMessage && props.isValid);
const invalid = computed(() => !valid.value && props.required);

const inputElement = ref();
const { focused } = useFocus(inputElement);

const isPlaceholderVisible = computed(() => !modelValue.value && props.placeholder && !focused.value);

const onDelete = () => {
	modelValue.value = '';
};
</script>

<template>
	<div class="w-full">
		<div class="w-full h-fit relative">
			<p
				v-if="isPlaceholderVisible"
				class="absolute text-xl text-primary-900 left-6 top-1/2 transform -translate-y-1/2 pointer-events-none"
			>
				{{ placeholder }}
			</p>
			<input
				v-bind="$attrs"
				ref="inputElement"
				v-model="modelValue"
				class="border-2 focus:border-primary-100 disabled:opacity-50 placeholder:text-white/25 placeholder:select-none text-white bg-primary-300 rounded-[7px] py-3 px-6 text-xl leading-none outline-none appearance-none w-full transition-all ease-in-out duration-200"
				type="time"
				:class="{
					'border-white/25': modelValue && !invalid,
					'border-red-700': invalid,
					'border-transparent': !modelValue && !invalid,
					'text-opacity-0': isPlaceholderVisible,
				}"
				:disabled="readonly"
				aria-label="Time Input"
			>
			<div class="absolute right-0 top-0 bottom-0 z-50 px-4 box-content flex items-center">
				<button
					v-if="modelValue"
					type="button"
					class="p-1"
					@click="onDelete"
				>
					<XMarkIcon
						class="text-primary-100 w-5 transform transition-all ease-in-out duration-200"
						:class="{
							'opacity-50': readonly,
						}"
					/>
				</button>
				<slot />
			</div>
		</div>
		<div
			v-if="invalid"
			class="text-xs text-red-400 mt-1"
		>
			{{ errorMessage }}
		</div>
	</div>
</template>

<style scoped>
input[type="time"]::-webkit-calendar-picker-indicator{
    display: none;
}
</style>

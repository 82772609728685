import { Ref, computed, ref } from 'vue';

export type ToggleOption<T extends string = string> = T | { label: string; value: T };

export const getValue = <T extends string = string>(option: ToggleOption<T>) => {
	if (typeof option === 'string') {
		return option;
	}

	return option.value;
};

export const getLabel = <T extends string = string>(option: ToggleOption<T>) => {
	if (typeof option === 'string') {
		return option;
	}

	return option.label;
};

export const useOptionToggle = <T extends string = string>(items: ToggleOption<T>[], defaultValue?: T) => {
	const selected = ref(defaultValue ?? getValue(items[0])) as Ref<T>;

	const selectedIndex = computed(() => items.map(getValue).indexOf(selected.value));

	const toggle = () => {
		const newIndex = selectedIndex.value >= items.length - 1 ? 0 : (selectedIndex.value + 1);
		selected.value = getValue(items[newIndex]);
	};

	const selectedLabel = computed(() => getLabel(items[selectedIndex.value]));

	return {
		selected,
		selectedLabel,
		toggle,
	};
};

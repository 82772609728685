<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { ResultAsync } from 'neverthrow';
import isEqual from 'lodash/isEqual';
import { match } from 'ts-pattern';
import * as Sentry from '@sentry/vue';
import { useRouter } from 'vue-router';
import { useClipboard } from '@vueuse/core';
import { useToast } from 'vue-toastification';
import { useI18n } from 'vue-i18n';
import { Beat, getActivityFromBeat, getProjectFromBeat, getClientFromBeat, isBeatValid, BeatMeta } from '../models/Beats';
import TrashIcon from '../assets/trash-icon.svg?component';
import EditIcon from '../assets/edit-icon.svg?component';
import LinkIcon from '../assets/link-icon.svg?component';
import { msToDisplayTime, dateTimeToLocaleTimeFormat } from '../shared/times';
import { useMainStore } from '../store/main';
import { useActivitiesStore } from '../store/activities';
import { useProjectsStore } from '../store/projects';
import { useClientsStore } from '../store/clients';
import { FirstLevelOption, fromClientAndMaybeProject } from '../models/FirstLevelOption';
import { Activity } from '../models/Activity';
import ActivitySelectInput from './ActivitySelectInput.vue';
import FirstLevelSelectInput from './FirstLevelSelectInput.vue';
import TodaysLogCardMenu from './TodaysLogCardMenu.vue';
import BeatInlineNote from './BeatInlineNote.vue';
import TimeTrackInput from './TimeTrackInput.vue';
import SatisfactionEmotes from './SatisfactionEmotes.vue';
import { useUserActivitiesQuery } from '@/composables/queries/useUserActivitesQuery';
import { FeatureFlag, isFeatureFlagEnabled } from '@/models/FeatureFlag';
import { createSharedBeatMetaFromBeatMeta } from '@/models/SharedBeats';
import { createNewSharedBeat } from '@/api/sharedBeats';
import { useBeatsStore } from '@/store/beats';
import { plausible } from '@/plugins/plausible/setup';
import { LogCardMenuItem } from '@/models/LogCard';

const props = withDefaults(defineProps<{
	beat: Beat | BeatMeta;
	isDark?: boolean;
	editable?: boolean;
}>(), {
	isDark: false,
	editable: true,
});

const emit = defineEmits<{
	(e: 'editing:success'): void;
	(e: 'editing:start'): void;
	(e: 'editing:end'): void;
}>();

const { t } = useI18n();
const mainStore = useMainStore();
const beatsStore = useBeatsStore();
const activitiesStore = useActivitiesStore();
const projectsStore = useProjectsStore();
const clientsStore = useClientsStore();

const { data: sortedActivitiesData } = useUserActivitiesQuery();
const sortedActivites = computed(() => sortedActivitiesData.value ?? []);

const activities = computed(() => sortedActivites.value);

const getTimeIntervalInHm = computed(() => msToDisplayTime(props.beat.timeIntervalInMs));
const beatTrackingtime = computed(() => dateTimeToLocaleTimeFormat(props.beat.timestamp));
const onBeatEditing = ref(false);
const hasNote = computed(() => props.beat.note && props.beat.note.length > 0);
const isFeatureAdvancedEditEnabled = ref(isFeatureFlagEnabled(FeatureFlag.ADVANCED_EDIT));

const modifiedBeat = ref<Beat | BeatMeta>({ ...props.beat });

const client = computed(() => getClientFromBeat(clientsStore.allClients, modifiedBeat.value));
const project = computed(() => getProjectFromBeat(projectsStore.allProjects, modifiedBeat.value));

const hasFocusBeatInlineNote = ref<boolean>(false);

const activity = computed<Activity | null>({
	get() {
		return getActivityFromBeat(activitiesStore.activities, modifiedBeat.value);
	},
	set(option) {
		if (!option) {
			return;
		}

		modifiedBeat.value.activityId = option.id;
	},
});

const firstLevelOption = computed<FirstLevelOption | null>({
	get() {
		if (!client.value) {
			return null;
		}
		return fromClientAndMaybeProject(client.value, project.value);
	},
	set(option) {
		if (option?.client) {
			modifiedBeat.value.clientId = option.client.id;
		}

		modifiedBeat.value.projectId = option?.project?.id ?? null;
	},
});

const isCardEditable = computed(() => {
	if (!props.editable) {
		return false;
	}

	if (props.beat.timestamp > mainStore.today.startOf('day').minus({ days: 7 }) || isFeatureAdvancedEditEnabled) {
		return true;
	}

	return false;
});

const closeEdit = () => {
	onBeatEditing.value = false;
	emit('editing:end');
};

const satisfactionValue = computed<number | null>({
	get() {
		return modifiedBeat.value.satisfaction;
	},
	set(value) {
		modifiedBeat.value.satisfaction = value;
	},
});

const router = useRouter();

const removeBeat = () => {
	if (!('beatId' in props.beat)) {
		return Promise.reject();
	}
	match(router.currentRoute.value.name)
		.with('Dashboard', () => plausible.trackEvent('delete-log', { props: { source: 'dashboard' } }))
		.with('DaySingle', () => plausible.trackEvent('delete-log', { props: { source: 'calendar' } }))
		.otherwise(() => {
			plausible.trackEvent('delete-log', { props: { source: 'error' } });

			Sentry.captureMessage('RemoveBeat in undefined route', {
				level: 'warning',
				extra: {
					route: router.currentRoute.value,
				},
			});
		});

	return beatsStore.deleteBeat(props.beat.beatId);
};

const startEdit = () => {
	onBeatEditing.value = true;
	emit('editing:start');
	return Promise.resolve();
};

const toast = useToast();

const computedMessages = computed(() => ({
	successEditLog: t('notifications.successEditLog'),
	errorCopyShareBeatURL: t('notifications.errorCopyShareBeatURL'),
	successCopyShareBeatURL: t('notifications.successCopyShareBeatURL'),
	share: t('common.share'),
	edit: t('common.edit'),
	delete: t('common.delete'),
}));

const saveChanges = () => {
	if (!('beatId' in props.beat)) {
		return Promise.reject();
	}

	if (isEqual(modifiedBeat.value, props.beat)) {
		closeEdit();
		return Promise.resolve();
	}

	if (!isBeatValid(modifiedBeat.value)) {
		return Promise.reject();
	}

	beatsStore.updateBeat(props.beat.beatId, modifiedBeat.value);

	emit('editing:success');
	toast.success(computedMessages.value.successEditLog);

	closeEdit();

	return Promise.resolve();
};

const abortEdit = () => {
	if (!isEqual(modifiedBeat.value, props.beat)) {
		modifiedBeat.value = { ...props.beat };
	}

	closeEdit();

	return Promise.resolve();
};

const { copy } = useClipboard();
const route = useRouter();
const sharedBeatShortcode = ref<string>('');

watch(modifiedBeat.value, () => {
	sharedBeatShortcode.value = '';
});

const copyBeatUrl = async () => {
	if (!sharedBeatShortcode.value) {
		toast.error(computedMessages.value.errorCopyShareBeatURL);
		return;
	}

	const shareLogUrl = `${window.location.origin + route.resolve({ name: 'Dashboard' }).fullPath}?sharedBeatShortCode=${sharedBeatShortcode.value}`;
	await copy(shareLogUrl);
	toast.success(computedMessages.value.successCopyShareBeatURL);
};

const copyBeat = async () => {
	if (!sharedBeatShortcode.value) {
		const sharedBeatMeta = createSharedBeatMetaFromBeatMeta(props.beat);
		const newSharedBeat = await createNewSharedBeat(sharedBeatMeta);
		sharedBeatShortcode.value = newSharedBeat?.shortCode || '';
	}

	await copyBeatUrl();
};

const menuActions: LogCardMenuItem[] = [
	{
		id: 'ShareBeat',
		icon: LinkIcon,
		needsConfirmation: false,
		tippyContent: computedMessages.value.share,
		onSubmit() {
			return ResultAsync.fromSafePromise(copyBeat());
		},
	},
	{
		id: 'EditBeat',
		icon: EditIcon,
		needsConfirmation: true,
		tippyContent: computedMessages.value.edit,
		onSubmit() {
			return ResultAsync.fromSafePromise(saveChanges());
		},
		onRequestConfirmation() {
			return ResultAsync.fromSafePromise(startEdit());
		},
		onAbortConfirmation() {
			return ResultAsync.fromSafePromise(abortEdit());
		},
	},
	{
		id: 'DeleteBeat',
		icon: TrashIcon,
		needsConfirmation: true,
		tippyContent: computedMessages.value.delete,
		onSubmit() {
			return ResultAsync.fromSafePromise(removeBeat());
		},
	},
];

const focusBeatInlineNote = (event: boolean) => {
	hasFocusBeatInlineNote.value = event;
};
</script>

<template>
	<div
		class="group relative border-2 flex flex-col justify-between text-center p-6 z-0 border-primary-300 h-full"
		:class="onBeatEditing ? 'border-none dashed-border rounded-lg' : 'border-solid rounded-2xl'"
	>
		<div
			v-show="isCardEditable"
			class="absolute z-10 top-0 left-1/2 tranform -translate-y-1/2 -translate-x-1/2"
		>
			<TodaysLogCardMenu
				:items="menuActions"
				tabindex="-1"
				:has-focus-beat-inline-note="hasFocusBeatInlineNote"
				:beat="beat"
			/>
		</div>
		<div>
			<FirstLevelSelectInput
				v-if="firstLevelOption"
				v-model="firstLevelOption"
				:client-options="clientsStore.clients"
				:project-options="projectsStore.projects"
				appearance="small"
				:disabled="!onBeatEditing"
			/>
			<ActivitySelectInput
				v-if="activity"
				v-model="activity"
				:options="activities"
				value-prop="id"
				label="name"
				appearance="smallRounded"
				:disabled="!onBeatEditing"
			/>
		</div>
		<div class="mb-7 mt-8">
			<div class="text-sm opacity-50">
				{{ beatTrackingtime }}
			</div>
			<div
				v-if="!onBeatEditing"
				class="text-4xl font-bold opacity-75"
			>
				{{ getTimeIntervalInHm }}
			</div>
			<TimeTrackInput
				v-else
				v-model="modifiedBeat.timeIntervalInMs"
				appearance="sm"
			/>
		</div>
		<div
			class="flex items-center text-white text-opacity-50 text-center mx-auto text-[14px] tracking-wider"
			:class="{ 'space-x-4': hasNote || onBeatEditing }"
		>
			<div class="relative">
				<SatisfactionEmotes
					ref="satisfactionRef"
					v-model="satisfactionValue"
					:is-satisfaction-happy="beat.satisfaction === 1"
					:is-satisfaction-sad="beat.satisfaction === 0"
					:is-editable="isCardEditable && onBeatEditing"
				/>
			</div>
			<div
				v-if="hasNote || onBeatEditing"
				class="relative"
			>
				<BeatInlineNote
					ref="noteRef"
					v-model="modifiedBeat.note"
					:is-editable="onBeatEditing"
					in-card
					@focus-beat-inline-note="focusBeatInlineNote($event)"
				/>
			</div>
		</div>
		<div
			v-show="onBeatEditing"
			class="absolute bottom-[-12px] left-0 right-0"
		>
			<div
				class="w-max px-1 mx-auto text-primary-300 text-[14px]"
				:class="isDark ? 'bg-primary-600' : 'bg-primary-500'"
			>
				{{ $t('beat.editLog') }}
			</div>
		</div>
	</div>
</template>

<style scoped>
@keyframes dash {
    to {
        background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
    }
}

.dashed-border {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #2a2656 50%, transparent 50%),
                linear-gradient(90deg, #2a2656 50%, transparent 50%),
                linear-gradient(0deg, #2a2656 50%, transparent 50%),
                linear-gradient(0deg, #2a2656 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 10px 2px, 10px 2px, 2px 10px, 2px 10px;
    background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
    animation: dash 8s linear infinite;
}
</style>

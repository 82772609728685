<script setup lang="ts">
import { match } from 'ts-pattern';
import { computed, toRef } from 'vue';
import { useProgress } from '@/composables/useProgress';
import type { ColorTheme } from '@/models/ColorTheme';

const props = withDefaults(defineProps<{
	progress?: number | number[];
	theme?: ColorTheme;
}>(), {
	progress: 0,
	theme: 'success',
});

const themeClass = computed(() => match(props.theme)
	.with('success', () => 'bg-success')
	.with('warning', () => 'bg-warning')
	.with('error', () => 'bg-error')
	.with('secondary', () => 'bg-secondary')
	.exhaustive());

const { currentProgressInPercent } = useProgress(toRef(props, 'progress'));
</script>

<template>
	<div
		class="rounded-full bg-opacity-20 overflow-hidden"
		:class="themeClass"
	>
		<div
			class="h-full min-h-1 rounded-full transition-all ease-in-out duration-1000 animation-bar"
			:class="themeClass"
			:style=" { width: currentProgressInPercent } "
		/>
	</div>
</template>

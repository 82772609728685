<script setup lang="ts">
import { computed } from 'vue';
import AppChip from './AppChip.vue';
import { useTopReasonsStore } from '@/store/topReasons';
import { plausible } from '@/plugins/plausible/setup';
import { Project } from '@/models/Project';
import { Client } from '@/models/Client';
import { Activity } from '@/models/Activity';
import { ReasonWithDescription } from '@/models/Reason';

withDefaults(defineProps<{
	project?: Project | null;
	client?: Client | null;
	activity?: Activity | null;
}>(), {
	project: null,
	client: null,
	activity: null,
});
const emit = defineEmits<{
	(e: 'click'): void;
	(e: 'update:project', value: Project | null): void;
	(e: 'update:client', value: Client): void;
	(e: 'update:activity', value: Activity): void;
}>();

const topReasonsStore = useTopReasonsStore();

const topReasons = computed(() => topReasonsStore.reasons);

const onSelectReason = (reason: ReasonWithDescription) => {
	plausible.trackEvent('use-topclientchips');
	emit('click');
	emit('update:activity', reason.activity);
	emit('update:client', reason.client);
	emit('update:project', reason.project ?? null);
};
</script>

<template>
	<div class="flex flex-wrap gap-2">
		<div
			v-for="reason in topReasons"
			:key="`${reason.activityId},${reason.clientId},${reason.projectId ?? null}`"
			class="group relative"
		>
			<AppChip
				class="max-w-fit flex flex-nowrap text-left items-center gap-2"
				@click="onSelectReason(reason)"
			>
				<span class="whitespace-nowrap">{{ reason.client.name }}{{ reason.project ? ',' : '' }}</span>
				<span class="truncate max-w-[120px] desktop:max-w-full">{{ reason.project ? reason.project.name : '' }}</span>
				<span class="bg-primary-400 text-center px-3 py-1 rounded-full -mr-2 whitespace-nowrap">{{ reason.activity.name }}</span>
			</AppChip>
		</div>
	</div>
</template>

import { useQuery } from '@tanstack/vue-query';
import {
	MaybeRef, computed, unref,
} from 'vue';
import { selectQuotasByInfoId } from '@/api/quota';
import { selectQuotaInfoById } from '@/api/quotaInfo';
import { createQuotaFromSupabase } from '@/models/Quota';
import { useAuthentication } from '@/store/auth';

export interface QuotasSearchParams {
	quotaInfoId?: string | null;
}

export const useQuotasQuery = (
	searchParams: MaybeRef<QuotasSearchParams>,
) => {
	const auth = useAuthentication();

	const enabled = computed(() => auth.isAuthenticated && !!unref(searchParams).quotaInfoId);

	const query = useQuery({
		queryKey: [
			'quotas',
			{ quotaInfoId: computed(() => unref(searchParams).quotaInfoId) },
		],
		queryFn: async () => {
			const { quotaInfoId } = unref(searchParams);

			if (!quotaInfoId) {
				return Promise.reject();
			}

			const quotaInfo = await selectQuotaInfoById(quotaInfoId);

			if (!quotaInfo.data) {
				return Promise.reject();
			}

			const quotas = await selectQuotasByInfoId(quotaInfoId);

			if (!quotas.data) {
				return [];
			}

			return quotas.data
				.map((quota) => createQuotaFromSupabase(quota, quotaInfo.data))
				.sort((a, b) => b.from.toMillis() - a.from.toMillis());
		},
		enabled,
		initialData: [],
		refetchOnWindowFocus: false,
	});

	return query;
};

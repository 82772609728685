import { MaybeRef, computed, unref } from 'vue';
import { queryOptions, useQueries } from '@tanstack/vue-query';
import { useAuthentication } from '@/store/auth';
import { UserRight, checkCurrentUserRight } from '@/api/rights';

export const useUserRightsQuery = (rights: MaybeRef<UserRight[]>) => {
	const authStore = useAuthentication();

	const enabled = computed(() => authStore.isAuthenticated);

	const queries = computed(() => unref(rights).map((right) => (queryOptions({
		queryKey: ['userRights', authStore.user?.id, right],
		queryFn: async () => {
			const hasRight = await checkCurrentUserRight(unref(right));
			return {
				key: right,
				value: hasRight,
			};
		},
		enabled,
	}))));

	const query = useQueries({
		queries,
		combine: (results) => ({
			data: results
				.filter((result) => !!result.data)
				.map((result) => ({ [result.data.key as UserRight]: result.data.value }))
				.reduce((result, object) => ({ ...object, ...result }), {}),
			pending: results.some((result) => result.isPending),
		}),
	});

	return query;
};

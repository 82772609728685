import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import { archiveBeat, createNewBeat, updateBeatById } from '@/api/beats';
import { BeatMeta, toSupabaseData } from '@/models/Beats';
import { UserModel } from '@/models/User';

export const useBeatsMutation = () => {
	const queryClient = useQueryClient();

	const createBeat = useMutation({
		async mutationFn(
			variables: {
				beat: BeatMeta;
				user: UserModel;
			},
		) {
			try {
				const response = await createNewBeat(variables.beat);

				if (!response) {
					return err(new Error('Could not create beat'));
				}

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['beats'] }),
				queryClient.invalidateQueries({ queryKey: ['topReasons'] }),
				queryClient.invalidateQueries({ queryKey: ['loggedData'] }),
			]);
		},
	});

	const deleteBeat = useMutation({
		async mutationFn(
			variables: {
				beatId: string;
			},
		) {
			try {
				const response = await archiveBeat(variables.beatId);

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['beats'] }),
				queryClient.invalidateQueries({ queryKey: ['topReasons'] }),
				queryClient.invalidateQueries({ queryKey: ['loggedData'] }),
			]);
		},
	});

	const updateBeat = useMutation({
		async mutationFn(
			variables: {
				beatId: string;
				beat: BeatMeta;
			},
		) {
			try {
				const response = await updateBeatById(variables.beatId, toSupabaseData(variables.beat));

				return ok(response);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['beats'] }),
				queryClient.invalidateQueries({ queryKey: ['topReasons'] }),
				queryClient.invalidateQueries({ queryKey: ['loggedData'] }),
			]);
		},
	});

	return {
		createBeat,
		deleteBeat,
		updateBeat,
	};
};

import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useAbsencesQuery } from '@/composables/queries/useAbsencesQuery';
import { useMainStore } from '@/store/main';
import { useToday } from '@/composables/useToday';

export const useAbsencesStore = defineStore('absences', () => {
	const minYear = ref(useToday().value.year);

	const mainStore = useMainStore();

	const { data } = useAbsencesQuery(computed(() => ({
		user: mainStore.user,
		year: minYear.value,
	})));

	const absences = computed(() => data.value ?? []);

	const requestAbsencesForYear = (year: number) => {
		if (year < minYear.value) {
			minYear.value = year;
		}
	};

	return {
		absences,
		minYear,
		requestAbsencesForYear,
	};
});

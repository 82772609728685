<script setup lang="ts">
import {
	computed, ref, toRef, watch,
} from 'vue';
import { DateTime } from 'luxon';
import { useAppProgressLoader } from '@sunlabde/vuelab';
import { useRouteQuery } from '@vueuse/router';
import { search } from 'fast-fuzzy';
import { useI18n } from 'vue-i18n';
import SatisfactionSad from '../assets/satisfaction-sad.svg?component';
import SatisfactionHappy from '../assets/satisfaction-happy.svg?component';
import PageTitle from '@/components/PageTitle.vue';
import BoxedStatItem from '@/components/BoxedStatItem.vue';
import { minutesToDisplayTime, msToDisplayTime } from '@/shared/times';
import { getLoggedDataByType, getLoggedDataInPercent } from '@/models/LoggedData';
import LoggedTimeStatsChart from '@/components/LoggedTimeStatsChart.vue';
import ChartPieSingle from '@/components/ChartPieSingle.vue';
import { useLoggedTimeStatsChart } from '@/composables/useLoggedTimeStatsChart';
import { useUnknownBeats } from '@/composables/useUnknownBeats';
import { useSatisfactionStats } from '@/composables/useSatisfactionStats';
import { useInternalLogStats } from '@/composables/useInternalLogStats';
import { PieChartDataItem } from '@/models/PieChartDataItem';
import {
	getTotalTimeInMs,
	groupBeatsByActivity,
	getActivityFromBeat,
	groupBeatsByTopicResolved,
} from '@/models/Beats';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useActivitiesStore } from '@/store/activities';
import AppButton from '@/components/AppButton.vue';
import { useBeatsQuery } from '@/composables/queries/useBeatsQuery';
import { useUsersQuery } from '@/composables/queries/useUsersQuery';
import { useMainStore } from '@/store/main';
import DateRangePicker from '@/components/DateRangePicker.vue';
import AppInput from '@/components/AppInput.vue';
import { useFieldSearch } from '@/composables/useFieldSearch';
import TableBeats from '@/components/TableBeats.vue';
import TableTopic from '@/components/TableTopic.vue';
import TableGroupedBeats from '@/components/TableGroupedBeats.vue';
import { BeatTableEntry } from '@/models/BeatTableEntry';
import AppDropdownSelect from '@/components/AppDropdownSelect.vue';
import { UserData } from '@/models/User';

const { t } = useI18n();
const mainStore = useMainStore();

const fromDate = ref(DateTime.now().minus({ days: 30 }).startOf('day'));
const toDate = ref(DateTime.now().startOf('day'));

const userId = useRouteQuery('userId');

const { data: users } = useUsersQuery();

const canSelectUsers = computed(() => users.value.length > 1);

const selectedUserId = computed(() => {
	if (userId.value) {
		return Array.isArray(userId.value) ? userId.value[0] : userId.value;
	}

	return mainStore.user?.id ?? null;
});

const selectedUser = computed({
	get() {
		return users.value.find((user) => user.userId === userId.value);
	},
	set(user) {
		userId.value = user?.userId ?? null;
	},
});

const getFilteredUsers = (query: string, options: UserData[]) => {
	if (!query) return options;

	return search(
		query,
		options,
		{
			keySelector: (option) => option.user,
			threshold: 0.7,
		},
	);
};

const {
	data,
	loggedData,
	isFetching: isFetchingLoggedTimeStats,
} = useLoggedTimeStatsChart({
	fromDate,
	toDate,
	userId: selectedUserId,
});

const personalLoggedData = computed(() => getLoggedDataByType(loggedData.value ?? [], 'user'));

const personalLoggedDataInPercent = computed(() => getLoggedDataInPercent(personalLoggedData.value));

const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const { data: beatsData, isFetching: isFetchingBeats } = useBeatsQuery({
	userId: selectedUserId,
	fromDate,
	toDate,
});

const isFetching = computed(() => isFetchingLoggedTimeStats.value || isFetchingBeats.value);

const appPogressLoader = useAppProgressLoader();

watch(isFetching, (_isLoading) => {
	if (_isLoading) {
		appPogressLoader.start();
	} else {
		appPogressLoader.finishAll();
	}
});

const currentStatsPeriodBeats = computed(() => beatsData.value ?? []);

const {
	internalLoggedTimeInPercent,
	externalLoggedTimeInPercent,
} = useInternalLogStats({
	beats: currentStatsPeriodBeats,
	internalClients: toRef(clientsStore, 'internalClients'),
});

const { unknownLoggedDataInPercent } = useUnknownBeats({
	beats: currentStatsPeriodBeats,
	internalClients: toRef(clientsStore, 'internalClients'),
});

const computedMessages = computed(() => ({
	internal: t('common.internal'),
	external: t('common.external'),
}));

const internalLogStatsData = computed<PieChartDataItem[]>(() => [
	{
		color: '#ff874b',
		label: computedMessages.value.internal,
		value: internalLoggedTimeInPercent.value,
	},
	{
		color: '#733bff',
		label: computedMessages.value.external,
		value: externalLoggedTimeInPercent.value,
	},
]);

const groupedBeatsTable = ref<InstanceType<typeof TableGroupedBeats>>();

const selectedRows = computed(() => groupedBeatsTable.value?.table.getSelectedRowModel());

const selectedRow = computed(() => selectedRows.value?.flatRows[0]);

const selectedParentRow = computed(() => selectedRow.value?.getParentRow() ?? null);

const selectedRowChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}

	const projects = selectedRow.value.original.subRows;

	if (!projects) {
		return [];
	}

	return projects.map<PieChartDataItem>((project) => ({
		label: project.name,
		value: project.totalDurationInMs,
	}));
});

const selectedRowActivityChartData = computed<PieChartDataItem[]>(() => {
	if (!selectedRow.value) {
		return [];
	}

	const groupedByActivites = groupBeatsByActivity(selectedRow.value.original.beats);

	return Object.entries(groupedByActivites)
		.map(([activityId, beats]) => {
			const activity = getActivityFromBeat(activitiesStore.activities, beats[0]);

			return ({
				label: activity?.name ?? activityId,
				value: getTotalTimeInMs(beats),
			});
		})
		.sort((a, b) => b.value - a.value);
});

const {
	value: logSearch, fields, unmatchedInput,
} = useFieldSearch({ debounce: 500 });

const beatsTable = ref<InstanceType<typeof TableBeats>>();

watch(fields, (newFields) => {
	beatsTable.value?.table.setColumnFilters(newFields);
});

watch(unmatchedInput, (input) => {
	beatsTable.value?.table.setGlobalFilter(input);
});

const filteredBeatsTableRows = computed(() => beatsTable.value?.table.getRowModel().rows ?? []);

const filteredBeatsTableRowsTotalTime = computed(() => msToDisplayTime(filteredBeatsTableRows.value.reduce((a, b) => a + b.original.timeIntervalInMs, 0)));

const {
	beatsWithHighSatisfaction,
	beatsWithLowSatisfaction,
	highSatisfactionLoggedTimeInPercent,
	lowSatisfactionLoggedTimeInPercent,
} = useSatisfactionStats(currentStatsPeriodBeats);

const highSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithHighSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});

const lowSatisfactionTableEntries = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByTopicResolved({
		beats: beatsWithLowSatisfaction.value,
		activities: activitiesStore.activities,
		clients: clientsStore.clients,
		projects: projectsStore.projects,
	});

	return Object.entries(group).map<BeatTableEntry>(([topic, beats]) => ({
		beats,
		id: topic,
		logCount: beats.length,
		name: topic,
		totalDurationInMs: getTotalTimeInMs(beats),
	}));
});

const showSatisfactionLogs = ref(false);
</script>

<template>
	<div class="pt-6 space-y-10">
		<PageTitle>
			{{ $t('personalReport.head.title') }} <span v-if="canSelectUsers && selectedUser">({{ selectedUser?.user }})</span>
		</PageTitle>
		<div class="flex items-center justify-between">
			<div class="flex space-x-4">
				<BoxedStatItem
					:label="$t('personalReport.head.yourLogQuota')"
					class="w-48 shrink-0"
					suffix="%"
					:value="personalLoggedDataInPercent"
				/>
				<BoxedStatItem
					:label="$t('personalReport.head.enteredTime')"
					class="w-48 shrink-0"
					suffix="h"
					:value="personalLoggedData.totalLoggedInMinutes"
					:formatter="minutesToDisplayTime"
				/>
				<BoxedStatItem
					:label="$t('personalReport.head.totalLogs')"
					class="w-48 shrink-0"
					:value="currentStatsPeriodBeats.length"
				/>
				<BoxedStatItem
					class="w-48 shrink-0"
					suffix="%"
					:value="unknownLoggedDataInPercent"
				>
					<div class="flex items-center space-x-2">
						<p>{{ $t('personalReport.head.unknownLogs') }}</p>
						<div
							v-tippy="{ content: $t('personalReport.head.unknownLogsInfo'), theme: 'sunhealth-mt' }"
							class="w-5 h-5 rounded-full bg-primary-800 flex items-center justify-center text-xs font-bold cursor-help"
						>
							?
						</div>
					</div>
				</BoxedStatItem>
			</div>
			<div class="flex space-x-4">
				<DateRangePicker
					v-model:from="fromDate"
					v-model:to="toDate"
				/>
				<div
					v-if="canSelectUsers"
					class="flex items-center space-x-6 bg-primary-400 rounded-2xl px-6 pt-2 pb-4 shadow-widget text-center"
				>
					<AppDropdownSelect
						v-model="selectedUser"
						:options="users"
						label-prop="user"
						value-prop="userId"
						class="w-60 !text-[16px]"
						:placeholder="$t('personalReport.head.searchPlaceholder')"
						:search-function="getFilteredUsers"
					>
						<template #valueAttributes="{ value }">
							<div class="absolute left-0 top-2.5 pointer-events-none">
								{{ value.user }}
							</div>
						</template>
						<template #optionAttributes="{ attributes }">
							{{ attributes.user }}
						</template>
					</AppDropdownSelect>
				</div>
			</div>
		</div>

		<div>
			<p class="text-2xl font-semibold mb-6">
				{{ $t('personalReport.quotaComparison') }}
			</p>
			<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center">
				<LoggedTimeStatsChart
					:data="data"
					class="px-6 desktop:px-12 w-full"
				/>
				<ChartPieSingle
					:data="internalLogStatsData"
					class="px-6 desktop:px-12 w-full"
				/>
			</div>
		</div>
		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				{{ $t('personalReport.dataOverview.title') }}
			</p>
			<div class="grid grid-cols-12 gap-4">
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-8">
					<TableGroupedBeats
						ref="groupedBeatsTable"
						:beats="currentStatsPeriodBeats"
						class="h-[700px] overflow-auto has-custom-scrollbar"
					/>
				</div>
				<div class="flex flex-col space-y-8 bg-primary-400 rounded-2xl p-8 shadow-widget justify-center items-center col-span-4">
					<div class="text-left w-full">
						<p class="text-xl">
							{{ selectedRow?.original.name }}
						</p>
						<p
							v-if="selectedParentRow"
							class="text-white/50"
						>
							{{ selectedParentRow.original.name }}
						</p>
					</div>
					<div class="flex-1 flex flex-col justify-center space-y-8 text-center">
						<div v-if="selectedRowChartData.length > 0">
							<p>{{ $t('diagrams.projectComparison') }}</p>
							<ChartPieSingle
								:data="selectedRowChartData"
								:show-legend="false"
								class="px-6 desktop:px-12"
							/>
						</div>
						<div>
							<p>{{ $t('diagrams.activityComparison') }}</p>
							<ChartPieSingle
								:data="selectedRowActivityChartData"
								:show-legend="false"
								class="px-6 desktop:px-12"
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="flex items-center justify-between bg-primary-400 rounded-2xl p-10 shadow-widget text-center">
				<p class="text-2xl font-semibold">
					{{ $t('personalReport.funFactor.title') }}
				</p>
				<div class="flex space-x-16">
					<div class="flex space-x-6 items-center">
						<SatisfactionHappy
							class="w-16 opacity-50"
						/>
						<span class="text-2xl">
							{{ highSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
					<div class="flex space-x-6 items-center">
						<SatisfactionSad
							class="w-16 opacity-50"
						/>
						<span class="text-2xl">
							{{ lowSatisfactionLoggedTimeInPercent }}%
						</span>
					</div>
				</div>
				<div>
					<AppButton
						color="gray"
						size="sm"
						class="min-w-32"
						@click="showSatisfactionLogs = !showSatisfactionLogs"
					>
						<span v-if="showSatisfactionLogs">
							{{ $t('personalReport.funFactor.hideLogs') }}
						</span>
						<span v-else>
							{{ $t('personalReport.funFactor.showLogs') }}
						</span>
					</AppButton>
				</div>
			</div>
			<div
				v-show="showSatisfactionLogs"
				class="grid grid-cols-12 gap-4"
			>
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="highSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
				<div class="flex bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-6">
					<TableTopic
						:data="lowSatisfactionTableEntries"
						class="w-full max-h-[400px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>
		<div class="space-y-6">
			<p class="text-2xl font-semibold">
				{{ $t('personalReport.logHistory.title') }}
			</p>
			<div class="grid grid-cols-12 gap-4">
				<div class="bg-primary-400 rounded-2xl p-6 shadow-widget text-center col-span-full">
					<div class="border-b-2 border-primary-300 pb-4 flex justify-between items-center">
						<div class="text-sm opacity-95 pl-4">
							<span class="font-bold">{{ $t('common.results') }} </span>
							<span>{{ filteredBeatsTableRows.length }} {{ $t('common.logs') }}</span>,
							<span class="font-bold">{{ $t('common.totalDuration') }} </span>
							<span>{{ filteredBeatsTableRowsTotalTime }}{{ $t('common.h') }}</span>
						</div>
						<div class="w-96 ml-auto">
							<AppInput
								v-model="logSearch"
								name="logSearch"
								:placeholder="$t('personalReport.logHistory.searchBeats')"
								class="text-sm"
							/>
						</div>
					</div>
					<TableBeats
						ref="beatsTable"
						:beats="currentStatsPeriodBeats"
						class="max-h-[700px] overflow-auto has-custom-scrollbar"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import IconSuccess from '@/assets/icon-success.svg?component';

const props = withDefaults(defineProps<{
	modelValue?: boolean;
}>(), {
	modelValue: false,
});

const emit = defineEmits<{
	(e: 'update:modelValue', beat: boolean): void;
}>();

const isChecked = computed(() => props.modelValue);

const onClick = () => {
	emit('update:modelValue', !props.modelValue);
};
</script>

<template>
	<div>
		<div class="flex items-center group">
			<button
				id="checkbox"
				class="flex items-center justify-center w-6 h-6 rounded border border-solid flex-shrink-0 cursor-pointer"
				:class="[
					isChecked ? 'border-white' : 'border-primary-900 group-hover:border-white',
				]"
				@click.prevent="onClick"
				@keydown.prevent="onClick"
			>
				<IconSuccess
					class="checkbox-mark text-white"
					:class="[
						isChecked ? 'opacity-100' : 'opacity-0',
					]"
				/>
			</button>
			<button
				class="flex group-hover:text-white cursor-pointer pl-2"
				:class="[
					isChecked ? 'text-white' : 'text-primary-900',
				]"
				@click.prevent="onClick"
				@keydown.prevent="onClick"
			>
				<slot />
			</button>
		</div>
	</div>
</template>

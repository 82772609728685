import { defineStore } from 'pinia';
import { computed } from 'vue';
import { isClientActive, ClientMeta, isClientInternal } from '../models/Client';
import { useClientsQuery } from '@/composables/queries/useClientsQuery';
import { useClientsMutation } from '@/composables/queries/useClientsMutation';

export const useClientsStore = defineStore('clients', () => {
	const { data, isFetching } = useClientsQuery();

	const allClients = computed(() => data.value ?? []);

	const clients = computed(() => allClients.value.filter(isClientActive) ?? []);

	const internalClients = computed(() => allClients.value.filter(isClientInternal) ?? []);

	const {
		createClient: createClientMutation,
		archiveClient: archiveClientMutation,
		restoreClient: restoreClientMutation,
		updateClient: updateClientMutation,
	} = useClientsMutation();

	const createClient = (meta: ClientMeta) => createClientMutation.mutateAsync({ client: meta });

	const archiveClient = (clientId: string) => archiveClientMutation.mutateAsync({ clientId });

	const restoreClient = (clientId: string) => restoreClientMutation.mutateAsync({ clientId });

	const updateClient = (clientId: string, client: ClientMeta) => updateClientMutation.mutateAsync({ clientId, client });

	return {
		clients,
		allClients,
		internalClients,
		isFetching,
		createClient,
		archiveClient,
		restoreClient,
		updateClient,
	};
});

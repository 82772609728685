<script setup lang="ts">
import {
	computed, onMounted, onUnmounted, reactive, ref, watch,
} from 'vue';
import interact from 'interactjs';
import { msToDisplayTime } from '../shared/times';
import {
	arrayOfCoordinateY, arrayOfMinutes, coordinatesToMs, msToCoordinates,
} from '../models/Coordinates';
import { MAX_BEAT_TIME_IN_MS } from '../utilities/Constants';
import { useMainStore } from '../store/main';

const props = withDefaults(defineProps<{
	// TODO: max timeinput, pin size, steps, color
	sliderValue?: number;
	// TODO: dynamic testen
	pinSize?: number;
}>(), {
	sliderValue: 0,
	pinSize: 20,
});

const emit = defineEmits<{
	(e: 'update:sliderValue', value: number): void;
}>();

const coordinates = reactive({
	x: 0,
	y: 0,
});

const mainStore = useMainStore();

const element = ref<HTMLElement>();
const parentElement = ref<HTMLElement>();

const mainWidth = ref(0);
const mainHeight = ref(0);

const isMouseMoving = ref(false);
const mouseX = ref(0);
const mouseY = ref(0);

// HOURS and COORDINATE X
const maxHours = computed(() => parseInt(msToDisplayTime(MAX_BEAT_TIME_IN_MS).split(':')[0], 10));

const arrayOfHours = computed(() => {
	const arr = [];

	for (let i = 0; i < (maxHours.value + 1); i++) {
		arr.push(i);
	}

	return arr;
});

// GRAPH MAX-WIDTH
const setMainWidth = () => {
	if (!parentElement.value) {
		return;
	}

	mainWidth.value = Math.round(parentElement.value.clientWidth);
};
window.addEventListener('resize', setMainWidth);

watch(parentElement, () => setMainWidth);

const graphStepX = computed(() => Math.round((mainWidth.value - props.pinSize) / maxHours.value));
const maxElementWidth = computed(() => Math.round(mainWidth.value / maxHours.value) * maxHours.value);

// GRAPH MAX-HEIGHT
const setMainHeight = () => {
	if (!parentElement.value) {
		return;
	}

	mainHeight.value = Math.round(parentElement.value.clientHeight);
};
window.addEventListener('resize', setMainHeight);

watch(parentElement, () => setMainHeight);

const graphStepY = computed(() => Math.round((mainHeight.value - props.pinSize) / (arrayOfMinutes.value.length - 1)));
const maxElementHeight = computed(() => Math.round(mainHeight.value / (arrayOfMinutes.value.length - 1)) * (arrayOfMinutes.value.length - 1));

// GRAPH-ELEMENTS TRANFORMATION
const elementPseudoTransform = computed(() => {
	if (isMouseMoving.value) {
		return `translate(${mouseX.value}px, ${mouseY.value}px)`;
	}

	return `translate(${coordinates.x}px, ${coordinates.y}px)`;
});

const yAxisPseudoTransformX = computed(() => {
	if (isMouseMoving.value) {
		return `translateX(${mouseX.value}px)`;
	}
	return `translateX(${coordinates.x}px)`;
});

const yAxisHighlightTransformX = computed(() => `translate(${coordinates.x}px, 0px)`);

const yAxisHighlightHeight = computed(() => {
	const arrayOfCoordinate = arrayOfCoordinateY(graphStepY.value);
	const arrayIndex = arrayOfCoordinate.reverse().indexOf(coordinates.y);

	return arrayOfCoordinate.reverse()[arrayIndex];
});

// MOUSE INTERACTION
const newValue = ref(0);

const initInteract = (parent: HTMLElement) => {
	interact(parent)
		.on('tap', (event) => {
			let ofX = coordinates.x;
			let ofY = coordinates.y;

			ofX = Math.round(event.offsetX / graphStepX.value) * graphStepX.value;
			ofY = Math.round(event.offsetY / graphStepY.value) * graphStepY.value;

			coordinates.x = ofX;
			coordinates.y = ofY;

			newValue.value = coordinatesToMs(coordinates, graphStepX.value, graphStepY.value);
			emit('update:sliderValue', newValue.value);
		})
		.on('move', (event) => {
			mouseX.value = Math.round(event.offsetX / graphStepX.value) * graphStepX.value;
			mouseY.value = Math.round(event.offsetY / graphStepY.value) * graphStepY.value;
			isMouseMoving.value = true;
			mainStore.mouseOverSlider = true;
		});
};

const onMouseLeave = () => {
	isMouseMoving.value = false;
	mainStore.mouseOverSlider = false;
};

const updateSliderValue = () => {
	coordinates.x = msToCoordinates(props.sliderValue, graphStepX.value, graphStepY.value).x;
	coordinates.y = msToCoordinates(props.sliderValue, graphStepX.value, graphStepY.value).y;
};

watch(() => props.sliderValue, updateSliderValue);

onMounted(() => {
	if (parentElement.value) {
		initInteract(parentElement.value);
	}

	setMainWidth();
	setMainHeight();
	updateSliderValue();
});

onUnmounted(() => {
	window.removeEventListener('resize', setMainWidth);
	window.removeEventListener('resize', setMainHeight);
});

</script>

<template>
	<div
		ref="parentElement"
		class="relative text-white text-[14px] tracking-wider text-opacity-50 z-50 cursor-pointer w-full h-full"
		@mouseleave="onMouseLeave"
		@focusout="onMouseLeave"
	>
		<div
			id="restrict"
			class="relative w-full min-h-56"
			:style="`max-width: ${maxElementWidth}px; max-height: ${maxElementHeight}px`"
		>
			<div
				class="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center z-[-1]"
				:class="sliderValue === 0 && !isMouseMoving ? '' : 'hidden'"
			>
				<div class="mb-[38px]">
					{{ $t('dashboard.graph') }}
				</div>
			</div>
			<div
				class="absolute transition-all pointer-events-none flex flex-col justify-between"
				:style="`transform: translateX(${coordinates.x}px); height: calc(100% - ${pinSize - 2}px); left: -8px; top: ${(pinSize / 2) - 2}px`"
			>
				<div
					v-for="minute in arrayOfMinutes"
					:key="minute"
					class="relative ml-2 last:opacity-0 top-0"
				>
					<div
						class="transition-all pointer-events-none w-4 h-[1px] z-0 bg-primary-300"
					/>
					<div
						class="absolute left-[34px]"
						:style="`top: -${pinSize / 2}px`"
					>
						{{ minute }}
					</div>
				</div>
			</div>
			<div
				class="absolute transition-all pointer-events-none w-[4px] z-0 bg-primary-300"
				:style="`transform: translateX(${coordinates.x}px); top: ${(pinSize / 2) - 2}px; height: calc(100% - ${pinSize - 2}px); left: ${(pinSize / 2) - 2}px`"
			/>
			<div
				class="absolute transition-all pointer-events-none flex flex-col justify-between"
				:style="`transform: ${yAxisPseudoTransformX}; height: calc(100% - ${pinSize - 2}px); left: -8px; top: ${(pinSize / 2) - 2}px`"
				:class="isMouseMoving ? 'opacity-100' : 'opacity-0'"
			>
				<div
					v-for="minute in arrayOfMinutes"
					:key="minute"
					class="relative ml-2 last:opacity-0"
				>
					<div
						class="transition-all pointer-events-none w-4 h-[1px] z-0 bg-primary-300"
					/>
					<div
						class="absolute left-[34px]"
						:style="`top: -${pinSize / 2}px`"
					>
						{{ minute }}
					</div>
				</div>
			</div>
			<div
				class="absolute transition-all pointer-events-none w-[4px] z-0 bg-primary-300"
				:style="`transform: ${yAxisPseudoTransformX}; top: ${(pinSize / 2) - 2}px; height: calc(100% - ${pinSize - 2}px); left: ${(pinSize / 2) - 2}px`"
			/>
			<div
				class="absolute pointer-events-none w-[4px] bg-secondary transition-all ease-in-out z-0"
				:style="`height: ${yAxisHighlightHeight}px; transform: ${yAxisHighlightTransformX}; bottom: ${(pinSize / 2) - 2}px; left: ${(pinSize / 2) - 2}px`"
			/>
			<div
				class="absolute pointer-events-none h-[4px] z-[-1] bg-primary-300"
				:style="`left: ${pinSize / 2}px; width: calc(100% - ${pinSize - 3}px); bottom: ${(pinSize / 2) - 2}px`"
			/>
			<div
				class="absolute pointer-events-none flex justify-between z-[-1]"
				:style="`left: ${pinSize / 2}px; width: calc(100% - ${pinSize - 3}px); bottom: ${(pinSize / 2) - 2}px`"
			>
				<div
					v-for="hour in arrayOfHours"
					:key="hour"
					class="relative font-bold"
				>
					<div
						class="transition-all pointer-events-none h-4 w-[1px] z-0 bg-primary-300"
					/>
					<div class="absolute top-7 left-[-3px]">
						{{ hour }}
					</div>
				</div>
			</div>
			<div
				class="absolute pointer-events-none h-[4px] z-0 bg-secondary transition-all ease-in-out"
				:style="`width: ${msToCoordinates(sliderValue, graphStepX, graphStepY).x}px; left: ${pinSize / 2}px; bottom: ${(pinSize / 2) - 2}px`"
			/>
			<div
				ref="element"
				:style="`transform: translate(${coordinates.x}px, ${coordinates.y}px); width: ${pinSize}px; height: ${pinSize}px;`"
				class="transition-all pointer-events-none rounded-full bg-opacity-25 flex justify-center items-center touch-none z-50"
				:class="sliderValue <= 0 ? 'bg-gray' : 'bg-secondary'"
			>
				<div
					class="absolute rounded-full"
					:class="sliderValue <= 0 ? 'bg-gray' : 'bg-secondary'"
					:style="`width: ${pinSize / 2}px; height: ${pinSize / 2}px`"
				/>
			</div>
			<div
				:style="`transform: ${elementPseudoTransform}; width: ${pinSize}px; height: ${pinSize}px; margin-top: -${pinSize}px`"
				class="transition-all pointer-events-none rounded-full bg-gray bg-opacity-25 justify-center items-center touch-none z-50"
				:class="isMouseMoving ? 'flex' : 'hidden'"
			>
				<div
					class="absolute bg-gray rounded-full"
					:style="`width: ${pinSize / 2}px; height: ${pinSize / 2}px`"
				/>
			</div>
		</div>
	</div>
</template>

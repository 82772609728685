import { DateTime } from 'luxon';
import { isWeekendDayInDateTime } from '@/utilities/Helpers';
import { UserWorkSchedule } from '@/models/UserWorkSchedule';

export interface Absence {
	userId: string;
	absenceId: string;
	date: string;
}

export interface IsIsoDateInAbsenceOptions {
	isoDate: string;
	absences: Absence[];
	userId?: string;
	userWorkSchedule?: UserWorkSchedule[];
}

export const isIsoDateInAbsences = (options: IsIsoDateInAbsenceOptions): boolean => options.absences.some((absence) => {
	const date = DateTime.fromISO(options.isoDate);
	const currentWorkSchedule = options.userWorkSchedule?.find((workSchedule) => workSchedule.isoDow === date.weekday);

	if (options.userId) {
		if (currentWorkSchedule?.workingHours === 0 && !isWeekendDayInDateTime(date.weekday)) {
			return true;
		}

		return absence.date === options.isoDate && absence.userId === options.userId;
	}
	return absence.date === options.isoDate;
});

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import SidepanelNavigation from '@/components/SidepanelNavigation.vue';
import SidepanelWrapper from '@/components/SidepanelWrapper.vue';
import { SidepanelNavigationItem } from '@/models/SidepanelNavigationItem';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';

const props = defineProps<{
	projectId: string;
}>();

const projectsStore = useProjectsStore();
const { t } = useI18n();

const project = computed(() => projectsStore.allProjects.find((_project) => _project.id === props.projectId));

const clientsStore = useClientsStore();

const client = computed(() => clientsStore.allClients.find((_client) => _client.id === project.value?.clientId));

const computedMessages = computed(() => ({
	general: t('common.general'),
	quotas: t('common.quotas'),
}));

const navigation: SidepanelNavigationItem[] = [
	{
		name: computedMessages.value.general,
		to: { name: 'ProjectGeneral' },
	},
	{
		name: computedMessages.value.quotas,
		to: { name: 'ProjectQuotas' },
	},
];
</script>

<template>
	<SidepanelWrapper>
		<SidepanelNavigation
			class="mb-10"
			:navigation="navigation"
		/>
		<RouterView
			v-if="project && client"
			:project="project"
			:client="client"
		/>
	</SidepanelWrapper>
</template>

import { computed, MaybeRef, unref } from 'vue';
import { keepPreviousData, useQuery } from '@tanstack/vue-query';
import { UserModel } from '@/models/User';
import { useAuthentication } from '@/store/auth';
import { supabase } from '@/lib/supabase';

export interface QuotaListsSearchParams {
	user: UserModel | null;
}

export const useQuotaListsQuery = (
	searchParams: MaybeRef<QuotaListsSearchParams>,
) => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated && unref(searchParams).user !== null);

	const query = useQuery({
		queryKey: ['quotaListId', searchParams],
		queryFn: async () => {
			const { user } = unref(searchParams);

			if (!user) {
				return Promise.reject();
			}
			const { data } = await supabase
				.from('users_access_in_quota_list')
				.select('*')
				.eq('user_id', user.id);
			return data;
		},
		enabled,
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: true,
	});

	return query;
};

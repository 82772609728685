<script setup lang="ts">
const props = withDefaults(defineProps<{
	modelValue: boolean;
	readonly?: boolean;
	positionReverse?: boolean;
	label?: string;
}>(), {
	readonly: false,
	positionReverse: false,
	label: '',
});

const emit = defineEmits<{
	(e: 'update:modelValue', value: boolean): void;
}>();

const onClick = () => {
	if (props.readonly) {
		return;
	}
	emit('update:modelValue', !props.modelValue);
};
</script>

<template>
	<button
		class="flex gap-5 items-center w-fit disabled:opacity-50 cursor-pointer disabled:cursor-default outline-none"
		:class="{ 'flex-row-reverse': positionReverse }"
		:disabled="readonly"
		:tabindex="-1"
		type="button"
		@click="onClick"
	>
		<button
			class="group transition w-18 h-10 rounded-full flex items-center justify-center pointer-events-none focus:outline focus:outline-gray-500 focus:outline-1 outline-offset-2"
			:class="{
				'bg-white/10': !modelValue,
				'bg-primary-100': modelValue,
			}"
			:disabled="readonly"
			type="button"
			:aria-disabled="readonly"
			:aria-checked="modelValue"
			role="switch"
		>
			<div
				class="w-7 h-7 rounded-full transform transition bg-white"
				:class="{
					'-translate-x-[15px]': !modelValue,
					'translate-x-[15px]': modelValue,
				}"
			/>
		</button>
		<div
			class="text-xl font-bold text-current"
			:class="[{ 'cursor-pointer': !readonly }]"
		>
			<slot>
				{{ label }}
			</slot>
		</div>
	</button>
</template>

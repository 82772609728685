<script setup lang="ts">
import { computed, toRef } from 'vue';
import { match } from 'ts-pattern';
import { getTopClients } from '../models/Client';
import { getTopProjects } from '../models/Project';
import LoggedTimeStatsChart from './LoggedTimeStatsChart.vue';
import TopTopicStats from './TopTopicStats.vue';
import StatItemGroup from './StatItemGroup.vue';
import StatItem from './StatItem.vue';
import { useBeatsStore } from '@/store/beats';
import { useLoggedTimeStatsChart } from '@/composables/useLoggedTimeStatsChart';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { useActivitiesStore } from '@/store/activities';
import { useInternalLogStats } from '@/composables/useInternalLogStats';
import { getTotalTimeInMs } from '@/models/Beats';
import { msToDisplayTime } from '@/shared/times';
import { getTopActivities } from '@/models/Activity';
import { useTimePeriodToggle } from '@/composables/useTimePeriodToggle';
import { EntryWithTime } from '@/models/EntryWithTime';

const beatsStore = useBeatsStore();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();
const activitiesStore = useActivitiesStore();

const {
	selected: selectedStatsPeriod,
	toggle: toggleTimePeriod,
	selectedLabel: currentTimePeriodName,
	selectedStartDate,
} = useTimePeriodToggle({
	initialPeriod: 'day',
});

const {
	data,
	selectedTopic,
	currentTopicName,
	toggleTopic,
} = useLoggedTimeStatsChart({
	fromDate: selectedStartDate,
});

const currentStatsPeriodBeats = computed(() => match(selectedStatsPeriod.value)
	.with('day', () => beatsStore.beatsFromToday)
	.with('week', () => beatsStore.beatsFromWeek)
	.with('month', () => beatsStore.beatsFromMonth)
	.with('year', () => beatsStore.beatsFromYear)
	.otherwise(() => []));

const currentTopicEntries = computed<EntryWithTime[] | undefined>(() => match(selectedTopic.value)
	.with('client', () => getTopClients(currentStatsPeriodBeats.value, clientsStore.allClients, 5))
	.with('project', () => getTopProjects(currentStatsPeriodBeats.value, projectsStore.allProjects, 5))
	.with('activity', () => getTopActivities(currentStatsPeriodBeats.value, activitiesStore.activities, 5))
	.exhaustive());

const {
	internalLoggedTimeInPercent,
	externalLoggedTimeInPercent,
} = useInternalLogStats({
	beats: currentStatsPeriodBeats,
	internalClients: toRef(clientsStore, 'internalClients'),
});
</script>

<template>
	<div>
		<div class="flex items-center space-x-2 mb-5 px-6">
			<button
				type="button"
				class="font-bold text-white text-opacity-75 cursor-pointer group w-fit"
				@click="toggleTimePeriod"
			>
				{{ $t('dashboard.loggedTimeStats.title') }}
				<strong
					class="group-hover:underline transition-all ease-in-out"
				>
					{{ currentTimePeriodName }}
				</strong>
			</button>
			<div
				v-tippy="$t('diagrams.dashboardToolTip')"
				class="bg-green-400 rounded-xl inline-block px-2 py-0.5 text-xs text-green-800 font-bold"
			>
				{{ $t('common.new') }}
			</div>
		</div>
		<LoggedTimeStatsChart
			:data="data"
			class="px-6 desktop:px-12 w-full mb-6"
		/>
		<div class="w-full h-px bg-primary-300" />
		<StatItemGroup class="px-6">
			<StatItem
				:label="$t('common.total')"
				:value="getTotalTimeInMs(currentStatsPeriodBeats)"
				:formatter="msToDisplayTime"
				suffix="h"
				highlight
			/>
			<StatItem
				:value="internalLoggedTimeInPercent"
				suffix="%"
				:options="{
					single: true,
				}"
			>
				<div class="flex items-center space-x-2">
					<p>{{ $t('common.internal') }}</p>
					<div
						v-tippy="{ content: $t('dashboard.internalInfo'), theme: 'sunhealth-mt' }"
						class="w-5 h-5 rounded-full bg-primary-800 flex items-center justify-center text-xs font-bold cursor-help"
					>
						?
					</div>
				</div>
			</StatItem>
			<StatItem
				:value="externalLoggedTimeInPercent"
				suffix="%"
				:options="{
					single: true,
				}"
			>
				<div class="flex items-center space-x-2">
					<p>{{ $t('common.external') }}</p>
					<div
						v-tippy="{ content: $t('dashboard.externalInfo'), theme: 'sunhealth-mt' }"
						class="w-5 h-5 rounded-full bg-primary-800 flex items-center justify-center text-xs font-bold cursor-help"
					>
						?
					</div>
				</div>
			</StatItem>
		</StatItemGroup>
		<div class="w-full h-px bg-primary-300" />
		<TopTopicStats
			class="px-6 desktop:px-12 pt-6"
			:beats="currentStatsPeriodBeats"
			:topic-entries="currentTopicEntries"
			:topic-name="currentTopicName"
			@change-topic="toggleTopic"
		/>
	</div>
</template>

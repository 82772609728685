<script setup lang="ts">
import { computed, ref } from 'vue';
import { search } from 'fast-fuzzy';
import { StarIcon as StarIconOutline } from '@heroicons/vue/24/outline';
import { StarIcon } from '@heroicons/vue/24/solid';
import { Project } from '../models/Project';
import { Client } from '../models/Client';
import { FirstLevelOption } from '../models/FirstLevelOption';
import AppDropdownSelect, { DropdownAppearance } from './AppDropdownSelect.vue';
import { FavoriteTopics } from '@/models/FavoriteTopics';

const props = withDefaults(defineProps<{
	clientOptions: Client[];
	projectOptions: Project[];
	appearance?: DropdownAppearance;
	favoriteTopics?: FavoriteTopics | null;
	canDeselect?: boolean;
	canFavorite?: boolean;

}>(), {
	appearance: 'regular',
	favoriteTopics: null,
	canDeselect: false,
	canFavorite: false,
});

const emits = defineEmits<{
	(e: 'toggleFavoriteTopic', option: FirstLevelOption): void;
}>();

const selectRef = ref();

const focus = () => {
	selectRef.value.focus();
};

const toggleFavoriteTopic = (firstLevelOption: FirstLevelOption) => {
	emits('toggleFavoriteTopic', firstLevelOption);
	focus();
};

const mappedClients = computed<FirstLevelOption[]>(() => props.clientOptions.map<FirstLevelOption>((option) => ({
	value: option.id,
	label: option.name,
	client: option,
	project: null,
})));

const mappedProject = computed<FirstLevelOption[]>(() => props.projectOptions.reduce((mappedOptions: FirstLevelOption[], project) => {
	const client = mappedClients.value.find((o) => o.client.id === project.clientId)?.client;

	if (!client) {
		return mappedOptions;
	}

	const newOption: FirstLevelOption = {
		value: `${client.id},${project.id}`,
		label: `${project.name}, ${client.name}`,
		client,
		project,
	};

	return [
		...mappedOptions,
		newOption,
	];
}, []));

const isOptionFavorited = (option: FirstLevelOption) => {
	if (option.project) {
		return !!props.favoriteTopics?.projects.find((project) => project === option.project?.id);
	}

	return !!props.favoriteTopics?.clients.find((client) => client === option.client.id);
};

const sortedOptions = computed<FirstLevelOption[]>(() => mappedClients.value
	.reduce((options: FirstLevelOption[], client) => {
		const clientProjects = mappedProject.value.filter((project) => project.client.id === client.client.id);

		if (clientProjects.length > 0) {
			return [
				...options,
				client,
				...clientProjects,
			];
		}

		return [...options, client];
	}, [])
	.toSorted((a, b) => Number(isOptionFavorited(b)) - Number(isOptionFavorited(a))));

const getFilteredOptions = (query: string, options: FirstLevelOption[]) => {
	if (!query) return sortedOptions.value;

	return search(
		query,
		options,
		{
			keySelector: (option) => (option.project ? `${option.client.name} ${option.project.name}` : option.client.name),
			threshold: 0.7,
		},
	);
};

defineExpose({
	focus,
});
</script>

<template>
	<AppDropdownSelect
		ref="selectRef"
		v-bind="$attrs"
		:options="sortedOptions"
		:appearance="appearance"
		:can-deselect="canDeselect"
		:search-function="getFilteredOptions"
	>
		<template #optionAttributes="{ attributes, isPointed }">
			<div :class="{ 'flex items-center justify-between w-full overflow-hidden': appearance === 'regular' }">
				<div v-if="attributes.client">
					<template v-if="attributes.project">
						<p class="text-sm opacity-60">
							{{ attributes.client.name }}
						</p>
						<p>{{ attributes.project.name }}</p>
					</template>
					<p v-else>
						{{ attributes.client.name }}
					</p>
				</div>
				<Transition
					enter-from-class="opacity-0 translate-x-2"
					enter-active-class="transition transform duration-200"
				>
					<button
						v-if="canFavorite"
						v-show="isPointed || isOptionFavorited(attributes)"
						type="button"
						class="text-white/50 group"
						tabindex="-1"
						@click.stop="toggleFavoriteTopic(attributes)"
					>
						<StarIcon
							v-if="isOptionFavorited(attributes)"
							class="w-5 text-green-400 group-hover:text-green-600"
						/>
						<StarIconOutline
							v-else
							class="w-5 group-hover:text-green-400"
						/>
					</button>
				</Transition>
			</div>
		</template>
		<template #valueAttributes="{ value }">
			<div
				v-if="value.client"
				:class="{
					'text-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug w-full flex flex-col-reverse justify-center': appearance === 'small',
					'text-center flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug max-w-[90%]': appearance === 'regular',
				}"
			>
				<template v-if="value.project">
					<p class="truncate">
						{{ value.project.name }}
					</p>
					<p
						:class="{
							'text-xs opacity-60': appearance === 'small',
							'text-sm opacity-60 ml-2': appearance === 'regular',
						}"
						class="truncate"
					>
						{{ value.client.name }}
					</p>
				</template>
				<p v-else>
					{{ value.client.name }}
				</p>
			</div>
		</template>
	</AppDropdownSelect>
</template>

<script setup lang="ts">
import hotkeys from 'hotkeys-js';
import { onMounted, ref } from 'vue';
import { match } from 'ts-pattern';
import SatisfactionSad from '../assets/satisfaction-sad.svg?component';
import SatisfactionHappy from '../assets/satisfaction-happy.svg?component';
import { plausible } from '@/plugins/plausible/setup';

const props = withDefaults(defineProps<{
	isFormValid?: boolean;
	modelValue?: number | null;
	isEditable?: boolean;
}>(), {
	isFormValid: false,
	modelValue: null,
	isEditable: true,
});
const emit = defineEmits<{
	(e: 'update:modelValue', value: number | null): void;
	(e: 'click', value: Event): void;
}>();

const hasFocus = ref(false);
const buttonRef = ref<HTMLButtonElement>();

const setSatisfaction = (satisfactionValue: number) => {
	if (!props.isEditable) {
		return;
	}

	match(satisfactionValue)
		.with(0, () => plausible.trackEvent('use-satisfaction', { props: { score: 'low' } }))
		.with(1, () => plausible.trackEvent('use-satisfaction', { props: { score: 'high' } }))
		.otherwise(() => plausible.trackEvent('use-satisfaction', { props: { score: 'error' } }));

	if (satisfactionValue === props.modelValue) {
		emit('update:modelValue', null);
		return;
	}
	emit('update:modelValue', satisfactionValue);
};

const focus = () => {
	if (!props.isEditable) {
		return;
	}

	buttonRef.value?.focus();
	hasFocus.value = true;
};

const onBlur = () => {
	hasFocus.value = false;
};

const onClick = ($event: Event) => {
	if (!props.isEditable) {
		return;
	}

	emit('click', $event);
};

onMounted(() => {
	hotkeys('left', () => {
		if (hasFocus.value) {
			setSatisfaction(0);
		}
	});

	hotkeys('right', () => {
		if (hasFocus.value) {
			setSatisfaction(1);
		}
	});
});

defineExpose({
	focus,
});
</script>

<template>
	<button
		ref="buttonRef"
		class="min-w-20 duration-300 ease-in-out h-[40px] rounded-full bg-primary-300 text-base leading-none font-medium text-white max-w-max flex items-center justify-center py-2 px-3 ring-offset-primary-400 ring-offset-2 ring-primary-300 outline-none"
		:class="{
			'focus:ring-1': hasFocus, 'opacity-30': !isEditable && modelValue === null, 'cursor-default': !isEditable, 'cursor-pointer': isEditable,
		}"
		v-bind="$attrs"
		type="button"
		@focus="focus"
		@blur="onBlur"
		@click.stop.prevent="onClick"
	>
		<div class="relative flex items-center justify-between w-full">
			<div
				class="group-scope transition-all ease-in-out"
			>
				<SatisfactionSad
					v-tippy="{ content: $t('beat.funFactor.low'), theme: 'sunhealth-mt' }"
					class="focus:outline-none w-6"
					tabindex="-1"
					:class="{ 'opacity-90': modelValue === 0, 'opacity-50': modelValue !== 0, 'hover:opacity-90 cursor-pointer': isEditable }"
					@click.stop.prevent="setSatisfaction(0)"
				/>
			</div>
			<div
				class="group-scope transition-all ease-in-out"
			>
				<SatisfactionHappy
					v-tippy="{ content: $t('beat.funFactor.high'), theme: 'sunhealth-mt' }"
					tabindex="-1"
					class="focus:outline-none w-6"
					:class="{ 'opacity-90': modelValue === 1, 'opacity-50': modelValue !== 1, 'hover:opacity-90 cursor-pointer': isEditable }"
					@click.stop.prevent="setSatisfaction(1)"
				/>
			</div>
		</div>
	</button>
</template>

import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { err, ok } from 'neverthrow';
import { UserSettings } from '@/models/UserSettings';
import {
	updateActivityOrderForUser, updateNotificationSettingsForUser, updateSettingsForUser,
} from '@/api/user';
import { Activity } from '@/models/Activity';

export const useUserSettingsMutation = () => {
	const queryClient = useQueryClient();

	const updateAllUserSettings = useMutation({
		async mutationFn(
			variables: {
				userId: string;
				settings: UserSettings;
			},
		) {
			try {
				queryClient.setQueryData(['userSettings', variables.userId], variables.settings);

				const updateResponse = await updateSettingsForUser(variables.userId, variables.settings);
				await updateNotificationSettingsForUser(variables.userId, variables.settings);

				return ok(updateResponse.data);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userSettings'] }),
				queryClient.invalidateQueries({ queryKey: ['userActivities'] }),
				queryClient.invalidateQueries({ queryKey: ['userActivitiesPreview'] }),
			]);
		},
	});

	const updateUserActivityRanking = useMutation({
		async mutationFn(
			variables: {
				userId: string;
				activities: Activity[];
			},
		) {
			try {
				queryClient.setQueryData(['userActivities', variables.userId], variables.activities);
				queryClient.setQueryData(['userActivitiesPreview', 'custom'], variables.activities);

				const updateResponse = await updateActivityOrderForUser(variables.userId, variables.activities);

				return ok(updateResponse.data);
			} catch (error) {
				return err(error);
			}
		},
		onSuccess(result) {
			if (result.isErr()) {
				return null;
			}

			return Promise.all([
				queryClient.invalidateQueries({ queryKey: ['userActivities'] }),
				queryClient.invalidateQueries({ queryKey: ['userActivitiesPreview'] }),
			]);
		},
	});

	return {
		updateAllUserSettings,
		updateUserActivityRanking,
	};
};

import { MaybeRef, computed, unref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { DateTime } from 'luxon';
import { useAuthentication } from '@/store/auth';
import { getLoggedData } from '@/api/loggedData';
import { LoggedDataPeriod, createLoggedDataFromSupabase } from '@/models/LoggedData';

export interface LoggedDataQuerySearchParams {
	period: LoggedDataPeriod | { fromDate: DateTime; toDate?: DateTime };
	userId?: string | null;
}

export const useLoggedDataQuery = (searchParams: MaybeRef<LoggedDataQuerySearchParams>) => {
	const { isAuthenticated } = useAuthentication();

	const enabled = computed(() => isAuthenticated);

	const query = useQuery({
		queryKey: ['loggedData', searchParams],
		queryFn: async () => {
			const params = unref(searchParams);

			const fromDate = typeof params.period === 'string' ? DateTime.now().startOf(params.period) : params.period.fromDate.startOf('day');
			const toDate = typeof params.period === 'string'
				? DateTime.now().endOf('day')
				: params.period.toDate?.endOf('day')
					?? DateTime.now().endOf('day');

			return (await getLoggedData({ startDate: fromDate, endDate: toDate, userId: params.userId ?? undefined }))
				.map(createLoggedDataFromSupabase);
		},
		enabled,
		placeholderData: (previousData) => previousData ?? [],
		refetchOnWindowFocus: true,
	});

	return query;
};

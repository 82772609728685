<script setup lang="ts">
import {
	createColumnHelper,
	getCoreRowModel, getExpandedRowModel, getSortedRowModel, useVueTable,
} from '@tanstack/vue-table';
import { computed, h, watch } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { useI18n } from 'vue-i18n';
import AppTable from './AppTable.vue';
import MeterLinear from './MeterLinear.vue';
import InternalBadge from './InternalBadge.vue';
import {
	Beat, getClientFromBeat, getProjectFromBeat, getTotalTimeInMs, groupBeatsByClient, groupBeatsByProject,
} from '@/models/Beats';
import { msToDisplayTime } from '@/shared/times';
import { useClientsStore } from '@/store/clients';
import { useProjectsStore } from '@/store/projects';
import { BeatTableEntry } from '@/models/BeatTableEntry';
import { notEmpty, percentageRatio } from '@/utilities/Helpers';

const props = withDefaults(defineProps<{
	beats?: Beat[];
}>(), {
	beats: () => [],
});

const { t } = useI18n();
const clientsStore = useClientsStore();
const projectsStore = useProjectsStore();

const beatsGroupedByClient = computed<BeatTableEntry[]>(() => {
	const group = groupBeatsByClient(props.beats);

	return Object.entries(group)
		.map(([, beats]) => {
			const client = getClientFromBeat(clientsStore.allClients, beats[0]);

			if (!client) {
				return null;
			}

			const groupedByProject = groupBeatsByProject(beats);

			const subRows: BeatTableEntry[] = Object.entries(groupedByProject)
				.map(([projectId, projectBeats]) => {
					const project = getProjectFromBeat(projectsStore.allProjects, projectBeats[0]);

					if (!project) {
						return {
							id: 'no-project',
							logCount: projectBeats.length,
							totalDurationInMs: getTotalTimeInMs(projectBeats),
							name: 'Ohne Projekt',
							beats: projectBeats,
							isInternal: client.isInternal,
						};
					}

					return {
						id: projectId,
						name: project.name,
						logCount: projectBeats.length,
						beats: projectBeats,
						totalDurationInMs: getTotalTimeInMs(projectBeats),
						isInternal: client.isInternal,
					};
				})
				.filter(notEmpty)
				.sort((a, b) => b.totalDurationInMs - a.totalDurationInMs);

			const mappedClient = {
				id: client.id,
				name: client.name,
				logCount: beats.length,
				totalDurationInMs: getTotalTimeInMs(beats),
				subRows: subRows.every((row) => row.id === 'no-project') ? [] : subRows,
				beats,
				isInternal: client.isInternal,
			};
			return mappedClient;
		})
		.filter(notEmpty);
});

const columnHelper = createColumnHelper<typeof beatsGroupedByClient['value'][number]>();

const computedMessages = computed(() => ({
	client: t('common.client'),
	duration: t('common.duration'),
	durationInPercent: t('common.durationInPercent'),
	logs: t('common.logs'),
}));

const columns = [
	columnHelper.display({
		id: 'expand',
		size: 40,
		cell: (context) => (context.row.getCanExpand()
			? h(
				'button',
				{
					class: 'flex items-center justify-center',
					onClick: (event: MouseEvent) => {
						event.stopPropagation();
						return context.row.toggleExpanded();
					},
				},
				h(
					ChevronDownIcon,
					{
						class: ['w-5 h-5 transform', { 'rotate-180': context.row.getIsExpanded() }],
					},
				),
			)
			: ''),
	}),
	columnHelper.accessor('name', {
		header: computedMessages.value.client,
		size: 300,
		cell: (context) => (context.row.depth === 0
			? h(
				'strong',
				{
					class: 'text-white opacity-100',
				},
				[
					context.getValue(),
					context.row.original.isInternal
						? h(InternalBadge)
						: '',
				],
			)
			: context.getValue()),
	}),
	columnHelper.accessor('logCount', {
		header: computedMessages.value.logs,
	}),
	columnHelper.accessor('totalDurationInMs', {
		header: computedMessages.value.duration,
		cell: (context) => h(
			'div',
			{
				class: 'flex space-x-3 items-center',
			},
			[
				h('span', { class: 'w-12' }, msToDisplayTime(context.getValue())),
				h(MeterLinear, {
					class: 'h-2 w-20',
					progress: [
						context.getValue(),
						getTotalTimeInMs(props.beats),
					],
				}),
			],
		),
	}),
	columnHelper.accessor('totalDurationInMs', {
		header: computedMessages.value.durationInPercent,
		cell: (context) => {
			const allBeatsTotalDurationInMs = getTotalTimeInMs(props.beats);
			const totalDurationInPercent = percentageRatio(allBeatsTotalDurationInMs, context.getValue());
			return `${totalDurationInPercent}%`;
		},
	}),
];

const table = useVueTable({
	columns,
	initialState: {
		sorting: [{ id: 'totalDurationInMs', desc: true }],
	},
	get data() {
		return beatsGroupedByClient.value;
	},
	enableRowSelection: true,
	enableMultiRowSelection: false,
	enableSubRowSelection: false,
	enableSortingRemoval: false,
	getSubRows: (row) => row.subRows,
	getCoreRowModel: getCoreRowModel(),
	getExpandedRowModel: getExpandedRowModel(),
	getSortedRowModel: getSortedRowModel(),
});

watch(beatsGroupedByClient, () => {
	if (table.getIsSomeRowsSelected()) {
		return;
	}

	table.setRowSelection({ 0: true });
});

defineExpose({ table });
</script>

<template>
	<AppTable
		:table="table"
		class="w-full"
		clickable
	/>
</template>

<script setup lang="ts">
import {
	computed, ref, watch,
} from 'vue';
import { useAppProgressLoader } from '@sunlabde/vuelab';
import { useElementBounding } from '@vueuse/core';
import PageTitle from '@/components/PageTitle.vue';
import AppInput from '@/components/AppInput.vue';
import { useFieldSearch } from '@/composables/useFieldSearch';
import { useClientsQuery } from '@/composables/queries/useClientsQuery';
import { useProjectsQuery } from '@/composables/queries/useProjectsQuery';
import TableTopicOverview from '@/components/TableTopicOverview.vue';

const tableTitleDiv = ref();
const { top, height } = useElementBounding(tableTitleDiv);
const positionTop = computed(() => top.value + height.value);

const { data: clientsData, isFetching: isFetchingClients } = useClientsQuery();
const { data: projectsData, isFetching: isFetchingProjects } = useProjectsQuery();

const isFetching = computed(() => isFetchingClients.value || isFetchingProjects.value);

const activeClientsData = computed(() => clientsData.value?.filter((client) => !client.archived));
const activeProjectsData = computed(() => projectsData.value?.filter((project) => !project.archived));

const appPogressLoader = useAppProgressLoader();

watch(isFetching, (_isLoading) => {
	if (_isLoading) {
		appPogressLoader.start();
	} else {
		appPogressLoader.finishAll();
	}
});

const {
	value: logSearch, fields, unmatchedInput,
} = useFieldSearch({ debounce: 500 });

const topicsTable = ref<InstanceType<typeof TableTopicOverview>>();

watch(fields, (newFields) => {
	topicsTable.value?.table.setColumnFilters(newFields);
});

watch(unmatchedInput, (input) => {
	topicsTable.value?.table.setGlobalFilter(input);
});
</script>

<template>
	<div class="pt-6 space-y-10 h-full">
		<PageTitle>
			{{ $t('topicOverview.title') }}
		</PageTitle>

		<div class="space-y-6">
			<div>
				<div class="bg-primary-500 w-full h-[30px] sticky z-[45] top-0" />
				<div
					class="bg-primary-400 shadow-widget text-center border-b-2 border-primary-300 rounded-2xl"
				>
					<div
						ref="tableTitleDiv"
						class="flex justify-between items-center bg-primary-500 rounded-t-2xl py-5 px-6 sticky top-[20px] z-50"
					>
						<div class="font-semibold text-lg">
							{{ $t('topicOverview.title') }}
						</div>
						<div class="w-96">
							<AppInput
								v-model="logSearch"
								name="logSearch"
								:placeholder="$t('topicOverview.searchProjects')"
								class="text-sm"
							/>
						</div>
					</div>
					<div class="px-6">
						<TableTopicOverview
							ref="topicsTable"
							:clients="activeClientsData"
							:projects="activeProjectsData"
							:head-position-top="positionTop"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

import { useQuery } from '@tanstack/vue-query';
import { computed } from 'vue';
import { getUsers } from '@/api/user';
import { UserData } from '@/models/User';
import { useAuthentication } from '@/store/auth';

export const useUsersQuery = () => {
	const auth = useAuthentication();

	const enabled = computed(() => auth.isAuthenticated);

	const query = useQuery({
		queryKey: [
			'users',
		],
		queryFn: async () => {
			const users = await getUsers();

			if (!users.data) {
				return Promise.reject();
			}

			return users.data
				.map<UserData>((user) => ({ email: user.email, user: user.name, userId: user.user_id }));
		},
		enabled,
		initialData: [],
		refetchOnWindowFocus: false,
	});

	return query;
};

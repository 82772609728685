<script setup lang="ts">

const props = withDefaults(defineProps<{
	isDayEditable?: boolean;
}>(), {
	isDayEditable: false,
});

const emits = defineEmits<{
	(e: 'click', value: Event): void;
}>();

const onClick = ($event: Event) => {
	if (!props.isDayEditable) {
		return;
	}

	emits('click', $event);
};
</script>

<template>
	<div
		class="grid grid-cols-cards gap-8 z-0"
	>
		<button
			class="relative border-2 border-dashed border-primary-300 min-h-[128px] mobile:min-h-[286px] rounded-2xl transition-all ease-in-out focus:outline-none"
			:class="isDayEditable ? 'hover:border-gray focus:border-gray cursor-pointer' : 'cursor-default pointer-events-none'"
			type="button"
			@click="onClick"
		>
			<div
				class="absolute inset-0 flex space-x-2 justify-center items-center font-medium text-white text-opacity-50 tracking-wider text-center p-4 transition-all ease-in-out"
				:class="{ 'hover:text-opacity-75': isDayEditable }"
			>
				<slot />
			</div>
		</button>
	</div>
</template>

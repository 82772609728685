import { useInterval, useStorage, useWebNotification } from '@vueuse/core';
import { DateTime } from 'luxon';
import { Ref, computed, watch } from 'vue';
import { useSound } from '@vueuse/sound';
import { LogMissingNotification } from '@/models/LogMissingNotification';
import { getLoggedData } from '@/api/loggedData';
import { getLoggedDataByTypeInPercentFromSupabase } from '@/models/LoggedData';
import SunHealthLogo from '@/assets/sunhealth-logo-2d.png';
import NotificationSound from '@/assets/nouveau-jingle-netflix.mp3';

export const useLogMissingNotification = (notifications: Ref<LogMissingNotification[]>) => {
	const { play } = useSound(NotificationSound);

	const { show: showWebNotification, permissionGranted, onShow } = useWebNotification({
		title: 'SunHealth',
		renotify: true,
		requestPermissions: true,
		requireInteraction: true,
		icon: SunHealthLogo,
		body: 'Mach deine Log-Quote voll du Pappnase! Sonst klatscht es, aber kein Beifall!',
		tag: 'log-missing',
		silent: false,
	});

	onShow(play);

	const formatDateTimeToLocaleString = (dateTime: DateTime) => dateTime.toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h24' });
	const lastNotificationIsoDate = useStorage<string>('last-notification-date', '');

	const mappedNotificationsWithComparableDateTime = computed(() => notifications.value.map((notification) => ({
		dateTime: DateTime.fromISO(`2004-04-04T${notification.time}`),
		messages: notification.messages,
	})));

	const createWebNotification = async () => {
		if (!permissionGranted.value) return;

		const lastNotificationDateTime = DateTime.fromISO(lastNotificationIsoDate.value);

		if (lastNotificationIsoDate.value && lastNotificationDateTime.toISODate() !== DateTime.now().toISODate()) {
			lastNotificationIsoDate.value = '';
		}

		const lastNotificationTime = formatDateTimeToLocaleString(lastNotificationDateTime);
		const currentTime = formatDateTimeToLocaleString(DateTime.now());

		const triggerableNotification = mappedNotificationsWithComparableDateTime.value.filter((notification) => formatDateTimeToLocaleString(notification.dateTime) === currentTime);

		const randomizeNotificationMessages = (messages: string[]) => messages[Math.floor(Math.random() * messages.length)];

		if (!triggerableNotification.length) return;

		if (currentTime === lastNotificationTime) return;

		const loggedData = await getLoggedData({
			startDate: DateTime.now(),
			endDate: DateTime.now(),
		});

		if (getLoggedDataByTypeInPercentFromSupabase(loggedData, 'user') > 80) return;

		showWebNotification({
			body: randomizeNotificationMessages(triggerableNotification[0].messages),
		});

		lastNotificationIsoDate.value = DateTime.now().toISO();
	};

	const { resume: resumeInterval, pause: pauseInterval } = useInterval(1000 * 60, {
		callback: createWebNotification,
		controls: true,
	});

	watch(notifications, () => {
		if (!notifications.value.length) {
			pauseInterval();
			return;
		}
		resumeInterval();
	}, {
		immediate: true,
	});
};

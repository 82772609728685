import { DateTime } from 'luxon';
import { ref } from 'vue';
import { createTimeToBeatTrackingEntry } from '@/api/timeToBeatTracking';
import { TimeToBeatType } from '@/models/TimeToBeat';
import logger from '@/plugins/Logger';

export type UseTimeToBeatTrackingOptions = {
	interactionType: TimeToBeatType;
};

const MAX_INTERACTION_TIME_IN_SECONDS = 60 * 10;

export const useTimeToBeatTracking = (options: UseTimeToBeatTrackingOptions) => {
	const firstInteractionTime = ref<DateTime>();

	const startInteraction = () => {
		logger.info('⏱️ TTB: startInteraction');

		if (firstInteractionTime.value && Math.abs(firstInteractionTime.value.diffNow('seconds').seconds) < MAX_INTERACTION_TIME_IN_SECONDS) {
			return;
		}

		firstInteractionTime.value = DateTime.now();
	};

	const stopInteraction = () => {
		logger.info('⏱️ TTB: stopInteraction');
		firstInteractionTime.value = undefined;
	};

	const restartInteraction = () => {
		stopInteraction();
		startInteraction();
	};

	const stopAndTrackInteraction = () => {
		if (!firstInteractionTime.value) {
			return;
		}

		const durationInSeconds = Math.abs(firstInteractionTime.value.diffNow('seconds').seconds);
		logger.info(`⏱️ TTB: ${durationInSeconds}`);
		createTimeToBeatTrackingEntry({ durationInSeconds, type: options.interactionType });
		stopInteraction();
	};

	return {
		startInteraction,
		restartInteraction,
		stopInteraction,
		stopAndTrackInteraction,
	};
};

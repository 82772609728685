import { P, match } from 'ts-pattern';

export type ConfidentState = 'confident' | 'unsure' | 'dismissed' | 'ignored';
export type BeatDuration = 'short' | 'medium' | 'long';
export type BeatTiming = 'same_day' | 'past_day';

export type ConfidenceMeta = {
	clientId: string;
	projectId?: string;
	activityId: string;
	confidence: ConfidentState;
	beatDuration?: BeatDuration;
	timing?: BeatTiming;
};

export const millisToDuration = (ms: number): BeatDuration => match<number, BeatDuration>(ms / 3_600_000)
	.with(P.when((hours) => hours > 2), () => 'long')
	.with(P.when((hours) => hours > 0.5 && hours <= 2), () => 'medium')
	.otherwise(() => 'short');

import { supabase } from '@/lib/supabase';

export type UserRight = 'READ_ANY_BEAT'
	| 'DELETE_ANY_BEAT'
	| 'UPDATE_ANY_BEAT'
	| 'UPDATE_ANY_ABSENCE'
	| 'DELETE_ANY_ABSENCE'
	| 'READ_ANY_ABSENCE'
	| 'CREATE_NEW_ACTIVITY'
	| 'CREATE_NEW_PROJECT'
	| 'CREATE_NEW_CLIENT'
	| 'UPDATE_ACTIVITIES'
	| 'UPDATE_PROJECTS'
	| 'UPDATE_CLIENTS'
	| 'READ_ANY_USER_FTE'
	| 'VIEW_ADMIN_PANEL'
	| 'MANAGE_QUOTA_LISTS';

export const checkUserRight = async (userId: string, right: UserRight) => {
	const { data } = await supabase
		.rpc('has_right_or_is_super', {
			user_id: userId,
			right_id: right,
		});

	if (data) {
		return data;
	}

	return false;
};

export const checkCurrentUserRight = async (right: UserRight) => {
	const { data: { user } } = await supabase.auth.getUser();

	if (!user) return false;

	return checkUserRight(user.id, right);
};

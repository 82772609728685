<script setup lang="ts">
import { computed } from 'vue';
import vAnimatedNumber, { AnimatedNumberDirectiveBindingOptions } from '../plugins/animatedNumberDirective';

const props = withDefaults(defineProps<{
	label?: string;
	value?: number;
	formatter?: (value: number) => string | number;
	suffix?: string;
	highlight?: boolean;
	options?: Partial<AnimatedNumberDirectiveBindingOptions>;
}>(), {
	label: '',
	value: 0,
	formatter: undefined,
	suffix: '',
	highlight: false,
	options: () => ({}),
});

const formatNumber = (value: number) => {
	if (props.formatter) {
		return props.formatter(value);
	}

	return Math.round(value);
};

const valueBindings = computed(() => ({ value: props.value, ...props.options }));
</script>

<template>
	<div
		class="flex flex-col bg-primary-400 rounded-2xl p-6 shadow-widget text-center"
		v-bind="$attrs"
	>
		<div
			class="text-xs phablet:text-sm font-normal tracking-wide mb-1"
			:class="{ 'text-secondary': highlight }"
		>
			<slot>
				{{ label }}
			</slot>
		</div>
		<span class="text-2xl text-white text-opacity-75 tracking-wide font-bold flex justify-center">
			<div v-animated-number:[formatNumber]="valueBindings" />{{ suffix }}
		</span>
	</div>
</template>

<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components';
import {
	ref, toRefs, watch,
} from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/solid';
import { SelectOption, getOptionLabel, getOptionValue } from '../../models/Select';
import { useSelect } from '../../composables/useSelect';
import { usePopper } from '../../composables/usePopper';
import { useTypeAhead } from './typeAhead';
import { useAdjustScroll } from './adjustScroll';
import TimeInput from './TimeInput.vue';

const props = withDefaults(defineProps<{
	options: SelectOption[];
	placeholder?: string;
	readonly?: boolean;
	selectable?: boolean;
	required?: boolean;
	isValid?: boolean;
	errorMessage?: string;
}>(), {
	placeholder: '',
	readonly: false,
	selectable: false,
	required: false,
	isValid: false,
	errorMessage: '',
});

const modelValue = defineModel<SelectOption>('modelValue', { default: '' });

const selectRef = ref<typeof TimeInput>();
const menuRef = ref<HTMLElement>();
const wrapperRef = ref<HTMLElement>();

const {
	isOpen: isDropdownMenuOpen,
	toggle: toggleDropdownMenu,
	close: closeDropdownMenu,
} = usePopper({
	placement: ref('bottom-start'),
	offsetSkid: ref('0'),
	offsetDistance: ref('10'),
	popperNode: menuRef,
	triggerNode: wrapperRef,
	options: {
		modifiers: [
			{
				name: 'sameWidth',
				enabled: true,
				fn: ({ state }) => {
					state.styles.popper.width = `${state.rects.reference.width}px`;
				},
				phase: 'beforeWrite',
				requires: ['computeStyles'],
			},
			{
				// We want to disable flip to avoid weird UX when scrolling to fast inside the dropdown
				name: 'flip',
				enabled: false,
			},
		],
	},
});

const { isSelected: isOptionSelected, toggleOption } = useSelect(
	modelValue,
);

const onMousedown = (option: SelectOption) => {
	toggleOption(option);
};

const { typeAheadPointer, setTypeAheadPointer } = useTypeAhead(
	toRefs(props).options,
	modelValue,
	toggleOption,
	closeDropdownMenu,
	isDropdownMenuOpen,
);

const { maybeAdjustScroll, optionsListRef } = useAdjustScroll(
	(listElement) => listElement.children[typeAheadPointer.value] as HTMLElement,
);

watch(typeAheadPointer, maybeAdjustScroll);

watch(
	() => props.selectable,
	() => {
		setTypeAheadPointer();
		maybeAdjustScroll();
	},
);
</script>

<template>
	<div>
		<div
			ref="wrapperRef"
			v-on-click-outside="closeDropdownMenu"
		>
			<TimeInput
				ref="selectRef"
				v-bind="$attrs"
				:placeholder="placeholder"
				:model-value="getOptionValue(modelValue)"
				:required="required"
				:is-valid="isValid"
				:error-message="errorMessage"
				:readonly="readonly"
				:disabled="readonly"
				@blur="closeDropdownMenu"
				@update:model-value="(newValue) => modelValue = newValue"
			>
				<button
					class="p-1"
					type="button"
					:disabled="readonly"
					@click="toggleDropdownMenu"
				>
					<ChevronDownIcon
						class="w-5 transform transition-all ease-in-out duration-200"
						:class="{
							'rotate-180': isDropdownMenuOpen,
							'opacity-50': readonly,
						}"
					/>
				</button>
			</TimeInput>
		</div>
		<teleport to="#app">
			<div
				ref="menuRef"
				class="z-50"
			>
				<transition name="select">
					<ul
						v-show="isDropdownMenuOpen && !readonly"
						ref="optionsListRef"
						class="rounded-[7px] overflow-y-auto max-h-52 flex flex-col bg-[#454162] text-xl"
					>
						<li
							v-for="(option, $index) in options"
							:key="JSON.stringify(option)"
							class="flex items-center py-3 px-6 w-full text-left focus-visible:bg-primary-300 !text-white outline-none transition-all ease-in-out duration-300 cursor-pointer"
							:class="{
								'bg-primary-300':
									isOptionSelected(option) || $index === typeAheadPointer,
							}"
							@mousedown="onMousedown(option)"
							@mouseover="typeAheadPointer = $index"
							@focus="typeAheadPointer = $index"
						>
							{{ getOptionLabel(option) }} {{ $t('common.clock') }}
						</li>
					</ul>
				</transition>
			</div>
		</teleport>
	</div>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core';

const props = defineProps<{
	modelValue: string;
}>();

const emit = defineEmits<{
	(e: 'update:modelValue', value: string): void;
}>();

const data = useVModel(props, 'modelValue', emit);
</script>

<template>
	<input
		ref="searchField"
		v-model="data"
		type="text"
		class="w-full inset-0 outline-none appearance-none box-border border-b-2 border-primary-800 text-xl phablet:text-2xl font-sans bg-transparent py-2"
		aria-label="Search"
		:placeholder="$t('admin.searchPlaceholder')"
	>
</template>
